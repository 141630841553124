import React from 'react';
import PropTypes from 'prop-types';
import { useHistory, Link as RouterLink } from 'react-router-dom';
import {
  Box, Button, Link
} from '@mui/material';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import SearchBox from './SearchBox';

const MobileArticleHeader = (props) => {
  const {
    articleSearch,
    setArticleSearch,
    articleSearchIndex,
    setArticleSearchIndex,
    articleSearchListLength,
    setArticleSearchListLength,
  } = props;

  const history = useHistory();

  const handleGoBack = () => {
    history.goBack();
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        px: 3,
        pb: 2,
        pt: 1,
        zIndex: 1000,
        position: 'sticky',
        top: 0,
        borderBottom: '1px solid',
        backgroundColor: 'white.main',
        displayPrint: 'none',
        '@media print': {
          display: 'none'
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Box>
          <Button
            variant="contained"
            color="white"
            sx={{
              padding: '8px',
              minWidth: '0px',
              borderRadius: '8px',
              fontSize: '1.2rem',
              displayPrint: 'none',
              mr: 2,
            }}
            onClick={handleGoBack}
          >
            <NavigateBeforeIcon />
          </Button>
        </Box>
        <Link
          component={RouterLink}
          to="/ask"
          sx={{
            textDecoration: 'none',
            fontSize: '2.4375rem',
            color: 'lightBlue.main',
            mb: 1,
            '&:hover, &:active': {
              textDecoration: 'none',
              color: 'lightBlue.main',
            },
          }}
        >
          Sofie
        </Link>
      </Box>

      <Box>
        <SearchBox
          articleSearch={articleSearch}
          setArticleSearch={setArticleSearch}
          setArticleSearchIndex={setArticleSearchIndex}
          articleSearchIndex={articleSearchIndex}
          articleSearchListLength={articleSearchListLength}
          setArticleSearchListLength={setArticleSearchListLength}
        />
      </Box>
    </Box>
  );
};

MobileArticleHeader.propTypes = {
  articleSearch: PropTypes.string.isRequired,
  setArticleSearch: PropTypes.func.isRequired,
  articleSearchIndex: PropTypes.number,
  articleSearchListLength: PropTypes.number,
  setArticleSearchIndex: PropTypes.func.isRequired,
  setArticleSearchListLength: PropTypes.func.isRequired,
};

MobileArticleHeader.defaultProps = {
  articleSearchIndex: undefined,
  articleSearchListLength: undefined,
};

export default MobileArticleHeader;
