import React, { Component } from 'react';
import * as Proptypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ReactGA from 'react-ga';
import ReactGA4 from 'react-ga4';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication, EventType } from '@azure/msal-browser';
import { withRouter } from 'react-router-dom';
import { attemptLogin } from '../../actions/authentication/authentication';
import MaintenancePage from '../MaintenancePage/MaintenancePage';
import ThemeConfig from './ThemeConfig';
import Routes from './Routes';
import { msalConfig } from '../../AuthConfig';
import AuthRequest from '../../api/AuthRequest';
import { authTokenRequest, authTokenSuccess, authTokenFailure } from '../../actions/authentication/tokenAuthentication';
import LoadingPage from '../LoadingPage/LoadingPage';
import 'bootstrap/dist/css/bootstrap.css';

// Theme for material UI components
const theme = createTheme(ThemeConfig);

const msalInstance = new PublicClientApplication(msalConfig);

/**
 * App Component
 * The first component which loads in the root DIV
 */
class App extends Component {
  /**
   * Initializes component
   * @param {object} props - defined in proptypes
   */
  constructor(props) {
    super(props);
    this.state = {
      msalForgotPassword: false,
      tokenChecked: false,
      msalLoginError: '',
    };
  }

  /**
   * Triggers authentication for rehydrated tokens
   */
  componentDidMount() {
    const {
      user, history, authenticateTokenRequest, authenticateTokenSuccess, authenticateTokenFailure
    } = this.props;

    ReactGA.initialize(ANALYTICS_CODE, {
      debug: ANALYTICS_DEBUG,
      gaOptions: {
        cookieFlags: 'SameSite=None; Secure',
      },
    });

    ReactGA4.initialize([
      {
        trackingId: GA4_ANALYTICS_CODE,
        gaOptions: {
          cookieFlags: 'SameSite=None; Secure',
        },
      },
    ]);

    msalInstance.addEventCallback((event) => {
      if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
        const account = event.payload.account;
        msalInstance.setActiveAccount(account);
      } else if (event.eventType === EventType.LOGIN_FAILURE && event.error.errorMessage.includes('AADB2C90118')) {
        this.setState({
          msalForgotPassword: true,
          msalLoginError: '',
        });
      } else if (event.eventType === EventType.LOGIN_FAILURE && !event.error.errorMessage.includes('AADB2C90091')) {
        this.setState({
          msalLoginError: event.error.errorMessage,
          msalForgotPassword: false,
        });
      }
    });

    if (window.location.pathname.includes('public-article')) {
      this.setState({ tokenChecked: true });
    } else {
      const shouldRedirect = window.location.pathname !== '/'
        && window.location.pathname !== '/login'
        && window.location.pathname !== '/handle-redirect';

      if (user.tokenExpiry - Math.floor(new Date().getTime() / 1000) <= MODAL_TIMER) {
        this.setState({ tokenChecked: true }, () => {
          if (shouldRedirect) {
            history.push('/');
          }
        });
      } else {
        authenticateTokenRequest();
        if (APP_ENV.localeCompare('production') === 0) {
          ReactGA.set({
            dimension17: `${user.userID}`,
            dimension18: user.accountID === null ? '0' : `${user.accountID}`,
            dimension19: `${user.companyID}`,
          });
        } else {
          ReactGA.set({
            dimension1: `${user.userID}`,
            dimension2: user.accountID === null ? '0' : `${user.accountID}`,
            dimension3: `${user.companyID}`,
          });
        }
        ReactGA4.set({
          userId: `${user.userID}`,
        });
        ReactGA4.gtag('set', 'user_properties', {
          user_id_custom: `${user.userID}`,
        });
        AuthRequest.defaults.headers.common.Authorization = `Bearer ${user.token}`;
        AuthRequest({
          method: 'post',
          url: 'alive',
        })
          .then(() => {
            authenticateTokenSuccess();
            if (APP_ENV.localeCompare('production') === 0) {
              ReactGA.set({
                dimension17: `${user.userID}`,
                dimension18: user.accountID === null ? '0' : `${user.accountID}`,
                dimension19: `${user.companyID}`,
              });
            } else {
              ReactGA.set({
                dimension1: `${user.userID}`,
                dimension2: user.accountID === null ? '0' : `${user.accountID}`,
                dimension3: `${user.companyID}`,
              });
            }
            ReactGA4.set({
              userId: `${user.userID}`,
            });
            ReactGA4.gtag('set', 'user_properties', {
              user_id_custom: `${user.userID}`,
            });
            this.setState({ tokenChecked: true });
          })
          .catch(() => {
            AuthRequest.defaults.headers.common.Authorization = '';
            authenticateTokenFailure();
            this.setState({ tokenChecked: true }, () => {
              if (shouldRedirect) {
                history.push('/');
              }
            });
          });
      }
    }
  }

  /**
   * Renders Application and handles routing between pages.
   * @returns {*} - DOM description
   */
  render() {
    const {
      embed, credentials, user, maintenance
    } = this.props;
    const { msalForgotPassword, msalLoginError, tokenChecked } = this.state;
    const token = user.token;

    if (maintenance) {
      return (
        <ThemeProvider theme={theme}>
          <MaintenancePage />
        </ThemeProvider>
      );
    }

    if (!tokenChecked) {
      return (
        <LoadingPage title="Checking Access Token." />
      );
    }

    return (
      <ThemeProvider theme={theme}>
        <MsalProvider instance={msalInstance}>
          <Routes
            embed={embed}
            credentials={credentials}
            token={token}
            user={user}
            forgotPassword={msalForgotPassword}
            loginError={msalLoginError}
            msalInstance={msalInstance}
          />
        </MsalProvider>
      </ThemeProvider>
    );
  }
}

/**
 * Maps items from the redux store to apps props.
 * @param {object} state - des
 * @returns {{user: *}} - maps user from redux to apps props
 */
function mapStateToProps(state) {
  return {
    user: state.user,
    auth: state.auth
  };
}

/**
 * Maps actions to component props.
 * @param {Dispatch} dispatch - allows action creators to work with redux.
 * @returns {{authToken: *, login: *}} - bound action creators
 */
function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    login: attemptLogin,
    authenticateTokenRequest: authTokenRequest,
    authenticateTokenSuccess: authTokenSuccess,
    authenticateTokenFailure: authTokenFailure,
  }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));

App.propTypes = {
  authenticateTokenRequest: Proptypes.func.isRequired,
  authenticateTokenSuccess: Proptypes.func.isRequired,
  authenticateTokenFailure: Proptypes.func.isRequired,
  user: Proptypes.shape({
    token: Proptypes.string,
    tokenExpiry: Proptypes.number,
    role: Proptypes.number,
    agreementAgreed: Proptypes.bool,
    accountID: Proptypes.number,
    userID: Proptypes.number,
    companyID: Proptypes.number,
    tookSurvey: Proptypes.bool,
  }).isRequired,
  auth: Proptypes.shape({
    errorMsg: Proptypes.string,
    tokenValidated: Proptypes.bool,
  }).isRequired,

  maintenance: Proptypes.bool,

  embed: Proptypes.bool,
  credentials: Proptypes.shape({
    error: Proptypes.string,
    access_token: Proptypes.string,
    third_party_token: Proptypes.string,
    registered: Proptypes.bool,
    remember_me: Proptypes.bool,
    user_details: Proptypes.shape({
      user_id: Proptypes.number,
      first_name: Proptypes.string,
      last_name: Proptypes.string,
      email: Proptypes.string,
      account_id: Proptypes.number,
      password: Proptypes.string,
      role_id: Proptypes.number,
      agreement_agreed: Proptypes.bool,
    }),
  }),

  history: Proptypes.shape({
    push: Proptypes.func,
  }).isRequired,
};

App.defaultProps = {
  embed: false,
  credentials: {
    third_party_token: '',
    user_details: {
      user_id: 0
    },
  },
  maintenance: false
};
