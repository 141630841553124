import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Box } from '@mui/material';
import {
  closeAndSearch,
} from '../../../actions/ask/askActions';
import BestPracticesContent from '../../BestPracticesContent/BestPracticesContent';
import AlertModal from '../AlertModal/AlertModal';
import BlackCheckbox from '../../../elements/BlackCheckbox/BlackCheckbox';

/**
 * Renders Component
 * @param {object} props - Defined in proptypes
 * @returns {JSX.Element} - DOM description
 */
const BestPracticesModal = (props) => {
  const {
    open, question, cancelToken, category
  } = props;
  const dispatch = useDispatch();
  const [hidePopupPermanently, setHidePopupPermanently] = useState(false);

  const handleClose = () => {
    dispatch(closeAndSearch(question, category, cancelToken, hidePopupPermanently));
  };

  const onChange = () => {
    setHidePopupPermanently(!hidePopupPermanently);
  };

  return (
    <AlertModal
      open={open}
      title="Sofie Best Search Practices"
      handleClose={handleClose}
      buttonText="Close"
      bodyPadding={4}
      maxWidth="md"
    >
      <>
        <BestPracticesContent />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <BlackCheckbox
            checked={hidePopupPermanently}
            onChange={onChange}
            label="Don't show this message again"
          />
        </Box>
      </>
    </AlertModal>
  );
};

export default BestPracticesModal;

BestPracticesModal.propTypes = {
  open: PropTypes.bool.isRequired,
  cancelToken: PropTypes.shape({}),
  question: PropTypes.string,
  category: PropTypes.string,
};

BestPracticesModal.defaultProps = {
  question: '',
  category: '2',
  cancelToken: {},
};
