import React, { useEffect } from 'react';
import Proptypes from 'prop-types';
import { useMsal } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import LoadingPage from '../LoadingPage/LoadingPage';
import { policies } from '../../AuthConfig';
import LoginError from './LoginError';
import { storeUUID } from '../../actions/authentication/authentication';

const HandleRedirect = (props) => {
  const {
    user, loginError, forgotPassword, history, saveUUID
  } = props;
  const { inProgress, instance } = useMsal();
  const azureAuthenticationInProgress = inProgress !== InteractionStatus.None;
  const now = Math.floor(new Date().getTime() / 1000);
  const isActiveAccount = !!instance.getActiveAccount();
  const isUuidValid = (user.azureUUID && (user.azureUUIDExpiry - now) > 0);

  useEffect(() => {
    if (!azureAuthenticationInProgress) {
      if (forgotPassword) {
        instance.loginRedirect(policies.authorities.forgotPassword);
      } else if (isUuidValid) {
        history.push('/login');
      } else if (!isActiveAccount) {
        history.push('/');
      } else if (isActiveAccount) {
        saveUUID(instance.getActiveAccount().localAccountId);
      }
    }
  }, [inProgress, user.azureUUID, user.azureUUIDExpiry]);

  if (loginError && !forgotPassword) {
    return (
      <LoginError error={loginError} />
    );
  }

  if (forgotPassword && !loginError) {
    return (
      <LoadingPage title="Redirecting to forgot password form." />
    );
  }

  return (
    <LoadingPage title="Checking Azure Account." />
  );
};

/**
 * Maps items from the redux store to apps props.
 * @param {object} state - des
 * @returns {{user: *}} - maps user from redux to apps props
 */
const mapStateToProps = (state) => ({
  user: state.user,
});

/**
 * Maps actions to component props.
 * @param {Dispatch} dispatch - allows action creators to work with redux.
 * @returns {{logout: *, authToken: *, login: *}} - bound action creators
 */
const mapDispatchToProps = (dispatch) => bindActionCreators({
  saveUUID: storeUUID,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(HandleRedirect);

HandleRedirect.propTypes = {
  user: Proptypes.shape({
    azureUUID: Proptypes.string,
    azureUUIDExpiry: Proptypes.number,
  }).isRequired,
  forgotPassword: Proptypes.bool.isRequired,
  loginError: Proptypes.string.isRequired,
  history: Proptypes.shape({
    push: Proptypes.func
  }).isRequired,
  saveUUID: Proptypes.func.isRequired,
};
