import React, { Component } from 'react';
import * as Proptypes from 'prop-types';
import { toast } from 'react-toastify';
import Collapse from '@material-ui/core/Collapse';
import TextField from '@material-ui/core/TextField';
import Validation from '../../../helpers/Validation';
import AuthRequest from '../../../api/AuthRequest';
import Notification from '../../Notification/Notification';
import Button from '../../Button/Button';
import './AddUser.scss';

/**
 * This component controls the add user form and the number of licenses information.
 */
class AddUser extends Component {
  /**
   * Initializes component
   * @param {object} props - defined in proptypes
   */
  constructor(props) {
    super(props);
    this.state = {
      adding: false,
      firstName: '',
      lastName: '',
      email: '',
      role: 7,
      roles: []
    };
    this.addingUser = this.addingUser.bind(this);
    this.addUser = this.addUser.bind(this);
    this.fnameUpdated = this.fnameUpdated.bind(this);
    this.lnameUpdated = this.lnameUpdated.bind(this);
    this.emailUpdated = this.emailUpdated.bind(this);
    this.roleUpdated = this.roleUpdated.bind(this);
    this.cancel = this.cancel.bind(this);
  }

  /**
   * Initialize the components state.
   */
  componentDidMount() {
    const { roles } = this.props;
    this.setState({
      roles
    });
  }

  /**
   * Handles updates to the first name field.
   * @param {object} e - event that contains the data
   */
  fnameUpdated(e) {
    const { error } = this.state;
    const newError = error;
    if (error && error['first name']) {
      delete newError['first name'];
    }

    this.setState({
      firstName: e.target.value,
      error: newError
    });
  }

  /**
   * Handles updates to the last name field.
   * @param {object} e - event that contains the data
   */
  lnameUpdated(e) {
    const { error } = this.state;
    const newError = error;
    if (error && error['last name']) {
      delete newError['last name'];
    }

    this.setState({
      lastName: e.target.value,
      error: newError
    });
  }

  /**
   * Handles updates to to the email field.
   * @param {object} e - event that contains the data
   */
  emailUpdated(e) {
    const { error } = this.state;
    const newError = error;
    if (error && error.email) {
      delete newError.email;
    }

    this.setState({
      email: e.target.value,
      error: newError
    });
  }

  /**
   * Handles updates to the role field.
   * @param {object} e - event that contains the data
   */
  roleUpdated(e) {
    this.setState({
      role: e.target.value
    });
  }

  /**
   * Changes the component view to show the add user form.
   * Will throw an error if not enough licenses are available.
   */
  addingUser() {
    const { licenses, used } = this.props;
    const { adding } = this.state;

    if (used >= licenses) {
      toast.warn(
        <Notification
          title="Not Enough Licenses"
          body="Please purchase more licenses before adding a new user."
        />
      );
      return;
    }
    this.setState({
      adding: !adding
    });
  }

  /**
   * Handles cancelling adding a new user. Erases form data.
   */
  cancel() {
    this.setState({
      firstName: '',
      lastName: '',
      email: '',
      role: 7,
      adding: false,
      error: {}
    });
  }

  /**
   * Calls the API to add a new user.
   * Throws an error if any of the necessary data is missing.
   * Creates a notification if user was sucessfully added or an error was encountered.
   */
  addUser() {
    const { getUsers } = this.props;
    const {
      firstName, lastName, email, role, roles,
    } = this.state;

    let roleActual = role;
    const validationObject = [
      {
        name: 'first name',
        value: firstName,
        validationType: 'required|alpha',
      },
      {
        name: 'last name',
        value: lastName,
        validationType: 'required|alpha',
      },
      {
        name: 'email',
        value: email,
        validationType: 'required|email',
      }
    ];

    const error = Validation.formValidation(validationObject);
    if (Object.keys(error).length !== 0) {
      this.setState({
        error
      });
      return;
    }

    if (!role) {
      roleActual = roles[2].id;
    }
    roleActual = parseInt(roleActual, 10);

    AuthRequest({
      method: 'post',
      url: 'user/invite',
      params: {
        email,
        first_name: firstName,
        last_name: lastName,
        role_id: roleActual
      }
    }).then(() => {
      getUsers();
      toast.success(
        <Notification
          title="Added User"
          body={`Added ${firstName} ${lastName}.`}
        />
      );
      this.cancel();
    });
  }

  /**
   * Renders component
   * @returns {*} - DOM description
   */
  render() {
    const { licenses, used, loggedInUser } = this.props;
    const {
      adding, roles, firstName, lastName, email, role, error
    } = this.state;

    return (
      <>
        <div className="AddUser_holder">
          <Collapse in={adding} addEndListener={() => {}}>
            <table className="AddUser_table">
              <tbody>
                <tr className="AddUser_table_row">
                  <td className="AddUser_check">
                    <div className="AddUser_data" />
                  </td>
                  <td className="AddUser_col">
                    <div className="AddUser_data">
                      <TextField
                        id="firstName"
                        type="text"
                        label="First Name"
                        autoComplete="family-name"
                        size="small"
                        variant="outlined"
                        color="primary"
                        fullWidth
                        value={firstName}
                        inputProps={{ className: 'AddUser_input' }}
                        InputLabelProps={{ className: 'AddUser_input_label' }}
                        onChange={this.fnameUpdated}
                        error={!!(error && error['first name'])}
                        helperText={error && error['first name'] ? error['first name'] : ' '}
                      />
                    </div>
                  </td>
                  <td className="AddUser_col">
                    <div className="AddUser_data">
                      <TextField
                        id="lastName"
                        type="text"
                        label="Last Name"
                        autoComplete="given-name"
                        size="small"
                        variant="outlined"
                        color="primary"
                        fullWidth
                        value={lastName}
                        inputProps={{ className: 'AddUser_input' }}
                        InputLabelProps={{ className: 'AddUser_input_label' }}
                        onChange={this.lnameUpdated}
                        error={!!(error && error['last name'])}
                        helperText={error && error['last name'] ? error['last name'] : ' '}
                      />
                    </div>
                  </td>
                  <td className="AddUser_col">
                    <div className="AddUser_data">
                      <TextField
                        id="email"
                        type="email"
                        label="Email Address"
                        autoComplete="email"
                        size="small"
                        variant="outlined"
                        color="primary"
                        fullWidth
                        value={email}
                        inputProps={{ className: 'AddUser_input' }}
                        InputLabelProps={{ className: 'AddUser_input_label' }}
                        onChange={this.emailUpdated}
                        error={!!(error && error.email)}
                        helperText={error && error.email ? error.email : ' '}
                      />
                    </div>
                  </td>
                  <td className="AddUser_role">
                    <div className="AddUser_data_role">
                      <select onChange={this.roleUpdated} value={role} className="AddUser_drop">
                        {
                          roles.filter((rle) => (
                            rle.id !== 5 && rle.id > loggedInUser.role)).map((r) => (
                              <option value={r.id} key={r.id}>{r.name}</option>
                          ))
                        }
                      </select>
                    </div>
                  </td>
                  <td className="AddUser_actions">
                    <div className="AddUser_data_actions">
                      <Button
                        onClick={this.addUser}
                        variant="secondary-inverse"
                        customVariant="AddUser_button"
                        customContent="Add User"
                      />
                      <Button
                        onClick={this.cancel}
                        variant="secondary-inverse"
                        customVariant="AddUser_button"
                        customContent="Cancel"
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </Collapse>
        </div>
        <div className="AddUser">
          <div className="AddUser_left">
            <Button
              onClick={this.addingUser}
              variant="secondary-inverse"
              customVariant="AddUser_button"
              customContent={adding ? 'Cancel' : 'Add User'}
            />
          </div>
          <div className="AddUser_right">
            <div>
              {`Licenses Total: ${licenses}`}
            </div>
            <div>
              {`Used: ${used} | Remaining: ${licenses - used}`}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default AddUser;

AddUser.propTypes = {
  licenses: Proptypes.number.isRequired,
  used: Proptypes.number.isRequired,
  getUsers: Proptypes.func.isRequired,
  roles: Proptypes.arrayOf(Proptypes.shape({
    id: Proptypes.number,
    name: Proptypes.string
  })).isRequired,
  loggedInUser: Proptypes.shape({
    role: Proptypes.number
  }).isRequired
};
