import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import AuthRequest from '../../api/AuthRequest';
import LoadingPage from '../LoadingPage/LoadingPage';

const TextPage = () => {
  const [content, setContent] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    AuthRequest({
      method: 'get',
      url: 'disclaimer',
    })
      .then((res) => {
        setContent(res.data.message);
        setLoading(false);
      })
      .catch(() => {
        setContent(<p>there was an error!</p>);
        setLoading(false);
      });
  }, []);

  let display = <LoadingPage title="Loading Content..." />;
  if (!loading) {
    display = (
      <div
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: content,
        }}
      />
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexFlow: 'column nowrap',
        flex: '1 0 auto',
        '@media (max-width: 800px)': {
          margin: '2rem 5%',
        },
        margin: '2rem 10%',
      }}
    >
      {display}
    </Box>
  );
};

TextPage.propTypes = {};

export default TextPage;
