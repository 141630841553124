import React, { Component } from 'react';
import * as Proptypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import LoadingPage from '../LoadingPage/LoadingPage';
import { attemptLogout } from '../../actions/authentication/authentication';

/**
 * Logs the user out.
 * Triggers the logout action which pings the backend to expire the token.
 * It then clears the redux state.
 * @param {object} props - defined in proptypes
 * @returns {*} - triggers logout sequence
 * @class
 */
class Logout extends Component {
  /**
   * Initializes component
   * @param {object} props - defined in proptypes
   */
  constructor(props) {
    super(props);
    this.state = {};
  }

  /**
   * Waits for the logout function to finishe before changing the page to the Branding page.
   */
  componentDidMount() {
    const { logout, location } = this.props;
    const invalid = !!(location.state && location.state.invalid);
    logout(invalid).then(() => {
      window.location.replace(LOGOUT_URL);
    });
  }

  /**
   * Renders the loader.
   * @returns {*} - loader.
   */
  render() {
    return <LoadingPage title="Logging Out" />;
  }
}

/**
 * Maps actions to component props.
 * @param {Dispatch} dispatch - allows action creators to work with redux.
 * @returns {{logout: *}} - bound action creators
 */
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      logout: attemptLogout,
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(Logout);

Logout.propTypes = {
  logout: Proptypes.func.isRequired,
  location: Proptypes.shape({
    state: Proptypes.shape({
      invalid: Proptypes.bool,
    })
  }).isRequired
};
