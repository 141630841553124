import React from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import CustomLink from '../../elements/CustomLink/CustomLink';
import Logo from '../../assets/svgs/logo-new.svg';

const Separator = (props) => (
  <Typography
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
    sx={{ display: 'inline-block', px: 1 }}
  >
    {' | '}
  </Typography>
);

const Footer = () => {
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: isMobile ? 'column' : 'row',
        alignItems: isMobile ? 'center' : 'flex-end',
        pt: isMobile ? 30 : 3,
        px: 7,
        pb: 2,
        displayPrint: 'none',
        textAlign: isMobile ? 'center' : 'left',
      }}
    >
      {isMobile && (
        <Box
          sx={{
            width: 176,
            pb: 3.5
          }}
        >
          <img
            src={Logo}
            alt="LifeLearn Company Logo"
          />
        </Box>
      )}
      <Box>
        <CustomLink
          text="Terms and Conditions"
          to="https://www.lifelearn.com/sofie-terms-and-conditions/"
          external
          color="text"
        />
        <Separator />
        <CustomLink
          text="LifeLearn Disclaimer"
          to="/disclaimer"
          disabled={location.pathname === '/disclaimer'}
          color="text"
        />
        <Separator />
        <CustomLink
          text="LifeLearn Privacy Policy"
          to="https://www.lifelearn.com/privacy-policy/"
          external
          color="text"
        />
        <Separator />
        <CustomLink
          text="Publications"
          to="/publisher"
          disabled={location.pathname === '/publisher'}
          color="text"
        />
        <Separator />
        <CustomLink
          text="Help"
          to="https://www.lifelearn.com/sofie-resources/"
          external
          color="text"
        />
        {isMobile && <Box pt={4} />}
        <Typography>
          Built with Watson - IBM Watson is a trademark of International Business Machines
          Corporation, registered in many jurisdictions worldwide.
        </Typography>
      </Box>
      {isMobile && <Box pt={4} />}
      <Box>
        <Typography>
          {`LIFELEARN ${String.fromCharCode(169)} ${new Date().getFullYear()}`}
        </Typography>
      </Box>
      {isMobile && <Box pt={7} />}
    </Box>
  );
};

Footer.propTypes = {};

export default Footer;
