import React from 'react';
import * as Proptypes from 'prop-types';
import { Redirect, Route, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

/**
 * Handles logic deciding if a user can go to a protected page. If not decides where they should go.
 * @param {object} props - defined in proptypes
 * @returns {*} - Page to render.
 * @class
 */
const PrivateRoute = (props) => {
  const {
    render, path, history, user, embed, auth
  } = props;

  /**
   * Determines if user has authorization to view a page.
   * @returns {boolean} - if user can view page
   */
  function routeAuthentication() {
    if (!user.token) {
      return false;
    }

    if (!user.agreementAgreed && path.localeCompare('/terms-and-conditions') !== 0
      && path.localeCompare('/logout') !== 0) {
      return false;
    }

    if (!user.agreementAgreed && (path.localeCompare('/terms-and-conditions') === 0
      || path.localeCompare('/logout') === 0)) {
      return true;
    }

    if (user.accountID === 4 && !user.tookSurvey && path.localeCompare('/ask') === 0
      && history.location.search.localeCompare('?done=yes') === 0) {
      return true;
    }

    if (user.accountID === 4 && !user.tookSurvey && path.localeCompare('/student/survey') !== 0
      && path.localeCompare('/logout') !== 0) {
      return false;
    }

    if (user.accountID === 4 && user.tookSurvey && path.localeCompare('/student/survey') === 0) {
      return false;
    }

    if (user.accountID !== 4 && path.localeCompare('/student/survey') === 0) {
      return false;
    }

    if (path.localeCompare('/profile') === 0) {
      return !embed && (user.role === 5 || user.role === 6);
    }
    return true;
  }

  return (
    <Route
      exact
      path={path}
      render={(routeAuthentication() ? render
        : () => {
          if (user.token && !user.agreementAgreed) {
            return (<Redirect push to="/terms-and-conditions" />);
          }
          if (user.token && user.accountID === 4 && !user.tookSurvey) {
            return (<Redirect push to="/student/survey" />);
          }
          if (user.token) {
            return (<Redirect push to="/ask" />);
          }

          if (auth.lastUserType === 4) {
            return (<Redirect push to="/student" />);
          }

          return (<Redirect push to="/" />);
        }
      )}
    />
  );
};

/**
 * Maps items from the redux store to apps props.
 * @param {object} state - des
 * @returns {{user: *}} - maps user from redux to apps props
 */
function mapStateToProps(state) {
  return {
    user: state.user,
    auth: state.auth
  };
}

export default withRouter(connect(mapStateToProps, null)(PrivateRoute));

PrivateRoute.propTypes = {
  render: Proptypes.func.isRequired,
  path: Proptypes.string.isRequired,
  embed: Proptypes.bool.isRequired,
  auth: Proptypes.shape({
    lastUserType: Proptypes.number
  }).isRequired,
  user: Proptypes.shape({
    token: Proptypes.string,
    agreementAgreed: Proptypes.bool,
    accountID: Proptypes.number,
    tookSurvey: Proptypes.bool,
    role: Proptypes.number,
  }).isRequired,
  history: Proptypes.shape({
    location: Proptypes.shape({
      search: Proptypes.string
    })
  }).isRequired
};
