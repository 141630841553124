/*
  Original JavaScript code by Chirp Internet: www.chirp.com.au
  Please acknowledge use of this code by including this header.
  https://www.the-art-of-web.com/javascript/search-highlight/
  Modified by LifeLearn to work with React link original above.
*/

/* eslint-disable no-plusplus */
/**
 * Finds all occurrences of key word held within the starting node and highlights them.
 * @param {string } start - reference to parent DOM element.
 * @param {string} keywords - words to highlight as a single string space separated
 * @param {string} tag - tag to use to highlight key words
 */
export default function Highlighter(start, keywords, tag = 'HIGHLIGHTER') {
  // private variables
  const targetNode = start;
  const hiliteTag = tag || 'HIGHLIGHTER';
  const skipTags = new RegExp(`^(?:${hiliteTag}|SCRIPT|FORM|SPAN)$`);
  const colors = ['#a0ffff', '#9f9', '#f99', '#f6f'];
  const wordColor = [];
  let colorIdx = 0;
  let matchRegExp = '';
  // if a both sides are closed (false) matches the word exactly
  // opening a side allows for characters to appear before (left) or after (right) of the match.
  const openLeft = true;
  const openRight = true;

  // characters to strip from start and end of the input string
  const endRegExp = new RegExp('^[^\\w]+|[^\\w]+$', 'g');

  // characters used to break up the input string into words
  const breakRegExp = new RegExp('[^\\w\'-]+', 'g');

  /**
   * Sets the Regex used to match words and avoid tags.
   * @param {string} input - keywords
   * @returns {RegExp|boolean} - REGEX
   */
  function setRegex(input) {
    let newInput = input.replace(endRegExp, '');
    newInput = newInput.replace(breakRegExp, '|');
    newInput = newInput.replace(/^\||\|$/g, '');
    if (newInput) {
      // let re = `(${newInput})`;
      // if (!openLeft) {
      //   re = `\\b${re}`;
      // }
      // if (!openRight) {
      //   re += '\\b';
      // }

      let re = `\\b(${newInput})\\b`;

      matchRegExp = new RegExp(re, 'i');
      return matchRegExp;
    }
    return false;
  }

  /**
   * Recursively applies word highlighting.
   * @param {HTMLElement} node - starting node
   */
  function hiliteWords(node) {
    if (node === undefined || !node) {
      return;
    }

    if (!matchRegExp) {
      return;
    }

    if (skipTags.test(node.nodeName)) {
      return;
    }

    if (node.hasChildNodes()) {
      for (let i = 0; i < node.childNodes.length; i++) hiliteWords(node.childNodes[i]);
    }
    if (node.nodeType === 3) { // NODE_TEXT
      const nv = node.nodeValue;
      const regs = matchRegExp.exec(nv);
      if (nv && regs) {
        if (!wordColor[regs[0].toLowerCase()]) {
          wordColor[regs[0].toLowerCase()] = colors[colorIdx++ % colors.length];
        }

        const match = document.createElement(hiliteTag);
        match.appendChild(document.createTextNode(regs[0]));
        match.style.backgroundColor = wordColor[regs[0].toLowerCase()];
        match.style.color = '#000';

        const after = node.splitText(regs.index);
        after.nodeValue = after.nodeValue.substring(regs[0].length);
        node.parentNode.insertBefore(match, after);
      }
    }
  }

  /**
   * Removes highlighting
   */
  function remove() {
    const arr = document.getElementsByTagName(hiliteTag);
    const el = arr[0];
    while (arr.length && el) {
      const parent = el.parentNode;
      parent.replaceChild(el.firstChild, el);
      parent.normalize();
    }
  }

  // main executable
  remove();
  if (keywords === undefined) {
    return;
  }
  keywords.replace(/(^\s+|\s+$)/g, '');
  if (!keywords) {
    return;
  }

  if (setRegex(keywords)) {
    hiliteWords(targetNode);
  }
}
