import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { BrowserRouter, HashRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { StylesProvider } from '@material-ui/core/styles';
import { StyledEngineProvider } from '@mui/material';
import { store, persistor } from './reducers/store';
import App from './components/App/App';

/**
 * The function that initializes the sofie application. It breaks apart the params if they exist.
 * The App component is able to handle null values for params.
 * Mars has a special login procedure hence it has a special param for it.
 * @param {object} params - params used to initialize the app.
 * @param {boolean} params.embed - if sofie is embedded in a third party application.
 * @param {object} params.credentials - details about the user user used for login & registration.
 */
// eslint-disable-next-line import/prefer-default-export
export function init(params = {}) {
  if (params.embed) {
    ReactDOM.render(
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <HashRouter>
            <StylesProvider injectFirst>
              <StyledEngineProvider>
                <App
                  embed={params.embed}
                  credentials={params.credentials}
                  maintenance={params.maintenance}
                />
                <ToastContainer />
              </StyledEngineProvider>
            </StylesProvider>
          </HashRouter>
        </PersistGate>
      </Provider>,
      document.getElementById('sofie-embed')
    );
  } else {
    ReactDOM.render(
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter>
            <StylesProvider injectFirst>
              <App
                embed={params.embed}
                credentials={params.credentials}
                maintenance={params.maintenance}
              />
              <ToastContainer />
            </StylesProvider>
          </BrowserRouter>
        </PersistGate>
      </Provider>,
      document.getElementById('sofie-embed')
    );
  }
}
