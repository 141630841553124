import { Box } from '@mui/material';
import React from 'react';
import Title from './Title';
import Body from './Body';

const PageOne = () => (
  <>
    <Title
      color="lightBlue.main"
      size={1.25}
    >
      New Books
    </Title>
    <Box mt={0.75} />
    <Title color="darkGrey.main">
      Blackwell&apos;s Five-Minute Veterinary Consult Clinical Companion: Small Animal Toxicology,
      2nd edition
    </Title>
    <Body size={0.875}>Hovda, Brutlag, Poppenga, Peterson; Wiley-Blackwell 2016</Body>
    <Box mt={0.75} />
    <Title color="darkGrey.main">Carpenters&apos;s Exotic Animal Formulary, 6th edition</Title>
    <Body size={0.875}>Carpenter; 2023</Body>
    <Box mt={0.75} />
    <Title
      color="lightBlue.main"
      size={1.25}
    >
      Updated Content
    </Title>
    <Box mt={0.75} />
    <Title color="darkGrey.main">VMX Conference Proceedings 2023</Title>
    <Box mt={0.75} />
    <Title color="darkGrey.main">Today&apos;s Veterinary Practice Journal 2013 to 2023</Title>
  </>
);

PageOne.propTypes = {};

export default PageOne;
