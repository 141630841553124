import React from 'react';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Zoom from '@material-ui/core/Zoom';
import Fab from '@material-ui/core/Fab';
import './ScrollWrapper.scss';

/**
 * Handles showing the scroll to top button and setting up where the top of the page is defined.
 * @returns {*} - scroll to top
 * @class
 */
function ScrollWrapper() {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 1,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector('#back-to-top-anchor');

    if (anchor) {
      anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  return (
    <Zoom in={trigger}>
      <div onClick={handleClick} role="presentation" className="scrollWrapper">
        <Fab color="secondary" size="small" aria-label="scroll back to top">
          <i className="fas fa-angle-up scrollWrapper_button" />
        </Fab>
      </div>
    </Zoom>
  );
}

export default ScrollWrapper;
