import React from 'react';
import * as Proptypes from 'prop-types';
import Collapse from '@material-ui/core/Collapse';
// eslint-disable-next-line import/no-named-default
import { default as MUIAlert } from '@material-ui/lab/Alert/Alert';
import './Alert.scss';

/**
 * Displays inline alerts and handles closing animations for them.
 * @param {object} props - defined in proptypes.
 * @returns {*} - Alert and animation component.
 * @class
 */
const Alert = (props) => {
  const {
    children, onClose, show, type,
    customClass, useDefaultClass
  } = props;

  // Figures out the styling.
  let styles = { root: 'Alert' };

  if (customClass) {
    styles = customClass;
  } else if (useDefaultClass) {
    styles = {};
  }

  if (onClose !== null) {
    return (
      <Collapse in={show} addEndListener={() => {}}>
        <MUIAlert onClose={onClose} severity={type} classes={styles}>
          {children}
        </MUIAlert>
      </Collapse>
    );
  }

  return (
    <MUIAlert severity={type} classes={styles}>
      {children}
    </MUIAlert>
  );
};

export default Alert;

Alert.propTypes = {
  show: Proptypes.bool.isRequired,
  children: Proptypes.oneOfType([Proptypes.string, Proptypes.element]).isRequired,
  type: Proptypes.oneOf([
    'error', 'warning', 'info', 'success'
  ]),
  onClose: Proptypes.func,

  // CSS Props
  customClass: Proptypes.shape({
    root: Proptypes.string,
    icon: Proptypes.string
  }),
  useDefaultClass: Proptypes.bool,
};

Alert.defaultProps = {
  type: 'error',
  onClose: null,
  customClass: null,
  useDefaultClass: false
};
