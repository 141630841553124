export const policies = {
  names: {
    signIn: SIGN_IN_POLICY,
    forgotPassword: FORGOT_PASSWORD_POLICY
  },
  authorities: {
    signIn: {
      authority: AZURE_SSO_URL + SIGN_IN_POLICY,
    },
    forgotPassword: {
      authority: AZURE_SSO_URL + FORGOT_PASSWORD_POLICY,
    },
  },
};

export const msalConfig = {
  auth: {
    authority: policies.authorities.signIn.authority,
    clientId: AZURE_CLIENT_ID,
    redirectUri: `${APP_URL}/handle-redirect`,
    knownAuthorities: [AZURE_AUTHORITY],
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false,
  },
};

export const loginRequest = {
  scopes: ['openid', 'profile']
};
