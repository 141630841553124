import { applyMiddleware, compose, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { createStateSyncMiddleware, initMessageListener } from 'redux-state-sync';
import rootReducer from './root';
import * as actions from '../actions/actions';

const logger = createLogger({
  // ...options
});

const persistConfig = {
  key: 'sofie-state',
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
const reduxStateSyncConfig = {
  // blacklist: ['persist/PERSIST', 'persist/REHYDRATE'],
  whitelist: [actions.LOGIN_SUCCESS, actions.LOGIN_REQUEST]
};

// allows for middle ware to easily be turned on and off
const middleware = [
  thunk,
  createStateSyncMiddleware(reduxStateSyncConfig),
  // logger,
  APP_ENV.localeCompare('production') !== 0 && logger
].filter(Boolean);

export const store = createStore(
  persistedReducer,
  composeWithDevTools(
    compose(
      applyMiddleware(...middleware)
    )
  )
);

initMessageListener(store);

export const persistor = persistStore(store);
