import React, { Component } from 'react';
import * as Proptypes from 'prop-types';
import axios from 'axios';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { toast } from 'react-toastify';
import Spinner from 'react-bootstrap/Spinner';
import { updateUserDetails } from '../../actions/userUpdates/updateUser';
import AuthRequest from '../../api/AuthRequest';
import ConfirmationModal from '../Modals/ConfirmationModal/ConfirmationModal';
import Notification from '../Notification/Notification';
import UserProfile from './UserProfile/UserProfile';
import Button from '../Button/Button';
import Checkbox from '../Checkbox/Checkbox';
import Search from '../Search/Search';
import AddUser from './AddUser/AddUser';
import './ProfilePage.scss';

/**
 * Generates the profile page.
 */
class ProfilePage extends Component {
  /**
   * Initializes component
   * @param {object} props - defined in proptypes
   */
  constructor(props) {
    super(props);
    this.state = {
      selectedAll: false,
      licenses: null,
      used: null,
      users: [],
      usersFiltered: [],
      selected: [],
      roles: null,
      modalOpen: false,
      smallScreen: false
    };
    this.getUsers = this.getUsers.bind(this);
    this.getRoles = this.getRoles.bind(this);
    this.filterUsers = this.filterUsers.bind(this);
    this.deleteUser = this.deleteUser.bind(this);
    this.selectAll = this.selectAll.bind(this);
    this.isChecked = this.isChecked.bind(this);
    this.selectItem = this.selectItem.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.renderActions = this.renderActions.bind(this);
  }

  /**
   * Retrieves the data from the backend for the page.
   */
  componentDidMount() {
    this.cancelGetRoles = axios.CancelToken.source();
    this.cancelGetUsers = axios.CancelToken.source();
    this.getRoles();
    this.getUsers();
    window.addEventListener('resize', this.resize.bind(this));
    this.resize();
  }

  /**
   * Cancels axios requests if component unmounts before they can be completed.
   */
  componentWillUnmount() {
    this.cancelGetUsers.cancel();
    this.cancelGetRoles.cancel();
  }

  /**
   * Retrieves the user and licence data from the backend.
   */
  getUsers() {
    AuthRequest({
      method: 'get',
      url: 'user/list',
      cancelToken: this.cancelGetUsers.token
    }).then((res) => {
      this.setState({
        users: res.data.message.users,
        usersFiltered: res.data.message.users,
        licenses: res.data.message.total_licenses,
        used: res.data.message.users.length,
      });
    });
  }

  /**
   * Retrieves the roles data from the backend.
   */
  getRoles() {
    AuthRequest({
      method: 'get',
      url: 'role/list',
      cancelToken: this.cancelGetUsers.token
    }).then((res) => {
      this.setState({
        roles: res.data.roles.role,
      });
    });
  }

  /**
   * Figures out if the user is viewing the page on a large or small screen.
   */
  resize() {
    const { smallScreen } = this.state;
    const newScreen = (window.innerWidth <= 760);
    if (newScreen !== smallScreen) {
      this.setState({
        smallScreen: newScreen
      });
    }
  }

  /**
   * Calls the backend to remove all selected users.
   * On success it re-retrieves the users list.
   * On failure it only returns the error message.
   */
  deleteUser() {
    const { selected, usersFiltered } = this.state;

    const selectedUsers = usersFiltered.filter((user) => (selected.includes(user.user_id)));
    let message = `Removed
    ${selectedUsers.map((user) => ` ${user.first_name} ${user.last_name}`)}.`;

    if (selectedUsers.length > 1) {
      const pos = message.lastIndexOf(',');
      message = `${message.substring(0, pos)} and${message.substring(pos + 1)}`;
    }

    const title = selected.length > 1
      ? 'Successfully removed selected users'
      : 'Successfully removed selected user';

    AuthRequest({
      method: 'delete',
      url: 'user/delete',
      data: {
        user_id: selected,
      }
    }).then(() => {
      toast.success(
        <Notification
          title={title}
          body={message}
        />
      );
      this.getUsers();
      this.setState({
        selected: [],
        modalOpen: false,
        selectedAll: false
      });
    }).catch(() => {
      this.setState({
        modalOpen: false
      });
    });
  }

  /**
   * Filters the user list based on the users input in the search bar.
   * @param {string} query - the string to filter the users table against.
   */
  filterUsers(query) {
    const { users, roles } = this.state;

    const newFilteredUsers = users.filter((user) => (
      user.email.includes(query) || user.first_name.includes(query)
      || user.last_name.includes(query) || user.role_id.toString().includes(query)
      || roles.filter((role) => (
        role.name.toLowerCase().includes(query.toLowerCase()) && role.id === user.role_id
      )).length > 0
    ));

    this.setState({
      usersFiltered: newFilteredUsers
    });
  }

  /**
   * Selects all selectable users if none or some users are already selected.
   * If all users are already selected then it unselects all of them.
   */
  selectAll() {
    const { loggedInUser } = this.props;
    const { selected, roles, usersFiltered } = this.state;

    const selectableUsers = usersFiltered.filter((user) => (
      roles.filter((role) => (
        role.id === user.role_id && role.id !== 5 && user.role_id > loggedInUser.role
      )).length > 0
    ));

    if (selected.length !== selectableUsers.length) {
      const newSelected = [];
      selectableUsers.map((user) => (
        newSelected.push(user.user_id)
      ));
      this.setState({
        selected: newSelected,
        selectedAll: true,
      });
    } else {
      this.setState({
        selected: [],
        selectedAll: false
      });
    }
  }

  /**
   * Selects a specified item if it is not selected otherwise it unselects it.
   * @param {object} newItem - The id of the item to be selected or unselected.
   */
  selectItem(newItem) {
    const { loggedInUser } = this.props;
    const { selected, usersFiltered, roles } = this.state;

    const selectableUsers = usersFiltered.filter((user) => (
      roles.filter((role) => (
        role.id === user.role_id && role.id !== 5 && user.role_id > loggedInUser.role
      )).length > 0
    ));

    // if the item is selected unselect it
    if (selected.filter((item) => item === newItem).length > 0) {
      const newSelected = selected.filter((sel) => sel !== newItem);
      this.setState({
        selected: newSelected,
        selectedAll: false
      });

      // item is unselected select it
    } else {
      const newSelected = selected;
      newSelected.push(newItem);
      this.setState({
        selected: newSelected
      });
      // check if all items are selected
      if (selectableUsers.length === selected.length) {
        this.setState({
          selectedAll: true
        });
      }
    }
  }

  /**
   * Helper function that checks if a checkbox is selected.
   * @param {string} id - The ID object of the checkbox/row.
   * @returns {boolean} - true if selected false otherwise
   */
  isChecked(id) {
    const { selected } = this.state;
    return selected.filter((item) => item === id).length > 0;
  }

  /**
   * Toggles if the modal is open or closed.
   */
  toggleModal() {
    const { modalOpen } = this.state;
    this.setState({
      modalOpen: !modalOpen
    });
  }

  /**
   * Generates the actions section for a small or large screen.
   * @returns {*} - actions section
   */
  renderActions() {
    const { smallScreen, selectedAll, selected } = this.state;
    if (smallScreen) {
      return (
        <div className="ProfilePage_actions">
          <Search
            searchFunc={this.filterUsers}
            placeholder="filter users"
          />
          <div className="ProfilePage_actions_small">
            <Checkbox
              id="select all"
              checked={selectedAll || selected.length > 0}
              onClick={this.selectAll}
              type="secondary"
              label="Select All"
              effect
              indeterminate={!selectedAll && selected.length > 0}
            />
            <Button
              onClick={this.toggleModal}
              variant="primary-inverse"
              customContent="Delete "
              disabled={selected.length === 0}
            />
          </div>
        </div>
      );
    }

    return (
      <div className="ProfilePage_actions">
        <Button
          onClick={this.toggleModal}
          variant="primary-inverse"
          customContent="Delete"
          disabled={selected.length === 0}
        />
        <Search
          searchFunc={this.filterUsers}
          placeholder="filter users"
        />
      </div>
    );
  }

  /**
   * Renders component
   * @returns {*} - DOM description
   */
  render() {
    const { loggedInUser, updateLoggedInUser } = this.props;
    const {
      usersFiltered, used, licenses, roles, modalOpen, selected, selectedAll, smallScreen
    } = this.state;

    if (used == null || licenses == null || roles == null) {
      return (
        <div className="ProfilePage">
          <div className="ProfilePage_title">
            Fetching User Data
          </div>
          <div className="ProfilePage_loader">
            <Spinner animation="border" />
          </div>
        </div>
      );
    }

    const selectedUsers = usersFiltered.filter((user) => (selected.includes(user.user_id)));
    let message = `Delete:
    ${selectedUsers.map((user) => ` ${user.first_name} ${user.last_name}`)}?`;

    if (selectedUsers.length > 1) {
      const pos = message.lastIndexOf(',');
      message = `${message.substring(0, pos)} and${message.substring(pos + 1)}`;
    }

    const title = selected.length > 1
      ? 'Are you sure you want to delete these users?'
      : 'Are you sure you want to delete this user?';

    return (
      <div className="ProfilePage">
        <ConfirmationModal
          isOpen={modalOpen}
          onCancel={this.toggleModal}
          onConfirm={this.deleteUser}
          message={message}
          title={title}
        />
        <div className="ProfilePage_title">User Profiles</div>
        {this.renderActions()}
        <div className="ProfilePage_holder">
          <table className="ProfilePage_table">
            <thead>
              <tr className="ProfilePage_table_header">
                <th className="ProfilePage_table_header_check">
                  {!smallScreen && (
                    <Checkbox
                      id="select all"
                      checked={selectedAll || selected.length > 0}
                      onClick={this.selectAll}
                      type="secondary"
                      effect
                      indeterminate={!selectedAll && selected.length > 0}
                    />
                  )}
                </th>
                <th className="ProfilePage_table_header_col">First Name </th>
                <th className="ProfilePage_table_header_col">Last Name </th>
                <th className="ProfilePage_table_header_col">Email </th>
                <th className="ProfilePage_table_header_role">Type </th>
                <th className="ProfilePage_table_header_actions">Action </th>
              </tr>
            </thead>
            <tbody>
              {usersFiltered.map((user) => (
                <UserProfile
                  key={user.user_id}
                  user={user}
                  roles={roles}
                  isSelected={this.isChecked(user.user_id)}
                  select={this.selectItem}
                  loggedInUser={loggedInUser}
                  updateLoggedInUser={updateLoggedInUser}
                />
              ))}
            </tbody>
          </table>
        </div>
        <AddUser
          getUsers={this.getUsers}
          licenses={licenses}
          used={used}
          roles={roles}
          loggedInUser={loggedInUser}
        />
      </div>
    );
  }
}

/**
 * Maps items from the redux store to apps props.
 * @param {object} state - des
 * @returns {{user: *}} - maps user from redux to apps props
 */
function mapStateToProps(state) {
  return {
    loggedInUser: state.user
  };
}

/**
 * Maps actions to component props.
 * @param {Dispatch} dispatch - allows action creators to work with redux.
 * @returns {{logout: *, authToken: *, login: *}} - bound action creators
 */
function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    updateLoggedInUser: updateUserDetails
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePage);

ProfilePage.propTypes = {
  updateLoggedInUser: Proptypes.func.isRequired,
  loggedInUser: Proptypes.shape({
    role: Proptypes.number
  }).isRequired
};
