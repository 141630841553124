import React from 'react';
import * as Proptypes from 'prop-types';
import { connect } from 'react-redux';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Heartbeat from '../Heartbeat/Heartbeat';
import Header from './Header';
import MobileHeader from './MobileHeader';
import BottomNavBar from './BottomNavBar';
import Footer from './Footer';
import ScrollWrapper from '../ScrollWrapper/ScrollWrapper';
import EmbedHeartbeat from '../Heartbeat/EmbedHeartbeat';
import UpdateModal from '../Modals/UpdateModal/UpdateModal';

/**
 * Page wrapper used to add universal components to a page and connect them to Redux.
 * @param {object} props - defined in proptypes
 * @returns {*} - Page container
 * @class
 */
const Page = (props) => {
  const {
    header,
    footer,
    children,
    heartbeat,
    embed,
    credentials,
    showLogout,
    user,
    paddingTop,
    showNavBar,
  } = props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

  let heartbeatDisplay = <></>;

  if (heartbeat) {
    if (embed) {
      heartbeatDisplay = <EmbedHeartbeat credentials={credentials} />;
    } else {
      heartbeatDisplay = <Heartbeat />;
    }
  }

  const drawerWidth = 250;

  return (
    <Box
      sx={{
        height: '100%',
        minHeight: '100vh',
        minHeight: 'calc(var(--vh, 1vh) * 100)',
        display: 'flex',
        flexFlow: 'column nowrap',
        flex: '1 1 auto',
      }}
    >
      <Box id="back-to-top-anchor" />
      <Box
        sx={{
          display: 'flex',
          pt: isMobile ? 1 : paddingTop,
          flexGrow: 1,
        }}
      >
        {!isMobile && showNavBar && (
          <Box
            sx={{
              width: drawerWidth,
            }}
          >
            {header && (
              <Header
                embed={embed}
                drawerWidth={drawerWidth}
              />
            )}
          </Box>
        )}
        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            width: '100%'
          }}
        >
          <Box
            sx={{
              flexGrow: 1,
              display: 'flex',
              flexDirection: 'column',
              height: '100%'
            }}
          >
            {showLogout && isMobile && <MobileHeader />}
            {children}
          </Box>
          {footer && <Footer />}
        </Box>
      </Box>
      {isMobile && header && <BottomNavBar />}
      {heartbeatDisplay}
      <ScrollWrapper />
      <UpdateModal
        open={!user.updatePopupSeen && user.agreementAgreed && !user.updatePopupSeenSession}
      />
    </Box>
  );
};

/**
 * Maps items from the redux store to apps props.
 * @param {object} state - des
 * @returns {{user: *}} - maps user from redux to apps props
 */
function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps, null)(Page);

Page.propTypes = {
  header: Proptypes.bool,
  footer: Proptypes.bool,
  heartbeat: Proptypes.bool,
  embed: Proptypes.bool.isRequired,
  children: Proptypes.element.isRequired,
  user: Proptypes.shape({
    firstName: Proptypes.string,
    lastName: Proptypes.string,
    role: Proptypes.number,
    updatePopupSeen: Proptypes.bool.isRequired,
    updatePopupSeenSession: Proptypes.bool.isRequired,
    agreementAgreed: Proptypes.bool.isRequired,
  }).isRequired,
  history: Proptypes.shape({
    push: Proptypes.func,
  }).isRequired,
  credentials: Proptypes.shape({
    third_party_token: Proptypes.string,
    user_details: Proptypes.shape({
      user_id: Proptypes.number,
    }),
  }).isRequired,
  showLogout: Proptypes.bool,
  paddingTop: Proptypes.number,
  showNavBar: Proptypes.bool,
};

Page.defaultProps = {
  header: true,
  footer: true,
  heartbeat: true,
  showLogout: true,
  showNavBar: true,
  paddingTop: 5.5,
};
