import React, { useState, useEffect } from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Box,
  TextField,
  Button,
  FormGroup,
  FormControlLabel,
  RadioGroup,
  Radio,
  Typography,
  Link,
} from '@mui/material';
import ReactGA from 'react-ga';
import ReactGA4 from 'react-ga4';
import SearchIcon from '@mui/icons-material/Search';
import { useHistory, useParams, Link as RouterLink } from 'react-router-dom';
import axios from 'axios';
import { useTheme, styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  checkKeyWords,
} from '../../actions/ask/askActions';
import BestPracticesModal from '../Modals/BestPracticesModal/BestPracticesModal';

const InputContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'middle',
})(({ theme, middle }) => ({
  width: '100%',
  paddingLeft: middle ? theme.spacing(4) : '26px',
  paddingRight: '26px',
  marginLeft: 0,
  [theme.breakpoints.up('lg')]: {
    width: '90%',
    paddingLeft: 0,
    paddingRight: middle ? 0 : theme.spacing(10.875),
    marginLeft: middle ? 0 : theme.spacing(5),
    padding: theme.spacing(1),
  },
  [theme.breakpoints.up('xl')]: {
    width: middle ? '63.2%' : '77%',
  },
}));

const BarContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'middle',
})(({ theme, middle }) => ({
  paddingLeft: 0,
  width: '100%',
  [theme.breakpoints.up('lg')]: {
    display: 'flex',
    flexDirection: middle ? 'column' : 'row',
    paddingLeft: middle ? 0 : theme.spacing(4.5),
    paddingRight: middle ? 0 : theme.spacing(10.875),
    alignItems: middle ? 'center' : 'flex-start',
  },
  [theme.breakpoints.up('xl')]: {
    paddingLeft: middle ? 0 : theme.spacing(23.625),
  },
}));

const Title = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'middle',
})(({ theme, middle }) => ({
  display: middle ? 'block' : 'none',
  fontSize: '2.5rem',
  fontWeight: 600,
  textAlign: 'center',
  whiteSpace: 'nowrap',
  marginBottom: theme.spacing(3),
  [theme.breakpoints.up('lg')]: {
    fontSize: middle ? '3.5rem' : '2.75rem',
    display: 'block',
    fontWeight: 'bold',
    marginBottom: theme.spacing(7.5),
  },
}));

const MyRadio = styled(FormControlLabel, {
  shouldForwardProp: (prop) => prop !== 'middle',
})(({ theme, middle }) => ({
  '& .MuiTypography-root': {
    fontWeight: 600,
    fontSize: '0.9rem',
    color: middle ? theme.palette.text.primary : theme.palette.darkGrey.main,
  },
  '& .MuiRadio-root': {
    color: middle ? theme.palette.text.primary : theme.palette.darkGrey.main,
    padding: '3px',
    '&.Mui-checked': {
      color: middle ? theme.palette.text.primary : theme.palette.darkGrey.main,
    },
  },
  [theme.breakpoints.up('lg')]: {
    '& .MuiTypography-root': {
      fontWeight: 'bold',
    },
    '& .MuiRadio-root': {
      padding: '9px',
    },
  },
}));

const SofieForm = styled(FormGroup, {
  shouldForwardProp: (prop) => prop !== 'middle',
})(({ theme, middle }) => ({
  flexGrow: 1,
  pl: middle ? 0 : 2,
  flexWrap: 'nowrap',
  [theme.breakpoints.up('lg')]: {
    flexGrow: 0,
  },
}));

const SofieInput = styled(TextField, {
  shouldForwardProp: (prop) => prop !== 'middle',
})(({ theme }) => ({
  flexGrow: 1,
  '& fieldset': {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
  },
  '& legend': {
    width: 'auto',
  },
  '& .MuiOutlinedInput-input': {
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  '& .MuiOutlinedInput-root': {
    height: '100%',
  },
  [theme.breakpoints.up('lg')]: {
    '& .MuiOutlinedInput-root': {
      height: 'auto',
    },
    '& .MuiOutlinedInput-input': {
      paddingTop: '16px',
      paddingBottom: '16px',
    },
  },
}));

const SofieButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'middle',
})(({ theme }) => ({
  whiteSpace: 'nowrap',
  borderTopLeftRadius: 0,
  borderBottomLeftRadius: 0,
  borderTopRightRadius: 8,
  borderBottomRightRadius: 8,
  color: 'white.main',
  textTransform: 'none',
  px: 0,
  fontSize: '1.1rem',
  [theme.breakpoints.up('lg')]: {
    pl: 5.5,
  },
}));

const CategoryContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'middle',
})(({ theme }) => ({
  paddingTop: theme.spacing(1),
  paddingLeft: 0,
  [theme.breakpoints.up('lg')]: {
    paddingLeft: theme.spacing(3),
  },
}));

const SofieIcon = styled(Link, {
  shouldForwardProp: (prop) => prop !== 'middle',
})(({ theme, middle }) => ({
  display: middle ? 'none' : 'inline-block',
  marginRight: middle ? 0 : theme.spacing(2),
  textDecoration: 'none',
  color: theme.palette.lightBlue.main,
  '&:hover, &:active': {
    textDecoration: 'none',
    color: theme.palette.lightBlue.main,
  },
  [theme.breakpoints.up('lg')]: {
    display: 'none',
  },
}));

const CategoryGroup = styled(FormGroup, {
  shouldForwardProp: (prop) => prop !== 'middle',
})(({ theme, middle }) => ({
  '& .MuiFormGroup-row': {
    justifyContent: middle ? 'space-around' : 'center',
  },
  [theme.breakpoints.up('sm')]: {
    '& .MuiFormGroup-row': {
      justifyContent: 'flex-start',
    },
  },
}));

/**
 * Component for the ask section
 * @param {object} props - defined in proptypes
 * @returns {*} - DOM description
 */
const AskSofieBar = (props) => {
  const {
    currentQuestion,
    currentCategory,
    bestPracticesModalOpen,
    dispatchCheckKeywords,
    location,
  } = props;

  const history = useHistory();
  const params = useParams();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

  const [askQuery, setAskQuery] = useState(params.query ?? '');
  const [category, setCategory] = useState(params.category ?? '2');

  const cancelAsk = axios.CancelToken.source();
  const middle = location === 'middle';

  const categories = [
    { name: 'Dogs & Cats', id: '2' },
    { name: 'Exotics', id: '4' },
    { name: 'Drug Formulary', id: '3' },
  ];

  /**
   * Sends users query to backend to be processed by Watson.
   * @param {string} question - users natural language query.
   * @param {number} category - numerical representation of the category
   */
  useEffect(() => {
    if (params.query) {
      if (
        !currentQuestion
        || currentQuestion !== params.query
        || currentCategory !== params.category
      ) {
        dispatchCheckKeywords(params.query, params.category, cancelAsk);
      }
    }
  }, [params.query, params.category]);

  const categoriesDisplay = categories.map((item) => (
    <MyRadio
      key={item.id}
      value={item.id}
      control={(
        <Radio
          size={isMobile ? 'small' : 'medium'}
        />
      )}
      label={item.name}
      middle={middle}
    />
  ));

  const handleSearch = () => {
    if ((params.query !== askQuery || params.category !== category) && askQuery) {
      ReactGA.event({
        category: 'Ask',
        action: 'query',
        label: askQuery,
      });
      ReactGA4.event('ask-query', {
        question: askQuery,
      });
      history.push(`/ask/${category}/${askQuery}`);
    }
  };

  const handleAskChange = (event) => {
    setAskQuery(event.target.value.replace('%', ''));
  };

  const handleCategoryChange = (event) => {
    ReactGA.event({
      category: 'Ask',
      action: 'Change-Category',
      label: event.target.value,
    });
    ReactGA4.event('change-category', {
      category: event.target.value,
    });
    setCategory(event.target.value);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  return (
    <BarContainer middle={middle}>
      <Title middle={middle}>Ask Sofie</Title>
      <InputContainer middle={middle}>
        <Box
          sx={{
            display: isMobile ? 'flex' : 'block',
            flexDirection: 'column',
          }}
        >
          <SofieIcon
            middle={middle}
            component={RouterLink}
            to="/ask"
          >
            <Typography
              sx={{
                fontSize: '2.8125rem',
                mb: 1,
              }}
            >
              Sofie
            </Typography>
          </SofieIcon>
          <SofieForm row>
            <SofieInput
              variant="outlined"
              id="ask"
              placeholder="Ask Sofie a question..."
              onChange={handleAskChange}
              onKeyDown={(e) => {
                handleKeyPress(e);
              }}
              value={askQuery}
              inputProps={{
                'data-hj-allow': true,
                className: 'data-hj-allow'
              }}
            />
            <SofieButton
              disableElevation
              variant="contained"
              color="secondary"
              onClick={handleSearch}
            >
              {isMobile ? '' : 'Ask Sofie'}
              <SearchIcon
                sx={{
                  pl: 1,
                  fontSize: '2rem',
                }}
              />
            </SofieButton>
          </SofieForm>
        </Box>
        <CategoryContainer>
          <CategoryGroup middle={middle}>
            <RadioGroup
              onChange={handleCategoryChange}
              value={category}
              row
              aria-label="Type of search to perform"
              name="radio-buttons-group"
            >
              {categoriesDisplay}
            </RadioGroup>
          </CategoryGroup>
        </CategoryContainer>
        {typeof bestPracticesModalOpen === 'boolean' ? (
          <BestPracticesModal
            open={bestPracticesModalOpen}
            cancelToken={cancelAsk}
            question={params.query}
            category={params.category}
          />
        ) : (
          <></>
        )}
      </InputContainer>
    </BarContainer>
  );
};

/**
 * Maps items from the redux store to apps props.
 * @param {object} state - des
 * @returns {{user: *}} - maps user from redux to apps props
 */
function mapStateToProps(state) {
  return {
    currentQuestion: state.searchResults.current.question,
    currentCategory: state.searchResults.current.category,
    bestPracticesModalOpen: state.searchResults.bestPracticesModalOpen,
    theState: state
  };
}

/**
 * Maps actions to component props.
 * @param {Dispatch} dispatch - allows action creators to work with redux.
 * @returns {{askQuestion: *, check: *, uncheck: *}} - bound action creators
 */
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      dispatchCheckKeywords: checkKeyWords,
    },
    dispatch
  );
}

AskSofieBar.propTypes = {
  bestPracticesModalOpen: PropTypes.bool.isRequired,
  currentCategory: PropTypes.string,
  currentQuestion: PropTypes.string,
  dispatchCheckKeywords: PropTypes.func.isRequired,
  location: PropTypes.string,
};

AskSofieBar.defaultProps = {
  currentCategory: '',
  currentQuestion: '',
  location: 'middle',
};

export default connect(mapStateToProps, mapDispatchToProps)(AskSofieBar);
