import React, { useMemo, useState } from 'react';
import {
  Box, Typography, Button, Grid, Autocomplete, TextField
} from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import AuthRequest from '../../api/AuthRequest';
import LoadingPage from '../LoadingPage/LoadingPage';
import getSymptoms from '../../helpers/Symptoms';

const DDX = () => {
  const [category, setCategory] = useState('dog');
  const symptomsData = getSymptoms();
  const [selectedSymptoms, setSelectedSymptoms] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [results, setResult] = useState({
    loading: false,
    answers: []
  });

  if (inputValue !== '') {
    const index = symptomsData.findIndex((e) => e.name === inputValue);
    if (index === -1) {
      const newArray = {
        name: inputValue,
        value: inputValue,
      };
      symptomsData.push(newArray);
    }
  }

  if (selectedSymptoms.length > 0) {
    selectedSymptoms.forEach((symptom) => {
      const index = symptomsData.findIndex((e) => e.name === symptom.name);
      if (index === -1) {
        const newArray = {
          name: symptom.name,
          value: symptom.name,
        };
        symptomsData.push(newArray);
      }
    });
  }

  const handleCategoryChange = (e) => {
    setCategory(e.target.value);
  };

  const handleSubmit = () => {
    setResult({
      loading: true,
      answers: []
    });
    const query = selectedSymptoms.map((symptom) => (
      symptom.name
    ));

    AuthRequest({
      method: 'post',
      url: 'ddx',
      data: {
        query,
        category
      }
    })
      .then((res) => {
        setResult({
          loading: false,
          answers: res.data.message.answers,
        });
      })
      .catch((err) => {
        setResult({
          loading: false,
        });
        console.log(err);
      });
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <Typography
        sx={{
          fontSize: '3rem',
          fontWeight: 'bold',
        }}
      >
        DDX Test Page
      </Typography>
      <Box
        sx={{
          width: '100%',
          pl: 10,
        }}
      >
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            xs={8}
          >
            <Autocomplete
              multiple
              id="tags-outlined"
              options={symptomsData}
              getOptionLabel={(option) => option.name}
              filterSelectedOptions
              autoHighlight
              value={selectedSymptoms}
              inputValue={inputValue}
              onInputChange={(e, value) => { setInputValue(value); }}
              onChange={(event, newValue) => {
                setSelectedSymptoms(newValue);
              }}
              isOptionEqualToValue={(option, value) => option.name === value.name}
              renderInput={(params) => (
                <TextField
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                  label="DDX Search"
                />
              )}
            />
          </Grid>
          <Grid
            item
            xs={4}
          >
            <Button
              color="secondary"
              variant="contained"
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </Grid>
          <Grid
            item
            xs={12}
          >
            <FormControl>
              <FormLabel id="demo-radio-buttons-group-label">Category</FormLabel>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="female"
                name="radio-buttons-group"
                value={category}
                onChange={handleCategoryChange}
                row
              >
                <FormControlLabel
                  value="dog"
                  control={<Radio />}
                  label="Dog"
                />
                <FormControlLabel
                  value="cat"
                  control={<Radio />}
                  label="Cat"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
      </Box>
      {results.loading && <LoadingPage title="Loading Results" />}
      {results.answers.length > 0 && (
        <Box
          sx={{
            mt: 5,
          }}
        >
          {results.answers.map((result, key) => (
            <Typography key={result}>{`${key + 1}. ${result}`}</Typography>
          ))}
        </Box>
      )}
    </Box>
  );
};

DDX.propTypes = {};

export default DDX;
