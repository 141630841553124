import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';

const PublisherCard = (props) => {
  const { image, title, website } = props;
  return (
    <a href={website}>
      <img
        src={`${UM_URL}media/book-banner/${image}`}
        alt={title}
        style={{
          width: '100%',
          height: 'auto'
        }}
      />
    </a>
  );
};

PublisherCard.propTypes = {
  image: PropTypes.string.isRequired,
  website: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};

export default PublisherCard;
