import ReactGA from 'react-ga';
import ReactGA4 from 'react-ga4';
import AuthRequest from '../../api/AuthRequest';
import {
  ASK_SUCCESS, ASK_REQUEST, ASK_FAILURE, UPDATE_CACHE, SHOWED_MORE,
  SELECT_ARTICLE, UNSELECT_ARTICLE, SET_CHECKED
} from '../actions';
import * as actions from '../actions';
import StopWords from '../../helpers/StopWords';

// ===== ACTION CREATORS =====
/**
 * Action creator for sending a request to retrieve answer articles.
 * @returns {object} - action
 */
function askRequest() {
  return {
    type: ASK_REQUEST,
  };
}

/**
 * Action creator for retrieving answer articles.
 * @param {object} results - contains the current results data
 * @param {Array} cache - array of previous results
 * @returns {object} - action
 */
function askSuccess(results, cache) {
  return {
    type: ASK_SUCCESS,
    payload: {
      cache,
      current: results
    }
  };
}

/**
 * Action creator for failing to load answer articles.
 * @returns {object} - action
 */
function askFailure() {
  return {
    type: ASK_FAILURE,
  };
}

/**
 * Opens the best practices modal from a click on the button
 * @returns {object} - action
 */
export const openBestPracticesModalAuto = () => ({
  type: actions.OPEN_BEST_PRACTICES_MODAL_AUTO,
});

/**
 * Closes the best practices modal from a click on the button
 * @returns {object} - action
 */
export const closeBestPracticesModalAuto = () => ({
  type: actions.CLOSE_BEST_PRACTICES_MODAL_AUTO,
});

/**
 * Action creator for retrieving answer articles.
 * @returns {object} - action
 */
function bestPracticesIgnoredSuccess() {
  return {
    type: actions.BEST_PRACTICES_SUCCESS,
  };
}

/**
 * Action creator for retrieving answer articles.
 * @returns {object} - action
 */
function bestPracticesIgnoredFailure() {
  return {
    type: actions.BEST_PRACTICES_FAILURE,
  };
}

/**
 * Action creator for updating the list of answer articles.
 * @param {Array} cache - new cache that has stale items removed
 * @returns {object} - action
 */
function updateCache(cache) {
  return {
    type: UPDATE_CACHE,
    payload: {
      cache
    }
  };
}

/**
 * Action creator for checking an article.
 * @param {number} id - article id
 * @returns {object} - action
 */
export function selectArticle(id) {
  return {
    type: SELECT_ARTICLE,
    payload: { id }
  };
}

/**
 * Action creator for un-checking an article.
 * @param {number} id - article id
 * @returns {object} - action
 */
export function unselectArticle(id) {
  return {
    type: UNSELECT_ARTICLE,
    payload: { id }
  };
}

/**
 * Action creator for setting the list of checked articles.
 * @param {Array} ids - array of article ids
 * @returns {object} - action
 */
export function setChecked(ids) {
  return {
    type: SET_CHECKED,
    payload: ids
  };
}

/**
 * User clicked the show more button updated the show more tracker in redux.
 * @returns {object} - action
 */
export function showMoreSuccess() {
  return {
    type: SHOWED_MORE,
  };
}

// ===== MIDDLEWARE =====

/**
 * Creates the new state items to be sent by the askSuccess action.
 * @param {object} state - state before being updated
 * @param {object} newRes - result from backend
 * @returns {Function} -
 */
function setNewResult(state, newRes) {
  const current = state.searchResults.current;
  const cache = state.searchResults.cache;
  const newResult = {
    ...newRes,
    showedMore: false,
    fetched: Date.now()
  };

  if (cache.length === 100) {
    cache.pop();
  }

  if (current.question) {
    // make sure item is not already in the cache only a problem for the first search.
    if (current.question.localeCompare(cache[0].question) !== 0
      || current.category.localeCompare(cache[0].category) !== 0) {
      return {
        result: newResult,
        cache: [current].concat(cache)
      };
    }
    return {
      cache,
      result: newResult,
    };
  }

  return {
    result: newResult,
    cache: [newResult]
  };
}

/**
 * Update Redux to use a cached result.
 * @param {number} index - index of cached result
 * @param {object} state - previous state
 * @returns {object} - new state
 */
function useCachedResult(index, state) {
  const oldCache = state.searchResults.cache;
  const newCache = [state.searchResults.current].concat(
    oldCache.slice(0, index), oldCache.slice(index + 1, oldCache.length)
  );

  // reset the show more tracker (need to work with a copy not to accidentally mutate)
  const result = oldCache[index];
  result.showedMore = false;

  return {
    result,
    cache: newCache
  };
}

/**
 * Handles executing logout requests.
 * @param {string} question - nlq
 * @param {string} category - category for IBM
 * @param {object} cancelToken - token to cancel axios request if ask page is closed early.
 * @param {boolean} bestPracticesIgnored - send ignore best practices to server.
 * @returns {Function} - updates redux store
 */
export function askSofie(question, category, cancelToken, bestPracticesIgnored = false) {
  return (dispatch, getState) => {
    dispatch(askRequest());

    const state = getState();
    const cache = state.searchResults.cache;

    const cachedResult = cache.findIndex(
      (result) => result.category.localeCompare(category) === 0
        && result.question.localeCompare(question) === 0
    );
    // Was the latest cached result fetched in the last half hour?
    if (cachedResult !== -1 && Date.now() - cache[cachedResult].fetched <= 1800000) {
      const newState = useCachedResult(cachedResult, state);

      // Send the first 5 items to GA for publisher tracking
      const answers = newState.result.answers.slice(0, 10);
      answers.map((answer) => {
        ReactGA.event({
          category: 'Ask-Answer',
          action: newState.result.question,
          label: answer.bookInfo ? `${answer.bookInfo[0].id}` : '-1'
        });
        ReactGA4.event('ask-answer', {
          question: newState.result.question,
          article: answer.id,
        });
        return true;
      });
      if (bestPracticesIgnored) {
        AuthRequest({
          method: 'post',
          url: 'best-practices-ignore',
        })
          .then(() => {
            dispatch(bestPracticesIgnoredSuccess());
          })
          .catch(() => {
            dispatch(bestPracticesIgnoredFailure());
          }).finally(() => {
            dispatch(askSuccess(newState.result, newState.cache));
          });
      } else {
        dispatch(askSuccess(newState.result, newState.cache));
      }
    } else {
      // remove stale items
      if (cachedResult !== -1 && Date.now() - cache[cachedResult].fetched >= 1800000) {
        dispatch(updateCache([].concat(
          cache.slice(0, cachedResult), cache.slice(cachedResult + 1, cache.length)
        )));
      }

      // fetch new search results with category
      AuthRequest({
        method: 'get',
        url: 'ask',
        cancelToken: cancelToken.token,
        params: {
          nlq: question,
          category,
          best_practices_ignore: bestPracticesIgnored
        }
      }).then((res) => {
        const newState = setNewResult(state, res.data.message);

        // Send the first 5 items to GA for publisher tracking
        const answers = newState.result.answers.slice(0, 10);
        answers.map((answer) => {
          ReactGA.event({
            category: 'Ask-Answer',
            action: newState.result.question,
            label: answer.bookInfo ? `${answer.bookInfo[0].id}` : '-1'
          });
          ReactGA4.event('ask-answer', {
            question: newState.result.question,
            article: answer.id,
          });

          return true;
        });

        dispatch(askSuccess(newState.result, newState.cache));
        if (bestPracticesIgnored) {
          dispatch(bestPracticesIgnoredSuccess());
        }
      }).catch(() => {
        dispatch(askFailure());
      });
    }
  };
}

/**
 * Check how many keywords before performing a search
 * @param {string} question - nlq
 * @param {string} category - category
 * @param {object} cancelToken - token to cancel axios request if ask page is closed early.
 * @returns {Function} - updates redux store
 */
export const checkKeyWords = (question, category, cancelToken) => (
  (dispatch, getState) => {
    const bestPracticesIgnored = getState().user.bestPracticesIgnored;

    if (bestPracticesIgnored) {
      dispatch(askSofie(question, category, cancelToken));
    } else {
      const stopWords = StopWords();
      const questionArr = question.split(' ');

      for (let i = 0; i < questionArr.length; i += 1) {
        if (stopWords.includes(
          questionArr[i]
            .toLocaleLowerCase()
            .replace(/[.,/#!?$%^&*;:{}=\-_`~()]/g, '')
        )) {
          questionArr.splice(i, 1);
          i -= 1;
        }
      }

      if (questionArr.length < 2) {
        dispatch(openBestPracticesModalAuto(question, cancelToken));
      } else {
        dispatch(askSofie(question, category, cancelToken));
      }
    }
  }
);

/**
 * Check how many keywords before performing a search
 * @returns {Function} - updates redux store
 */
export const showMore = () => (
  (dispatch, getState) => {
    const state = getState();
    ReactGA.event({
      category: 'Ask',
      action: 'show-more',
    });
    ReactGA4.event('show-more');

    const answers = state.searchResults.current.answers.slice(10, 20);

    answers.map((answer) => {
      ReactGA.event({
        category: 'Ask-Answer',
        action: state.searchResults.current.question,
        label: answer.bookInfo ? `${answer.bookInfo[0].id}` : '-1',
      });
      ReactGA4.event('ask-answer', {
        question: state.searchResults.current.question,
        article: answer.id,
      });
      return true;
    });

    dispatch(showMoreSuccess());
  }
);

/**
 * Closes the best practices modal and performs search
 * @param {string} question - nlq
 * @param {string} category - category
 * @param {object} cancelToken - token to cancel axios request if ask page is closed early.
 * @param {boolean} bestPracticesIgnored - Ignore best practices modal to server
 * @returns {Function} - updates redux store
 */
export const closeAndSearch = (question, category, cancelToken, bestPracticesIgnored) => (
  (dispatch) => {
    dispatch(closeBestPracticesModalAuto());
    dispatch(askSofie(question, category, cancelToken, bestPracticesIgnored));
  }
);
