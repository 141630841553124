import { LOGOUT_INVALID_TOKEN } from '../actions';

// has to be in a file without an AuthRequest in order to not be cyclical.

// ===== ACTION CREATORS =====
/**
 * Action creator for "logging" the user out of redux.
 * @returns {object} - action
 */
export default function invalidToken() {
  return {
    type: LOGOUT_INVALID_TOKEN,
  };
}
