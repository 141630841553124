import React from 'react';
import * as Proptypes from 'prop-types';
import classNames from 'classnames';
import './Checkbox.scss';

/**
 * Generates a checkbox. There are several pre-defined variants.
 * Custom classes can be passed in to create a custom checkbox.
 * @param {object} props - defined in proptypes
 * @returns {*} - checkbox
 * @class
 */
const Checkbox = (props) => {
  const {
    checked, onClick, disabled, id, type, effect, label, labelLeft, minimal, onCheck, onUnCheck,
    iconAnimation, iconColour, effectSize, boxSize, indeterminate,
    customIconClass, customEffectClass, customCheckboxClass, customWrapperClass, customLabelClass,
    customBoxClass
  } = props;

  const wrapperClass = 'custom'.localeCompare(type) === 0 ? customWrapperClass
    : classNames(
      'checkbox', customWrapperClass
    );

  const checkboxClass = 'custom'.localeCompare(type) === 0 ? customCheckboxClass
    : classNames(
      `checkbox-${type}`, customCheckboxClass
    );

  const effectClass = 'custom'.localeCompare(type) === 0 ? customEffectClass
    : classNames(
      'checkbox-effect', customEffectClass
    );

  const boxClass = 'custom'.localeCompare(type) === 0 ? customBoxClass
    : classNames(
      'checkbox-box', !disabled && `checkbox-${type}-box`, customBoxClass,
      { 'checkbox-box-minimal': minimal },
      { 'checkbox-box-disabled': disabled },
    );

  const iconClass = 'custom'.localeCompare(type) === 0 ? customIconClass
    : classNames(
      { 'fas fa-check': !indeterminate }, { 'fas fa-minus': indeterminate },
      'checkbox-icon', customIconClass, `checkbox-icon-${iconColour}`,
      { 'checkbox-icon-rotate': 'rotate'.localeCompare(iconAnimation) === 0 },
      { 'checkbox-icon-disabled': disabled }
    );

  const labelRightClass = 'custom'.localeCompare(type) === 0 ? customLabelClass
    : classNames(
      'checkbox-label-right', customLabelClass,
      { 'checkbox-label-right-disabled': disabled }
    );

  const labelLeftClass = 'custom'.localeCompare(type) === 0 ? customLabelClass
    : classNames(
      'checkbox-label-left', customLabelClass,
      { 'checkbox-label-left-disabled': disabled }
    );

  // deals with checkbox sizing depending on what is active for the checkbox.
  let boxStyle = boxSize ? { width: boxSize, height: boxSize } : null;
  let effectStyle = effectSize ? { width: effectSize, height: effectSize } : null;
  if (!effect) {
    effectStyle = boxSize ? { width: boxSize, height: boxSize } : { width: '1rem', height: '1rem' };
    if (!label) {
      boxStyle = boxSize
        ? {
          width: boxSize, height: boxSize, top: '0%', left: '0%'
        }
        : { top: '0%', left: '0%' };
    }
  }

  /**
   * Handles user clicks on the checkbox.
   * If user has not provided a valid click method prints an error to the console.
   */
  function activated() {
    if (!disabled) {
      if (onClick) {
        onClick();
      } else if (onUnCheck && (checked || indeterminate)) {
        onUnCheck();
      } else if (onCheck && !checked && !indeterminate) {
        onCheck();
      } else {
        // eslint-disable-next-line no-console
        console.log('No onClick or onCheck and onUnCheck functions provided.');
      }
    }
  }

  /**
   * checks to see if the enter key has been pressed.
   * @param {object} e - event
   */
  function keyActivation(e) {
    if (e.key === 'Enter') {
      activated();
    }
  }

  /**
   * Input requires onChange function.
   * Since the input is not actually being used the onChange function is empty.
   */
  function onChange() {}

  /**
   * Checkbox consists of a label section and checkbox section.
   * Checkbox section has the original input, the effect box and the new checkbox and check icon.
   */
  return (
    <div
      aria-checked={checked}
      role="checkbox"
      tabIndex={0}
      onClick={activated}
      onKeyPress={keyActivation}
      className={wrapperClass}
      id={id}
    >
      {label && labelLeft && (
        <div className={labelLeftClass}>
          {label}
        </div>
      )}

      <div className={checkboxClass} style={effectStyle}>
        <input
          type="checkbox"
          className="checkbox-input"
          tabIndex={-1}
          checked={checked}
          onChange={onChange}
        />
        <div className={boxClass} style={boxStyle}>
          <i
            className={iconClass}
            style={{ color: iconColour }}
          />
        </div>
        { effect && !disabled && (
          <div className={effectClass} />
        )}
      </div>

      {label && !labelLeft && (
        <div className={labelRightClass}>
          {label}
        </div>
      )}
    </div>
  );
};

export default Checkbox;

Checkbox.propTypes = {
  checked: Proptypes.bool.isRequired,
  onClick: Proptypes.func,
  onCheck: Proptypes.func,
  onUnCheck: Proptypes.func,
  indeterminate: Proptypes.bool,
  disabled: Proptypes.bool,
  id: Proptypes.string,

  type: Proptypes.oneOf([
    'primary', 'secondary', 'custom'
  ]).isRequired,
  label: Proptypes.string,
  labelLeft: Proptypes.bool,
  effect: Proptypes.bool,
  iconAnimation: Proptypes.oneOf([
    'rotate', 'none'
  ]),
  iconColour: Proptypes.oneOf([
    'primary', 'secondary', 'success'
  ]),
  minimal: Proptypes.bool,
  boxSize: Proptypes.string,
  effectSize: Proptypes.string,

  customIconClass: Proptypes.string,
  customEffectClass: Proptypes.string,
  customBoxClass: Proptypes.string,
  customCheckboxClass: Proptypes.string,
  customLabelClass: Proptypes.string,
  customWrapperClass: Proptypes.string
};

Checkbox.defaultProps = {
  onClick: null,
  onCheck: null,
  onUnCheck: null,
  disabled: false,
  indeterminate: false,
  id: '',
  label: '',
  labelLeft: false,
  effect: false,
  iconAnimation: 'none',
  iconColour: null,
  minimal: false,
  boxSize: null,
  effectSize: null,
  customIconClass: null,
  customEffectClass: null,
  customCheckboxClass: null,
  customLabelClass: null,
  customWrapperClass: null,
  customBoxClass: null
};
