exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports

exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);"]);
exports.push([module.id, "@import url(https://use.fontawesome.com/releases/v5.7.2/css/all.css);"]);
// Module
exports.push([module.id, "@media only screen and (min-width: 760px){.UserProfile{display:flex;flex-flow:row nowrap;flex:0 1 auto;padding-bottom:2px;padding-top:2px;border-bottom:solid 1px #e7e7e7}}@media only screen and (max-width: 760px){.UserProfile_data{display:flex;flex-flow:column;height:100%;justify-content:center}}@media only screen and (min-width: 760px){.UserProfile_check{display:flex;flex-flow:column;align-items:center;flex:1;justify-content:center;max-width:32px;min-width:32px}}@media only screen and (max-width: 760px){.UserProfile_check{height:3rem;padding-left:0 !important}}@media only screen and (min-width: 760px){.UserProfile_col{display:flex;flex-flow:row wrap;align-items:center;flex:1 1 1rem;margin:0 0.5rem;overflow:hidden}}@media only screen and (max-width: 760px){.UserProfile_col{height:6rem;overflow-x:hidden;margin-right:4px}}@media only screen and (min-width: 760px){.UserProfile_buttons{display:flex;flex-flow:row wrap;align-items:center;flex:1 1 1rem;justify-content:center;max-width:16rem;margin:0 0.5rem 4px 0.5rem;overflow:hidden}}@media only screen and (max-width: 760px){.UserProfile_buttons{min-height:3rem;margin:2px 0 6px 0}}@media only screen and (min-width: 760px){.UserProfile_role{display:flex;flex-flow:row;align-items:center;flex:1 1 1rem;margin:0 0.5rem;overflow:hidden;max-width:8rem}}@media only screen and (max-width: 760px){.UserProfile_role{height:3rem;overflow-x:hidden;margin-right:4px;align-content:center}}@media only screen and (min-width: 760px){.UserProfile_input{padding-bottom:6px;padding-top:6px}}@media only screen and (max-width: 760px){.UserProfile_input{padding:6px}}.UserProfile_button{margin-top:4px;margin-right:4px}\n", ""]);
// Exports
exports.locals = {
	"primaryColor": "#006281",
	"secondaryColor": "#f8992d"
};