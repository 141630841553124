import {
  LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE,
  LOGOUT_COMPLETE, LOGOUT_INVALID_TOKEN,
  AUTHENTICATE_TOKEN_REQUEST, AUTHENTICATE_TOKEN_SUCCESS,
  REGISTRATION_REQUEST, REGISTRATION_FAILURE, REGISTRATION_SUCCESS,
  RESET_REDUX, AUTHENTICATE_TOKEN_FAILURE
} from '../../actions/actions';

const INIT_STATE = {
  errorMsg: '',
  loginInProgress: false,
  loginInProgressExpiry: 0,
  lastUserType: 1 // last users company ID.
};

const auth = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        errorMsg: '',
        loginInProgress: true,
        loginInProgressExpiry: Math.floor(new Date().getTime() / 1000) + 60
      };

    case LOGIN_FAILURE:
      return {
        ...state,
        errorMsg: action.payload.errorMsg,
        loginInProgress: false,
        loginInProgressExpiry: 0
      };

    case LOGIN_SUCCESS: {
      return {
        lastUserType: action.payload.accountID,
        loginInProgress: false,
        loginInProgressExpiry: 0,
        errorMsg: ''
      };
    }

    case LOGOUT_INVALID_TOKEN:
    case LOGOUT_COMPLETE:
      return {
        ...state,
        loginInProgress: false,
        loginInProgressExpiry: 0,
        errorMsg: ''
      };

    case REGISTRATION_REQUEST:
    case AUTHENTICATE_TOKEN_REQUEST:
    case AUTHENTICATE_TOKEN_SUCCESS:
    case AUTHENTICATE_TOKEN_FAILURE:
    case REGISTRATION_FAILURE:
    case REGISTRATION_SUCCESS:
      return {
        ...state,
        errorMsg: ''
      };
    case RESET_REDUX:
      return INIT_STATE;

    default:
      return state;
  }
};

export default auth;
