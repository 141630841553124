exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports

exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);"]);
exports.push([module.id, "@import url(https://use.fontawesome.com/releases/v5.7.2/css/all.css);"]);
// Module
exports.push([module.id, ".errorPage{display:flex;flex-flow:row nowrap;width:90%;align-self:center}.errorPage_left{display:flex;flex-flow:column nowrap;flex:1 0 50%;justify-content:center;border-bottom:4px solid #ececec}.errorPage_left_blue{text-align:center;font-size:2.5rem;color:#006281;margin-bottom:-2.5rem;letter-spacing:1px}.errorPage_left_orange{text-align:center;font-size:13rem;font-weight:800;color:#f8992d;margin-bottom:-2.5rem;letter-spacing:4px}.errorPage_left_image{margin-top:2rem}.errorPage_right{display:flex;flex-flow:column nowrap;flex:1 0 50%;justify-content:center;border-bottom:4px solid #ececec}\n", ""]);
// Exports
exports.locals = {
	"primaryColor": "#006281",
	"secondaryColor": "#f8992d"
};