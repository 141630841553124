import React from 'react';
import * as PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import BestPracticesInstructions from '../LandingPage/BestPracticesInstructions';
import AskSofieBar from '../../components/AskSofieBar/AskSofieBar';
import AnswerList from '../../components/AnswerList/AnswerList';
import LoadingPage from '../../components/LoadingPage/LoadingPage';

/**
 * Component for the ask page
 * @param {object} props - defined in proptypes
 * @returns {*} - DOM description
 */
const Ask = (props) => {
  const {
    answers,
    loading,
  } = props;

  const params = useParams();

  return (
    <Box
      sx={{
        flexGrow: '1',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <AskSofieBar location="top" />
      {loading ? (
        <Box
          sx={{
            flexGrow: 1,
          }}
        >
          <LoadingPage title="Loading Articles..." />
        </Box>
      ) : (
        <>
          {answers.length === 0 ? (
            <>
              {params.query && (
                <Box
                  mb={5}
                >
                  <Typography
                    sx={{
                      fontSize: '1.5rem',
                      fontWeight: 'bold'
                    }}
                  >
                    Sorry we were unable to find any results. Please ask a different question.
                  </Typography>
                </Box>
              )}
              <BestPracticesInstructions />
            </>
          ) : (
            <>
              <Box
                sx={{
                  width: '100%',
                  borderBottom: '2px solid',
                  borderColor: 'grey.main',
                }}
              />
              <AnswerList />
            </>
          )}
        </>
      )}
    </Box>
  );
};

/**
 * Maps items from the redux store to apps props.
 * @param {object} state - des
 * @returns {{user: *}} - maps user from redux to apps props
 */
function mapStateToProps(state) {
  return {
    user: state.user,
    answers: state.searchResults.current.answers,
    loading: state.searchResults.loading
  };
}

Ask.propTypes = {
  user: PropTypes.shape({
    accountID: PropTypes.number,
    tookSurvey: PropTypes.bool,
    role: PropTypes.number,
    expiresIn: PropTypes.number,
    expiryWarning: PropTypes.bool,
  }).isRequired,
  answers: PropTypes.arrayOf(PropTypes.shape({})),
  loading: PropTypes.bool.isRequired,
};

Ask.defaultProps = {
  answers: []
};

export default connect(mapStateToProps)(Ask);
