import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid, Box, Typography, Link
} from '@mui/material';
import '../../assets/svgs/logo-new.svg';
import Logo from '../../components/Logo/Logo';

const PublicArticleHeader = (props) => {
  const {
    title,
    book,
    year,
    publisherName,
    publisherWebsite,
  } = props;

  return (
    <Grid
      container
      sx={{
        pt: { xs: 2, lg: 4 },
        pl: { xs: 2, lg: 4 },
      }}
    >
      <Grid
        item
        xs={12}
        lg={2}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <Logo />
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        lg={8}
      >
        <Box>
          <Typography
            sx={{
              fontSize: { xs: '1.25rem', lg: '1.75rem' },
              fontWeight: 'bold',
            }}
          >
            {title}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography
            sx={{
              display: 'inline-block',
              color: 'grey.main',
              fontSize: { xs: '0.8rem', lg: '1rem' },
            }}
          >
            {`${book} - ${year}:`}
          </Typography>
          <Link
            href={publisherWebsite}
            color="secondary"
            underline="hover"
            target="_blank"
            rel="noopener noreferrer"
            sx={{
              display: 'inline-block',
              ml: 1,
              color: 'lightBlue.main',
              '&:hover, &:active': {
                color: 'lightBlue.main',
              },
              fontSize: { xs: '0.8rem', lg: '1rem' },
            }}
          >
            {publisherName}
          </Link>
        </Box>
      </Grid>
    </Grid>
  );
};

PublicArticleHeader.propTypes = {
  title: PropTypes.string,
  book: PropTypes.string,
  year: PropTypes.number,
  publisherName: PropTypes.string,
  publisherWebsite: PropTypes.string,
};

PublicArticleHeader.defaultProps = {
  title: '',
  book: '',
  year: '',
  publisherName: '',
  publisherWebsite: '',
};

export default PublicArticleHeader;
