import React from 'react';
import { Typography, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import BestPracticesContent from '../../components/BestPracticesContent/BestPracticesContent';

const BestPracticesContainer = styled(Paper, {
  shouldForwardProp: (prop) => prop !== 'middle',
})(({ theme }) => ({
  border: '1px solid',
  borderColor: theme.palette.grey.main,
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
  paddingTop: theme.spacing(5),
  paddingBottom: theme.spacing(5),
  marginLeft: theme.spacing(4),
  marginRight: theme.spacing(4),
  marginTop: theme.spacing(8),
  marginBottom: theme.spacing(8),
  borderRadius: 16,
  [theme.breakpoints.up('lg')]: {
    paddingLeft: theme.spacing(8.25),
    paddingRight: theme.spacing(8.25),
    marginLeft: 0,
    marginRight: 0,
    marginTop: theme.spacing(6.625),
  },
}));

const BestPracticesTitle = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'middle',
})(({ theme }) => ({
  color: theme.palette.darkGrey.main,
  fontSize: '1.5rem',
  fontWeight: 600,
  textAlign: 'center',
  paddingBottom: theme.spacing(1),
  [theme.breakpoints.up('lg')]: {
    fontSize: '2.1875rem',
    paddingLeft: theme.spacing(3),
  },
}));

const BestPracticesInstructions = () => (
  <BestPracticesContainer
    elevation={1}
  >
    <BestPracticesTitle>
      Sofie Best Search Practices
    </BestPracticesTitle>
    <BestPracticesContent />
  </BestPracticesContainer>
);
BestPracticesInstructions.propTypes = {};

export default BestPracticesInstructions;
