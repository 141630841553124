import React from 'react';
import { Box } from '@mui/material';
import Title from './Title';
import Body from './Body';

const PageTwo = () => (
  <>
    <Title
      color="lightBlue.main"
      size={1.25}
    >
      Updated Books
    </Title>
    <Title color="darkGrey.main">Small Animal Critical Care Medicine, 3rd edition</Title>
    <Body size={0.875}>Silverstein, Hopper; Elsevier 2023</Body>
    <Box mt={0.75} />
  </>
);

PageTwo.propTypes = {};

export default PageTwo;
