import React, { useEffect, memo } from 'react';

// eslint-disable-next-line react/display-name
const MyPureIframe = memo(() => {
  const token = window.localStorage.getItem('sofie_token').replace('Bearer ', '');
  let height = '100%';
  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    height = 'calc(100% - 79px)';
  }
  if (navigator.userAgent.match('CriOS') && window.screen.width < 450) {
    height = 'calc(100% - 110px)';
  }

  return (
    <iframe
      src={`${APP_URL}/vetcalculators-code/vetcalculator?token=${token}`}
      title="vetcalculators"
      id="vetcalculators"
      style={{
        height,
      }}
    />
  );
});

const Vetcalculators = () => {
  const eventListener = (event) => {
    if (event.origin !== APP_URL) {
      return;
    }
    const body = document.body;
    if (event.data === 'mousemove') {
      body.dispatchEvent(new Event('mousemove'));
      return;
    }

    if (event.data === 'click' || event.data === 'touch') {
      body.dispatchEvent(new Event('click'));
    }
  };

  useEffect(() => {
    window.addEventListener(
      'message',
      eventListener,
      false
    );

    return () => {
      window.removeEventListener('message', eventListener, false);
    };
  }, []);
  return (
    <>
      <MyPureIframe />
    </>
  );
};

Vetcalculators.propTypes = {
};

export default Vetcalculators;
