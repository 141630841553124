import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog, DialogTitle, DialogContent, Box, DialogActions, Button, Typography
} from '@mui/material';
import { grey } from '@mui/material/colors';

const FavoriteRemovalModal = (props) => {
  const {
    title, open, id, onConfirm, onCancel
  } = props;

  return (
    <Dialog
      open={open}
      onClose={() => {}}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="sm"
      PaperProps={{
        sx: {
          borderRadius: '16px',
        },
      }}
    >
      <DialogTitle
        id="alert-dialog-title"
        sx={{
          fontSize: '2rem',
          backgroundColor: grey[200],
          textAlign: 'center',
          py: 3,
          px: 2,
        }}
      >
        Are you sure you want to remove:
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            fontSize: '1.2rem',
            textAlign: 'center',
            pt: 5,
            px: 3,
            pb: 1,
            color: 'black.main',
          }}
          id="alert-dialog-description"
        >
          <Typography sx={{ fontStyle: 'italic' }}>{title}</Typography>
          <Typography sx={{ fontSize: '2rem' }}>From Favorites?</Typography>
          <Typography>All notes for this article will be lost if it is removed.</Typography>
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: 'center',
          pb: 5,
        }}
      >
        <Button
          onClick={onCancel}
          variant="outlined"
          color="black"
          type="button"
          sx={{ minWidth: '100px', padding: '6px 10px', borderRadius: '8px' }}
        >
          Cancel
        </Button>
        <Box px={1} />
        <Button
          onClick={() => {
            onConfirm(id, title);
          }}
          variant="contained"
          color="secondary"
          sx={{ minWidth: '100px', padding: '6px 10px', borderRadius: '8px' }}
        >
          Remove
        </Button>
      </DialogActions>
    </Dialog>
  );
};

FavoriteRemovalModal.propTypes = {
  title: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default FavoriteRemovalModal;
