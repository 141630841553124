import React, { Component } from 'react';
import * as Proptypes from 'prop-types';
import classNames from 'classnames';
import TextField from '@material-ui/core/TextField';
import Button from '../Button/Button';
import './Search.scss';

/**
 * Used to filter or search for items.
 * For Custom styling details please refer to comments above the proptypes.
 */
class Search extends Component {
  /**
   * Initializes component
   * @param {object} props - defined in proptypes
   */
  constructor(props) {
    super(props);
    this.state = {
      query: ''
    };
    this.queryUpdated = this.queryUpdated.bind(this);
    this.checkKey = this.checkKey.bind(this);
    this.search = this.search.bind(this);
  }

  /**
   * Tracks updates the user makes to their query.
   * @param {object} e - event containing updates to query
   */
  queryUpdated(e) {
    this.setState({
      query: e.target.value
    });
  }

  /**
   * Checks if the enter key has been pressed. If so triggers the search function.
   * @param {object} e - event
   */
  checkKey(e) {
    if (e.key === 'Enter') {
      this.search();
    }
  }

  /**
   * Takes care of triggering the search function.
   */
  search() {
    const { searchFunc } = this.props;
    const { query } = this.state;
    searchFunc(query);
  }

  /**
   * Renders component
   * @returns {*} - DOM description
   */
  render() {
    const {
      placeholder, customSearchClass, customClass, customButtonClass
    } = this.props;
    const { query } = this.state;

    const searchNames = classNames({
      customSearchClass,
      '': customClass.localeCompare('none') === 0,
      search: customClass.localeCompare('default') === 0
    });

    const searchButtonNames = classNames({
      customButtonClass,
    });

    return (
      <div className={searchNames}>
        <TextField
          id="searchBar"
          type="text"
          size="small"
          variant="outlined"
          color="primary"
          fullWidth
          placeholder={placeholder}
          className="search_bar"
          inputProps={{ className: 'search_bar_inner' }}
          value={query}
          onChange={this.queryUpdated}
          onKeyPress={this.checkKey}
        />
        <Button
          onClick={this.search}
          variant={searchButtonNames ? 'custom' : 'primary-inverse'}
          customVariant={searchButtonNames}
          customContent="Search"
        />
      </div>
    );
  }
}

export default Search;

/**
 * customSearchClass: Custom class for the div holding the search sub components.
 * customBarClass: Custom class for the search bar.
 * customButtonClass: Custom class for the search button
 * customCSSFile: file path to custom scss file. Use custom Bar, Search and Button classes with it.
 * customClass: A selection of Preset classes.
 */
Search.propTypes = {
  placeholder: Proptypes.string,
  customSearchClass: Proptypes.string,
  customButtonClass: Proptypes.string,
  customClass: Proptypes.oneOf(['', 'default']),
  searchFunc: Proptypes.func.isRequired
};

Search.defaultProps = {
  placeholder: '',
  customSearchClass: '',
  customButtonClass: '',
  customClass: 'default'
};
