import {
  FAVOURITE_ADD_REQUEST,
  FAVOURITE_REMOVE_REQUEST,
  FAVOURITE_REMOVE_SUCCESS,
  FAVOURITE_LIST_REQUEST,
  FAVOURITE_LIST_SUCCESS,
  FAVOURITE_LIST_FAILURE,
  NOTES_ADD_REQUEST,
  NOTES_ADD_SUCCESS,
  NOTES_ADD_FAILURE,
  RESET_REDUX,
  LOGOUT_COMPLETE,
  LOGOUT_INVALID_TOKEN,
  FAVOURITE_ADD_SUCCESS,
  FAVOURITE_OPEN_MODAL,
  FAVOURITE_CLOSE_MODAL,
  NOTES_EDIT,
} from '../../actions/actions';

const INIT_STATE = {
  currentList: {
    message: {
      answers: [],
    },
  },
  newFavoriteId: '',
  loading: false,
  loaded: false,
  modal: {
    open: false,
    title: '',
    id: ''
  },
  note: {
    edit: false,
  }
};

const favouriteLists = (state = INIT_STATE, action) => {
  switch (action.type) {
    case FAVOURITE_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FAVOURITE_LIST_FAILURE:
      return {
        ...state,
      };
    case FAVOURITE_LIST_SUCCESS:
      return {
        ...state,
        currentList: action.payload,
        loading: false,
      };
    case FAVOURITE_REMOVE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FAVOURITE_REMOVE_SUCCESS:
      return {
        ...state,
        currentList: action.payload.favoriteData,
        loading: false,
        modal: {
          open: false,
          title: '',
          id: '',
        },
      };

    case FAVOURITE_ADD_REQUEST:
      return {
        ...state,
      };

    case FAVOURITE_ADD_SUCCESS:
      return {
        ...state,
        newFavoriteId: action.payload.favoriteId,
      };

    case NOTES_EDIT: {
      return {
        ...state,
        note: {
          edit: action.payload.edit,
        },
      };
    }

    case NOTES_ADD_REQUEST:
      return {
        ...state,
      };

    case NOTES_ADD_SUCCESS: {
      const note = {
        edit: false,
      };
      return {
        ...state,
        loading: false,
        note,
      };
    }

    case NOTES_ADD_FAILURE:
      return {
        ...state,
      };

    case FAVOURITE_OPEN_MODAL: {
      const obj = {
        open: true,
        title: action.payload.title,
        id: action.payload.id,
      };

      return {
        ...state,
        modal: obj,
      };
    }

    case FAVOURITE_CLOSE_MODAL: {
      const modal = state.modal;
      const obj = {
        ...modal,
        open: false,
      };
      return {
        ...state,
        modal: obj,
      };
    }

    case LOGOUT_INVALID_TOKEN:
    case LOGOUT_COMPLETE:
    case RESET_REDUX:
      return INIT_STATE;

    default:
      return state;
  }
};

export default favouriteLists;
