/**
 * Validates form data.
 * @type {{formValidation(*): *}} - validator
 */
const Validation = {
  formValidation(data) {
    let error;
    const message = {};
    data.forEach((element) => {
      const validationType = element.validationType.split('|')
        .map((item) => item.trim());

      if (validationType.includes('required')) {
        if (element.value.trim().length < 1) {
          message[element.name] = `Please enter a value for ${element.name}.`;
        }
      }

      if (validationType.includes('alpha')) {
        if (/[^a-z .A-Z]/.test(element.value.toLowerCase())) {
          if (!(element.name in message)) {
            message[element.name] = `Only Letters are allowed for ${element.name}.`;
          }
        }
      }

      if (validationType.includes('email')) {
        const expression = /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
        if (element.value != '' && !expression.test(element.value.toLowerCase())) {
          if (!(element.name in message)) {
            message[element.name] = `A valid email is required for ${element.name}.`;
          }
        }
      }

      if (validationType.includes('confirm')) {
        if (element.value !== element.confirm) {
          if (!(element.name in message)) {
            message[element.name] = 'Passwords do not match!';
          }
        }
      }

      if (validationType.includes('newPassword')) {
        if (element.value.length < 8) {
          if (!(element.name in message)) {
            message[element.name] = 'Password needs to have 8 or more characters.';
          }
        }
      }
    });

    if (message) {
      error = message;
    } else {
      error = false;
    }

    return error;
  }
};

export default Validation;
