import React, { useEffect, useState } from 'react';
import Proptypes from 'prop-types';
import { useIdleTimer } from 'react-idle-timer';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TimeoutModal from '../Modals/TimeoutModal/TimeoutModal';
import useCancelToken from '../../hooks/useCancelToken';
import { attemptLogin } from '../../actions/authentication/authentication';

/**
 * Handles tracking and updating user activity.
 * If the user is active it lets the backend know.
 * If user is inactive it checks with the backend to see if user is active in another window.
 * If not heartbeat will ask the user if they are active with a countdown.
 * If the user fails to say they are still active they are logged out.
 * @param {object} props - Defined in proptypes
 * @returns {JSX.Element} - DOM description
 */
const Heartbeat = (props) => {
  const { login, user } = props;
  const [timeOutOpen, setTimeOutOpen] = useState(false);
  const { cancelToken } = useCancelToken();
  let idleTimer;

  const checkExpiry = (timer) => {
    if (timer && timer.isIdle()) {
      const expiry = user.tokenExpiry;
      const now = Math.floor(new Date().getTime() / 1000);

      if (!timeOutOpen && expiry - now <= MODAL_TIMER) {
        setTimeOutOpen(true);
        timer.pause();
      } else if (timeOutOpen && expiry - now > MODAL_TIMER) {
        setTimeOutOpen(false);
        timer.reset();
      }
    }
  };

  /**
   * Cancels active axios calls if component is unmounted.
   */
  useEffect(
    () => () => {
      cancelToken.cancel();
    },
    []
  );

  useEffect(() => {
    const checkExpiryInterval = setInterval(() => {
      checkExpiry(idleTimer);
    }, 1000 * 10);

    /**
     * Cancels active axios calls if component is unmounted.
     */
    return () => {
      clearInterval(checkExpiryInterval);
    };
  }, [timeOutOpen, idleTimer, user]);

  const onAction = () => {
    const expiry = user.tokenExpiry;
    if (!timeOutOpen) {
      const now = Math.floor(new Date().getTime() / 1000);
      if (expiry - now <= MODAL_TIMER) {
        login(user.token, user.azureUUID, cancelToken);
      }
    }
  };

  idleTimer = useIdleTimer({
    onAction,
    timeout: 1000 * IDLE_TIME,
    throttle: 1000 * ACTIVITY_THROTTLE,
  });

  return (
    <>
      {timeOutOpen && (
        <TimeoutModal
          open={timeOutOpen}
          onClose={() => {
            setTimeOutOpen(false);
            login(user.token, user.azureUUID, cancelToken);
            idleTimer.reset();
          }}
          end={user.tokenExpiry}
        />
      )}
    </>
  );
};

/**
 * Maps items from the redux store to apps props.
 * @param {object} state - des
 * @returns {{user: *}} - maps user from redux to apps props
 */
function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

/**
 * Maps actions to component props.
 * @param {Dispatch} dispatch - allows action creators to work with redux.
 * @returns {{logout: *, authToken: *, login: *}} - bound action creators
 */
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      login: attemptLogin,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Heartbeat);

Heartbeat.propTypes = {
  login: Proptypes.func.isRequired,
  user: Proptypes.shape({
    tokenExpiry: Proptypes.number,
    token: Proptypes.string,
    azureUUID: Proptypes.string,
  }).isRequired,
};
