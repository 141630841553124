import React from 'react';
import * as Proptypes from 'prop-types';

/**
 * Generates the structure of the title and body for toast notifications.
 * @param {object} props - defined in proptypes
 * @returns {*} - Notification message structure.
 * @class
 */
const Notification = (props) => {
  const { body, title } = props;

  return (
    <div>
      <p>{title}</p>
      { body }
    </div>
  );
};

export default Notification;

Notification.propTypes = {
  body: Proptypes.oneOfType([
    Proptypes.string,
    Proptypes.arrayOf(Proptypes.string)
  ]).isRequired,
  title: Proptypes.string.isRequired
};
