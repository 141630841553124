import React from 'react';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const TextContainer = styled(Typography)(({ theme }) => ({
  color: theme.palette.lightBlue.main,
  fontSize: '1rem',
  fontWeight: 600,
  [theme.breakpoints.up('lg')]: {
    fontSize: '1.25rem',
  },
}));

const GreyText = styled('span')(({ theme }) => ({
  color: theme.palette.grey.main,
  marginLeft: theme.spacing(1),
  [theme.breakpoints.up('lg')]: {},
}));

const LineContainer = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  textAlign: 'center',
  [theme.breakpoints.up('lg')]: {

  },
}));

const BestPracticesContent = () => (
  <>
    <LineContainer>
      <TextContainer>
        Use 2-4 search terms to provide context
        <GreyText>e.g. &ldquo;treatment diabetes insipidus dog&rdquo;</GreyText>
      </TextContainer>
    </LineContainer>
    <LineContainer>
      <TextContainer>
        Pose questions like you would ask a colleague
        <GreyText>e.g. &ldquo;What is the treatment for?&rdquo;</GreyText>
      </TextContainer>
    </LineContainer>
    <LineContainer>
      <TextContainer>
        Check your spelling
        <GreyText>Right click on red underlined words for suggestions</GreyText>
      </TextContainer>
    </LineContainer>
  </>
);

BestPracticesContent.propTypes = {};

export default BestPracticesContent;
