import ReactGA from 'react-ga';
import ReactGA4 from 'react-ga4';
import React from 'react';
import { toast } from 'react-toastify';
import {
  FAVOURITE_ADD_REQUEST,
  FAVOURITE_ADD_SUCCESS,
  FAVOURITE_ADD_FAILURE,
  FAVOURITE_REMOVE_REQUEST,
  FAVOURITE_REMOVE_SUCCESS,
  FAVOURITE_REMOVE_FAILURE,
  FAVOURITE_LIST_REQUEST,
  FAVOURITE_LIST_SUCCESS,
  FAVOURITE_LIST_FAILURE,
  NOTES_ADD_REQUEST,
  NOTES_ADD_SUCCESS,
  NOTES_ADD_FAILURE,
  FAVOURITE_OPEN_MODAL,
  FAVOURITE_CLOSE_MODAL,
  NOTES_EDIT,
} from '../actions';
import AuthRequest from '../../api/AuthRequest';
import Notification from '../../components/Notification/Notification';

// Add Favourite List
/**
 * Action creator for sending add to favourite request
 * @returns {object} - action
 */
function favouriteAddRequest() {
  return {
    type: FAVOURITE_ADD_REQUEST,
  };
}

/**
 * Action creator for user accepting add to favourite
 * @returns {object} - action
 * @param {Array} favorites - list of favorite article ids
 * @param {string} favoriteId - id of favorite article
 */
function favouriteAddSuccess(favorites, favoriteId) {
  return {
    type: FAVOURITE_ADD_SUCCESS,
    payload: {
      favorites,
      favoriteId,
    },
  };
}

/**
 * Action creator for failing to add to favourite
 * @param {object} id - article id
 * @returns {object} - action
 */
function favouriteAddFailure(id) {
  return {
    type: FAVOURITE_ADD_FAILURE,
    payload: { id }
  };
}

// Remove Favourite List
/**
 * Action creator for sending remove favourite request
 * @returns {object} - action
 */
function favouriteRemoveRequest() {
  return {
    type: FAVOURITE_REMOVE_REQUEST,
  };
}

/**
 * Action creator for user accepting add remove favourite
 * @returns {object} - action
 * @param {object} favoriteData - favorites with their data
 * @param {Array} favorites - list of favorite article id's
 */
function favouriteRemoveSuccess(favoriteData, favorites) {
  return {
    type: FAVOURITE_REMOVE_SUCCESS,
    payload: {
      favoriteData,
      favorites,
    },
  };
}

/**
 * Action creator for user getting favourite list
 * @returns {object} - action
 */
function favouriteRemoveFailure() {
  return {
    type: FAVOURITE_REMOVE_FAILURE,
  };
}

// Get Favourite List
/**
 * Action creator for user getting favourite list
 * @returns {object} - action
 */
function favouriteListRequest() {
  return {
    type: FAVOURITE_LIST_REQUEST,
  };
}

/**
 * Action creator for user getting favourite list success
 * @param {object} favouriteList - favourite list
 * @returns {object} - action
 */
function favouriteListSuccess(favouriteList) {
  return {
    type: FAVOURITE_LIST_SUCCESS,
    payload: favouriteList,
  };
}

/**
 * Action creator for failing to get favourite list
 * @returns {object} - action
 */
function favouriteListFailure() {
  return {
    type: FAVOURITE_LIST_FAILURE,
  };
}

/**
 * Request for adding a note
 * @returns {object} - action
 */
function notesAddRequest() {
  return {
    type: NOTES_ADD_REQUEST,
  };
}

/**
 * Success for adding note
 * @param {string} favouriteId - id of the favorite to add the note too
 * @param {string} notes - note content
 * @returns {object} - action
 */
function notesAddSuccess(favouriteId, notes) {
  return {
    type: NOTES_ADD_SUCCESS,
    payload: {
      favouriteId,
      notes
    }
  };
}

/**
 * failure for adding notes
 * @param {object} error - error message
 * @returns {object} - action
 */
function notesAddFailure(error) {
  return {
    type: NOTES_ADD_FAILURE,
    payload: error
  };
}

/**
 * Request for adding a note
 * @returns {object} - action
 * @param {boolean} edit - whether to open or close edit
 */
export function noteEdit(edit) {
  return {
    type: NOTES_EDIT,
    payload: { edit }
  };
}

/**
 * open favorite removal confirmation modal
 * @param {string} title - title of the article to be removed
 * @param {string} id - id of the article to be removed
 * @returns {object} - action
 */
export function openFavoriteConfirmationModal(id, title) {
  return {
    type: FAVOURITE_OPEN_MODAL,
    payload: { title, id }
  };
}

/**
 * close favorite removal confirmation modal
 * @param {string} title - error message
 * @returns {object} - action
 */
export function closeFavoriteConfirmationModal() {
  return {
    type: FAVOURITE_CLOSE_MODAL,
  };
}

// ===== MIDDLEWARE =====

/**
 * API request for adding a favorite
 * @param {string} id - id of the article
 * @returns {Function} - updates redux store
 */
export const addtoFavourite = (id) => (dispatch, getState) => {
  dispatch(favouriteAddRequest());
  AuthRequest({
    method: 'post',
    url: '/favourites',
    data: {
      article_id: id,
    }
  }).then((res) => {
    ReactGA.event({
      category: 'Favourites',
      action: 'add-to-fav'
    });
    ReactGA4.event('add-to-fav');
    const favorites = [...getState().user.favorites, id];
    dispatch(favouriteAddSuccess(favorites, res.data.message.favourite_id.toString()));
  }).catch((error) => {
    dispatch(favouriteAddFailure(error.message));
  });
};

/**
 * API call for getting all the favorites for a user
 * @returns {Function} - updates redux store
 */
export const getFavouriteList = () => (dispatch) => {
  dispatch(favouriteListRequest());
  AuthRequest({
    method: 'get',
    url: '/favourites',
  }).then((res) => {
    if (res.data.message) {
      dispatch(favouriteListSuccess(res.data));
    } else {
      const current = {
        message: {
          answers: []
        }
      };
      dispatch(favouriteListSuccess(current));
    }
  }).catch((error) => {
    dispatch(favouriteListFailure(error.message));
  });
};

/**
 * API call for removing favorites
 * @param {string} id - id of the favorite to be removed
 * @returns {Function} - updates redux store
 */
export const removeFavourite = (id) => (dispatch, getState) => {
  dispatch(favouriteRemoveRequest());
  AuthRequest({
    method: 'delete',
    url: '/favourites',
    data: {
      article_id: id,
    },
  })
    .then(() => {
      const favorites = getState().user.favorites;
      const favoriteData = getState().favouriteLists.currentList.message.answers;

      const newFavorites = favorites.filter((favorite) => favorite !== id);
      let newFavoriteData = [];

      if (favoriteData.length > 0) {
        newFavoriteData = favoriteData.filter(
          (favourite) => favourite.doc_id !== id
        );
      }
      dispatch(favouriteRemoveSuccess({ message: { answers: newFavoriteData } }, newFavorites));
    })
    .catch((error) => {
      dispatch(favouriteRemoveFailure(error.message));
    });
};

/**
 * API call for adding notes
 * @param {string} favouriteId - id of the favorite to add the note
 * @param {string} notes - content of the note
 * @returns {Function} - updates redux store
 */
export function addNotes(favouriteId, notes) {
  return (dispatch) => {
    dispatch(notesAddRequest());
    AuthRequest({
      method: 'post',
      url: 'note',
      data: {
        favourite_id: favouriteId,
        body: notes
      }
    }).then((res) => {
      ReactGA.event({
        category: 'Favourites',
        action: 'add-notes'
      });
      ReactGA4.event('add-notes');
      toast.success(
        <Notification
          title="Note Saved!"
          body=""
        />
      );
      dispatch(notesAddSuccess(favouriteId, res.data));
    }).catch((error) => {
      dispatch(notesAddFailure(error.message));
    });
  };
}
