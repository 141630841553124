const ThemeConfig = {
  palette: {
    primary: {
      main: '#006281',
    },
    secondary: {
      main: '#f8992d',
      contrastText: '#ffffff'
    },
    tertiary: {
      main: '#ea8008'
    },
    yellow: {
      main: '#f7b330'
    },
    lightBlue: {
      main: '#008CC2'
    },
    darkGrey: {
      main: '#7E7E7E'
    },
    grey: {
      main: '#c3c3c3',
      light: '#f2f3f4'
    },
    white: {
      main: '#ffffff'
    },
    black: {
      main: '#000000',
      contrastText: '#ffffff'
    },
    testColor: {
      main: '#000000',
      contrastText: '#ffffff'
    },
    text: {
      primary: '#00111E'
    }
  },
  typography: {
    fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
    button: {
      textTransform: 'none',
    },
  }
};

export default ThemeConfig;
