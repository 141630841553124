import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Box, Typography, Link, Button, Grid
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import {
  closeFavoriteConfirmationModal,
  removeFavourite,
} from '../../actions/addFavourite/addFavourite';
import SearchBox from './SearchBox';
import ArticleButtons from '../../components/ArticleButtons/ArticleButtons';
import Notes from '../../components/Notes/Notes';
import FavoriteRemovalModal from '../../components/Modals/FavoriteRemovalModal/FavoriteRemovalModal';

const ArticleHeader = (props) => {
  const {
    title,
    openShareArticles,
    id,
    book,
    year,
    publisherName,
    publisherWebsite,
    articleSearch,
    setArticleSearch,
    articleSearchIndex,
    setArticleSearchIndex,
    articleSearchListLength,
    setArticleSearchListLength,
    favoriteId,
    notes,
    isFavorite,
    setAddedFav,
    modalOpen,
    modalTitle,
    modalId,
    dispatchRemoveFavourite,
    dispatchCloseFavoriteConfirmationModal,
  } = props;

  const history = useHistory();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

  const handleGoBack = () => {
    history.goBack();
  };

  return (
    <Grid
      container
      columnSpacing={isMobile ? 0 : 4}
    >
      <Grid
        item
        xs={12}
        lg={1}
      >
        {!isMobile && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              variant="contained"
              color="white"
              sx={{
                padding: '8px',
                minWidth: '0px',
                borderRadius: '8px',
                fontSize: '1.2rem',
                displayPrint: 'none',
              }}
              onClick={handleGoBack}
            >
              <NavigateBeforeIcon />
            </Button>
          </Box>
        )}
      </Grid>
      <Grid
        item
        xs={12}
        lg={7}
      >
        <Box>
          <Typography
            sx={{
              fontSize: '1.75rem',
              fontWeight: 'bold',
              overflowWrap: 'anywhere',
              hyphens: 'auto',
            }}
          >
            {title}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: isMobile ? 'column-reverse' : 'row',
            alignItems: isMobile ? 'flex-start' : 'center',
          }}
        >
          {isMobile && isFavorite && (
            <Box
              sx={{ width: '100%' }}
              pt={{ xs: 3, lg: 0 }}
            >
              <Notes
                id={favoriteId}
                notes={notes}
                width={isMobile ? '100%' : 'auto'}
              />
            </Box>
          )}
          <Box ml={isMobile ? -1 : 0}>
            <ArticleButtons
              id={id}
              handleShareArticleClick={openShareArticles}
              setAddedFav={setAddedFav}
              size={1.75}
              popupRequired
              title={title}
            />
          </Box>
          <Typography
            sx={{
              display: 'inline-block',
              color: 'grey.main',
            }}
          >
            {`${book} - ${year}: `}
            {isMobile && <br />}
            <Link
              href={publisherWebsite}
              underline="hover"
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                mr: isMobile ? 0 : 1,
                color: 'lightBlue.main',
                '&:hover': {
                  color: 'lightBlue.main',
                },
              }}
            >
              {publisherName}
            </Link>
          </Typography>
        </Box>
      </Grid>
      {!isMobile && (
        <Grid
          item
          xs={12}
          lg={4}
        >
          <SearchBox
            articleSearch={articleSearch}
            setArticleSearch={setArticleSearch}
            setArticleSearchIndex={setArticleSearchIndex}
            articleSearchIndex={articleSearchIndex}
            articleSearchListLength={articleSearchListLength}
            setArticleSearchListLength={setArticleSearchListLength}
          />
        </Grid>
      )}
      <FavoriteRemovalModal
        open={modalOpen}
        title={modalTitle}
        id={modalId}
        onCancel={dispatchCloseFavoriteConfirmationModal}
        onConfirm={dispatchRemoveFavourite}
      />
    </Grid>
  );
};

/**
 * Maps items from the redux store to apps props.
 * @param {object} state - des
 * @returns {{ user: *}} - maps user from redux to apps props
 */
function mapStateToProps(state) {
  return {
    modalOpen: state.favouriteLists.modal.open,
    modalTitle: state.favouriteLists.modal.title,
    modalId: state.favouriteLists.modal.id,
  };
}

/**
 * Maps actions to component props.
 * @param {Dispatch} dispatch - allows action creators to work with redux.
 * @returns {{askQuestion: *, check: *, uncheck: *}} - bound action creators
 */
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      dispatchCloseFavoriteConfirmationModal: closeFavoriteConfirmationModal,
      dispatchRemoveFavourite: removeFavourite,
    },
    dispatch
  );
}

ArticleHeader.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  book: PropTypes.string.isRequired,
  notes: PropTypes.string.isRequired,
  isFavorite: PropTypes.bool.isRequired,
  favoriteId: PropTypes.string.isRequired,
  year: PropTypes.number.isRequired,
  publisherName: PropTypes.string.isRequired,
  publisherWebsite: PropTypes.string.isRequired,
  openShareArticles: PropTypes.func.isRequired,
  articleSearch: PropTypes.string.isRequired,
  setArticleSearch: PropTypes.func.isRequired,
  articleSearchIndex: PropTypes.number,
  articleSearchListLength: PropTypes.number,
  setArticleSearchIndex: PropTypes.func.isRequired,
  setArticleSearchListLength: PropTypes.func.isRequired,
  setAddedFav: PropTypes.func.isRequired,
  modalOpen: PropTypes.bool.isRequired,
  modalId: PropTypes.string.isRequired,
  modalTitle: PropTypes.string.isRequired,
  dispatchRemoveFavourite: PropTypes.func.isRequired,
  dispatchCloseFavoriteConfirmationModal: PropTypes.func.isRequired,
};

ArticleHeader.defaultProps = {
  articleSearchIndex: undefined,
  articleSearchListLength: undefined,
};

export default connect(mapStateToProps, mapDispatchToProps)(ArticleHeader);
