import {
  Typography, Box, Grid, Pagination, PaginationItem
} from '@mui/material';
import { useLocation, Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { grey } from '@mui/material/colors';
import AuthRequest from '../../api/AuthRequest';
import LoadingPage from '../../components/LoadingPage/LoadingPage';
import BookCard from './BookCard';
import PublisherCard from './PublisherCard';

const Publisher = () => {
  const [state, setState] = useState({
    loading: true,
    books: [],
    publishers: [],
    proceedings: []
  });

  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const page = parseInt(query.get('page') || '1', 10);

  useEffect(() => {
    AuthRequest({
      method: 'get',
      url: 'publisher-page',
    })
      .then((res) => {
        const resultBooks = res.data.message.books.filter((book) => book.book.type_id === 1);
        const resultProceedings = res.data.message.books.filter((book) => book.book.type_id === 2);

        setState({
          loading: false,
          books: resultBooks,
          publishers: res.data.message.publishers,
          proceedings: resultProceedings,
        });
      })
      .catch(() => {
        setState({
          loading: true,
          books: [],
          publishers: [],
          proceedings: [],
        });
      });
  }, []);

  if (state.loading) {
    return (
      <LoadingPage title="Loading Books and Publishers..." />
    );
  }

  let numberPages = 0;
  let thisPage = '';
  if (state.books.length > 0) {
    numberPages = Math.ceil(state.books.length / 6);

    const pageAdjustment = page * 6;
    thisPage = state.books.slice(pageAdjustment - 6, pageAdjustment);
  }

  const subtitleStyles = {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    color: grey[600],
    pt: 2,
    pb: 3,
  };

  return (
    <Box
      sx={{
        px: { xs: 4, lg: 15 },
      }}
    >
      <Typography
        sx={{
          fontSize: '2rem',
          fontWeight: 'bold',
          pb: 3,
        }}
      >
        Publications & Resources
      </Typography>
      <Box
        sx={{
          pb: 10,
        }}
      >
        <Typography sx={subtitleStyles}>Textbooks</Typography>
        <Grid
          container
          spacing={4}
        >
          {thisPage.map((book) => {
            const authors = book.authors
              .map((author) => `${author.last_name} ${author.first_name}`)
              .join(', ');
            const title = `${book.book.name}: ${book.name}`;
            const subtitle = `(${authors}; ${book.book.publisher.name} ${book.year})`;

            return (
              <Grid
                key={book.id}
                item
                xs={6}
                lg={4}
              >
                <BookCard
                  title={title}
                  subtitle={subtitle}
                  image={book.image}
                />
              </Grid>
            );
          })}
        </Grid>
        <Box
          pt={4}
        >
          <Pagination
            count={numberPages}
            shape="rounded"
            color="testColor"
            page={page}
            renderItem={(item) => (
              <PaginationItem
                component={Link}
                to={`/publisher${item.page === 1 ? '' : `?page=${item.page}`}`}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...item}
              />
            )}
          />
        </Box>
      </Box>
      <Box sx={{ borderBottom: '1px solid', borderColor: grey[500] }} />
      <Box
        sx={{
          pb: 10,
        }}
      >
        <Typography sx={subtitleStyles}>Conference Proceedings and Journals</Typography>
        <Grid
          container
          spacing={4}
        >
          {state.proceedings.map((book) => {
            const title = `${book.book.name} (${book.year})`;
            return (
              <Grid
                key={book.id}
                item
                xs={6}
                lg={4}
              >
                <BookCard
                  title={title}
                  image={book.image}
                />
              </Grid>
            );
          })}
        </Grid>
      </Box>
      <Box sx={{ borderBottom: '1px solid', borderColor: grey[500] }} />
      <Box
        sx={{
          pb: 20,
        }}
      >
        <Typography sx={subtitleStyles}>Publishing Partners</Typography>
        <Grid
          container
          spacing={{ xs: 6, lg: 3 }}
          sx={{
            alignItems: 'center',
          }}
        >
          {state.publishers.map((publisher) => (
            <Grid
              item
              xs={6}
              lg
              key={publisher.id}
            >
              <PublisherCard
                website={publisher.website}
                image={publisher.logo}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

Publisher.propTypes = {};

export default Publisher;
