import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BestPracticesInstructions from './BestPracticesInstructions';
import AskSofieBar from '../../components/AskSofieBar/AskSofieBar';
import AlertModal from '../../components/Modals/AlertModal/AlertModal';
import { completedSurvey, sawExpiryWarning } from '../../actions/userUpdates/updateUser';
import LoadingPage from '../../components/LoadingPage/LoadingPage';

/**
 * Component for the ask page
 * @param {object} props - defined in proptypes
 * @returns {*} - DOM description
 */
const LandingPage = (props) => {
  const {
    user, dispatchCompletedSurvey, dispatchSawExpiryWarning, loading
  } = props;

  const history = useHistory();

  const [showWarningModal, setShowWarningModal] = useState(false);

  useEffect(() => {
    // for purina users check if the completed survey sent them here if so mark it as done.
    if (history.location.search.localeCompare('?done=yes') === 0 && user.accountID === 4) {
      if (!user.tookSurvey) {
        dispatchCompletedSurvey();
      }
    }

    // check if users license is expiring in the next 4 days.
    const expiring = user.expiresIn < 1000 * 60 * 60 * 24 * 5;
    if (!user.expiryWarning && (user.role === 8 || user.role === 9) && expiring) {
      setShowWarningModal(true);
    }
  }, []);

  /**
   * Hides the warning modal.
   */
  const closeModal = () => {
    dispatchSawExpiryWarning();
    setShowWarningModal(false);
  };

  const timeLeft = {
    days: Math.floor(user.expiresIn / (1000 * 60 * 60 * 24)),
    hours: Math.floor((user.expiresIn / (1000 * 60 * 60)) % 24),
    minutes: Math.floor((user.expiresIn / 1000 / 60) % 60),
    seconds: Math.floor((user.expiresIn / 1000) % 60),
  };

  let timeRemaining;
  if (timeLeft.days) {
    timeRemaining = `${timeLeft.days} day${timeLeft.days > 1 ? 's' : ''}`;
  } else {
    timeRemaining = `${timeLeft.hours} hour${timeLeft.hours > 1 ? 's' : ''} and
  ${timeLeft.minutes} minute${timeLeft.minutes > 1 ? 's' : ''}`;
  }

  const message = `Your license will expire in ${timeRemaining}. To renew or buy a license click `;

  return (
    <Box
      sx={{
        flexGrow: '1',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        mt: 10,
      }}
    >
      <AskSofieBar location="middle" />
      {loading && (
        <Box
          sx={{
            flexGrow: 1,
          }}
        >
          <LoadingPage title="Loading Articles..." />
        </Box>
      )}
      <BestPracticesInstructions />
      <AlertModal
        open={showWarningModal}
        title="Your License is Expiring Soon!"
        handleClose={closeModal}
        buttonText="Close"
      >
        <>
          {message}
          <a
            rel="noopener noreferrer"
            target="_blank"
            href="https://store-can.lifelearn.com/index.php"
          >
            here
          </a>
          .
        </>
      </AlertModal>
    </Box>
  );
};

/**
 * Maps items from the redux store to apps props.
 * @param {object} state - des
 * @returns {{user: *}} - maps user from redux to apps props
 */
function mapStateToProps(state) {
  return {
    user: state.user,
    answers: state.searchResults.current.answers,
    loading: state.searchResults.loading,
  };
}

/**
 * Maps actions to component props.
 * @param {Dispatch} dispatch - allows action creators to work with redux.
 * @returns {{askQuestion: *, check: *, uncheck: *}} - bound action creators
 */
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      dispatchCompletedSurvey: completedSurvey,
      dispatchSawExpiryWarning: sawExpiryWarning,
    },
    dispatch
  );
}

LandingPage.propTypes = {
  user: PropTypes.shape({
    accountID: PropTypes.number,
    tookSurvey: PropTypes.bool,
    role: PropTypes.number,
    expiresIn: PropTypes.number,
    expiryWarning: PropTypes.bool,
  }).isRequired,
  dispatchSawExpiryWarning: PropTypes.func.isRequired,
  dispatchCompletedSurvey: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(LandingPage);
