import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, TextField } from '@mui/material';
import { grey } from '@mui/material/colors';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { addNotes, noteEdit } from '../../actions/addFavourite/addFavourite';

const Notes = (props) => {
  const {
    id, dispatchAddNotes, notes, width, dispatchNoteEdit, edit
  } = props;
  const [newNote, setNewNote] = useState(notes);
  const [noteChanged, setNoteChanged] = useState(false);

  if (!newNote && !edit) {
    return (
      <Box
        sx={{
          backgroundColor: grey[50],
          borderLeft: '3px solid',
          borderColor: grey[600],
          mr: 9,
          width,
          displayPrint: 'none'
        }}
      >
        <Button
          sx={{
            width: '100%',
            justifyContent: 'flex-start',
            textTransform: 'none',
            color: grey[500],
            fontSize: '1rem',
            fontStyle: 'italic',
            fontWeight: '400',
            paddingLeft: '16px',
          }}
          onClick={() => {
            dispatchNoteEdit(!edit);
          }}
        >
          Add a note...
        </Button>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        backgroundColor: grey[50],
        borderLeft: '3px solid',
        borderColor: grey[600],
        mr: 9,
        width,
        displayPrint: 'none',
      }}
    >
      <Box
        sx={{
          px: 2,
          pt: 2,
        }}
      >
        <TextField
          multiline
          rows={4}
          value={newNote || ''}
          onChange={(e) => {
            setNewNote(e.target.value);
            setNoteChanged(true);
          }}
          sx={{
            '& legend': {
              width: 'auto',
            },
          }}
          fullWidth
          placeholder="Type Note..."
          disabled={!edit}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          py: 2,
        }}
      >
        {edit && (
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              dispatchNoteEdit(!edit);
              if (noteChanged) {
                setNewNote(notes);
              }
            }}
            sx={{
              minWidth: '90px',
            }}
          >
            Cancel
          </Button>
        )}
        <Box px={1} />
        <Button
          variant="contained"
          color="secondary"
          sx={{
            minWidth: '90px',
          }}
          onClick={() => {
            if (edit) {
              setNoteChanged(false);
              dispatchAddNotes(id, newNote);
            } else {
              dispatchNoteEdit(!edit);
            }
          }}
        >
          {edit ? 'Save' : 'Edit'}
        </Button>
      </Box>
    </Box>
  );
};

/**
 * Maps actions to component props.
 * @param {Dispatch} dispatch - allows action creators to work with redux.
 * @returns {{askQuestion: *, check: *, uncheck: *}} - bound action creators
 */
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      dispatchAddNotes: addNotes,
      dispatchNoteEdit: noteEdit,
    },
    dispatch
  );
}

/**
 * Maps items from the redux store to apps props.
 * @param {object} state - des
 * @returns {{ user: *}} - maps user from redux to apps props
 */
function mapStateToProps(state) {
  return {
    edit: state.favouriteLists.note.edit,
  };
}

Notes.propTypes = {
  id: PropTypes.string.isRequired,
  dispatchAddNotes: PropTypes.func.isRequired,
  dispatchNoteEdit: PropTypes.func.isRequired,
  notes: PropTypes.string.isRequired,
  edit: PropTypes.bool.isRequired,
  width: PropTypes.string,
};

Notes.defaultProps = {
  width: 'auto'
};

export default connect(mapStateToProps, mapDispatchToProps)(Notes);
