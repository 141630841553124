import React from 'react';
import PropTypes from 'prop-types';
import { useLocation, Link as RouterLink } from 'react-router-dom';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import ReactGA4 from 'react-ga4';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import ChatBubbleOutlineRoundedIcon from '@mui/icons-material/ChatBubbleOutlineRounded';
import CalculateIcon from '@mui/icons-material/Calculate';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import { Paper } from '@mui/material';

const BottomNavBar = (props) => {
  const { currentCategory, currentQuestion } = props;

  const location = useLocation();

  const navigation = [
    {
      name: 'Ask Sofie',
      url:
        currentQuestion && currentCategory ? `/ask/${currentCategory}/${currentQuestion}` : '/ask',
      icon: <ChatBubbleOutlineRoundedIcon sx={{ fontSize: '2rem' }} />,
      active: ['/ask', '/view-articles'],
    },
    {
      name: 'Vetcalculators',
      url: '/vetcalculators',
      icon: <CalculateIcon sx={{ fontSize: '2rem' }} />,
      active: ['/vetcalculators'],
    },
    {
      name: 'Favorites',
      url: '/favorites',
      icon: <StarOutlineIcon sx={{ fontSize: '2rem' }} />,
      active: ['/favorites', 'favorite-articles'],
    },
    {
      name: 'Client Education',
      external: true,
      url: CLIENTED_URL,
      icon: <AutoStoriesIcon sx={{ fontSize: '2rem' }} />,
      active: ['/client-education'],
    },
  ];

  if (APP_ENV !== 'production' && APP_ENV !== 'staging') {
    navigation.splice(1, 0, {
      name: 'Differential Diagnosis',
      url: '/ddx',
      icon: <CompareArrowsIcon sx={{ fontSize: '2rem' }} />,
      active: ['/ddx'],
    });
  }
  return (
    <Paper
      sx={{
        position: 'sticky',
        bottom: 0,
        left: 0,
        right: 0,
        py: 3,
        background: 'linear-gradient(180deg, rgba(0,34,60,1) 50%, rgba(0,1,1,1) 100%)',
        borderRadius: '0px',
        displayPrint: 'none'
      }}
      elevation={3}
    >
      <BottomNavigation
        showLabels
        sx={{
          background: 'transparent'
        }}
      >
        {navigation.map((item) => {
          const isActive = item.active.filter((i) => location.pathname.includes(i)).length !== 0;

          let component = '';
          let componentProps = {};
          if (!item.external) {
            component = RouterLink;
            componentProps.to = item.url;
          }

          if (item.external) {
            component = 'a';
            componentProps = {
              underline: 'hover',
              target: '_blank',
              rel: 'noopener noreferrer',
              href: item.url,
            };
          }
          return (
            <BottomNavigationAction
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...componentProps}
              component={component}
              to={item.url}
              key={item.url}
              label={item.name}
              icon={item.icon}
              sx={{
                textAlign: 'center',
                color: 'white.main',
                minWidth: '70px',
                '& .MuiSvgIcon-root': {
                  color: isActive ? 'secondary.main' : 'white.main',
                },
              }}
              onClick={() => {
                if (item.name === 'Client Education') {
                  ReactGA.event({
                    category: 'Header',
                    action: 'redirect-to-cliented',
                  });
                  ReactGA4.event('redirect-to-cliented');
                }
              }}
            />
          );
        })}
      </BottomNavigation>
    </Paper>
  );
};

BottomNavBar.propTypes = {
  currentQuestion: PropTypes.string,
  currentCategory: PropTypes.string,
};

BottomNavBar.defaultProps = {
  currentQuestion: '',
  currentCategory: '',
};

/**
 * Maps items from the redux store to apps props.
 * @param {object} state - des
 * @returns {{user: *}} - maps user from redux to apps props
 */
function mapStateToProps(state) {
  return {
    currentQuestion: state.searchResults.current.question,
    currentCategory: state.searchResults.current.category,
  };
}

export default connect(mapStateToProps)(BottomNavBar);
