import React, { useEffect } from 'react';
import Proptypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { thirdPartyLogin } from '../../actions/authentication/authentication';
import useCancelToken from '../../hooks/useCancelToken';

const EmbedHeartbeat = (props) => {
  const { user, login, credentials } = props;
  const { cancelToken } = useCancelToken();

  const checkExpiry = () => {
    const expiry = user.tokenExpiry;
    const now = Math.floor(new Date().getTime() / 1000);

    if ((expiry - now) <= MODAL_TIMER) {
      login(
        credentials.third_party_token,
        credentials.user_details.user_id,
        user.token,
        cancelToken
      );
    }
  };

  useEffect(() => {
    const checkExpiryInterval = setInterval(() => {
      checkExpiry();
    }, 1000 * 10);

    /**
     * Cancels active axios calls if component is unmounted.
     */
    return () => {
      clearInterval(checkExpiryInterval);
    };
  }, [user]);

  return <></>;
};

/**
 * Maps items from the redux store to apps props.
 * @param {object} state - des
 * @returns {{user: *}} - maps user from redux to apps props
 */
function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

/**
 * Maps actions to component props.
 * @param {Dispatch} dispatch - allows action creators to work with redux.
 * @returns {{logout: *, authToken: *, login: *}} - bound action creators
 */
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      login: thirdPartyLogin,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(EmbedHeartbeat);

EmbedHeartbeat.propTypes = {
  login: Proptypes.func.isRequired,
  user: Proptypes.shape({
    tokenExpiry: Proptypes.number,
    token: Proptypes.string,
  }).isRequired,
  credentials: Proptypes.shape({
    third_party_token: Proptypes.string,
    user_details: Proptypes.shape({
      user_id: Proptypes.number,
    }),
  }).isRequired,
};
