import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { Link, Typography, useTheme } from '@mui/material';

const CustomLink = (props) => {
  const {
    text, to, external, disabled, color
  } = props;

  const theme = useTheme();

  let linkColor;

  switch (color) {
    case 'blue':
      linkColor = theme.palette.lightBlue;
      break;
    case 'darkGrey':
      linkColor = theme.palette.darkGrey;
      break;
    case 'text':
      linkColor = theme.palette.text.primary;
      break;
    default:
      linkColor = theme.palette.lightBlue;
      break;
  }

  if (disabled) {
    return (
      <Typography
        sx={{
          cursor: 'default',
          display: 'inline-block',
          color: linkColor,
          '&:hover': {
            color: linkColor,
          },
        }}
      >
        {text}
      </Typography>
    );
  }

  if (external) {
    return (
      <Link
        href={to}
        underline="hover"
        target="_blank"
        rel="noopener noreferrer"
        sx={{
          color: linkColor,
          '&:hover': {
            color: linkColor,
          },
        }}
      >
        {text}
      </Link>
    );
  }

  return (
    <Link
      component={RouterLink}
      to={to}
      underline="hover"
      sx={{
        color: linkColor,
        '&:hover': {
          color: linkColor,
        },
      }}
    >
      {text}
    </Link>
  );
};

CustomLink.propTypes = {
  text: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  external: PropTypes.bool,
  disabled: PropTypes.bool,
  color: PropTypes.oneOf(['blue', 'darkGrey', 'text']),
};

CustomLink.defaultProps = {
  external: false,
  disabled: false,
  color: 'blue',
};

export default CustomLink;
