import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import AlertModal from '../AlertModal/AlertModal';

const TimeoutModal = (props) => {
  const { open, onClose, end } = props;
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 1
  });

  const calculateTime = () => {
    const difference = end - +Math.floor(new Date().getTime() / 1000);
    let newTimeLeft = {};
    if (difference > 0) {
      newTimeLeft = {
        days: Math.floor(difference / (60 * 60 * 24)),
        hours: Math.floor((difference / (60 * 60)) % 24),
        minutes: Math.floor((difference / 60) % 60),
        seconds: Math.floor((difference) % 60)
      };
    }
    setTimeLeft(newTimeLeft);
  };

  useEffect(() => {
    calculateTime();
    const reCalc = setInterval(() => {
      calculateTime();
    }, 1000);

    return (() => {
      clearInterval(reCalc);
    });
  }, []);

  const timerComponents = [];
  Object.keys(timeLeft).forEach((interval) => {
    if (!timeLeft[interval]) {
      return;
    }
    timerComponents.push(
      <span key={interval}>
        {' '}
        {timeLeft[interval]}
        {' '}
        {interval}
      </span>
    );
  });

  if (timerComponents.length > 0) {
    return (
      <AlertModal
        open={open}
        title="Are you still there?"
        handleClose={onClose}
        buttonText="Yes"
      >
        <>
          You will be logged out in
          {timerComponents.length && timerComponents}
          {'.'}
        </>
      </AlertModal>
    );
  }

  return (
    <Redirect
      to={{
        pathname: '/logout',
        state: { invalid: true },
      }}
    />
  );
};

export default TimeoutModal;

TimeoutModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  end: PropTypes.number.isRequired
};
