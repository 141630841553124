/**
 * List of symptoms for DDX
 * @returns {Array} - string array of symptoms
 */
export default function getSymptoms() {
  return [
    { name: 'lethargy', value: 'lethargy' },
    { name: 'anorexia', value: 'anorexia' },
    { name: 'weight loss', value: 'weight loss' },
    { name: 'fever', value: 'fever' },
    { name: 'jaundice', value: 'jaundice' },
    { name: 'pleural effusion', value: 'pleural effusion' },
    { name: 'abdominal distention', value: 'abdominal distention' },
    { name: 'dyspnea', value: 'dyspnea' },
    { name: 'neurologic signs', value: 'neurologic signs' },
    { name: 'weakness', value: 'weakness' },
    { name: 'ataxia', value: 'ataxia' },
    { name: 'flaccid paralysis', value: 'flaccid paralysis' },
    { name: 'cough', value: 'cough' },
    { name: 'vocalization', value: 'vocalization' },
    { name: 'tachycardia', value: 'tachycardia' },
    { name: 'hypertension', value: 'hypertension' },
    { name: 'vomiting', value: 'vomiting' },
    { name: 'diarrhea', value: 'diarrhea' },
    { name: 'anemia', value: 'anemia' },
    { name: 'spherocytes', value: 'spherocytes' },
    { name: 'back pain', value: 'back pain' },
    { name: 'kyphosis', value: 'kyphosis' },
    { name: 'proprioceptive deficits', value: 'proprioceptive deficits' },
    { name: 'inappetent', value: 'inappetent' },
    { name: 'polyphagia', value: 'polyphagia' },
    { name: 'polyuria', value: 'polyuria' },
    { name: 'pyrexia', value: 'pyrexia' },
    { name: 'azotemia', value: 'azotemia' },
    { name: 'murmur', value: 'murmur' },
    { name: 'hind limb lameness', value: 'hind limb lameness' },
    { name: 'acute', value: 'acute' },
    { name: 'head tilt', value: 'head tilt' },
    { name: 'otitis', value: 'otitis' },
    { name: 'nystagmus', value: 'nystagmus' },
    { name: 'stumbling', value: 'stumbling' },
    { name: 'proprioceptive deficit', value: 'proprioceptive deficit' },
    { name: 'thin coat', value: 'thin coat' },
    { name: 'pot belly', value: 'pot belly' },
    { name: 'polydipsia', value: 'polydipsia' },
    { name: 'pollakiuria', value: 'pollakiuria' },
    { name: 'straining', value: 'straining' },
    { name: 'hematuria', value: 'hematuria' },
    { name: 'dilated pupils', value: 'dilated pupils' },
    { name: 'incontinence', value: 'incontinence' },
    { name: 'shifting leg lameness', value: 'shifting leg lameness' },
    { name: 'patchy alopecia', value: 'patchy alopecia' },
    { name: 'kitten', value: 'kitten' },
    { name: 'cat drinking more than normal', value: 'cat drinking more than normal' },
    { name: 'unsteady on feet', value: 'unsteady on feet' },
    { name: 'decreased conscious proprioception front limb', value: 'decreased conscious proprioception front limb' },
    { name: 'enlarged lymph nodes', value: 'enlarged lymph nodes' },
    { name: 'pale mucus membranes', value: 'pale mucus membranes' },
    { name: 'abdominal enlargement', value: 'abdominal enlargement' },
    { name: 'afebrile', value: 'afebrile' },
    { name: 'dense fluid filled abdomen', value: 'dense fluid filled abdomen' },
    { name: 'reticulocyte count high', value: 'reticulocyte count high' },
    { name: 'hypoproteinemia', value: 'hypoproteinemia' },
    { name: 'parvo negative', value: 'parvo negative' },
    { name: 'hypernatremia hyposthenuria', value: 'hypernatremia hyposthenuria' },
    { name: 'litterbox avoidance', value: 'litterbox avoidance' },
    { name: 'bilateral nasal discharge', value: 'bilateral nasal discharge' },
    { name: 'sneezing', value: 'sneezing' },
  ];
}
