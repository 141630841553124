import React, { useState, useRef, useEffect } from 'react';
import FindInPageOutlinedIcon from '@mui/icons-material/FindInPageOutlined';
import CloseIcon from '@mui/icons-material/Close';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import {
  Box, Button, IconButton, InputAdornment, InputBase, Typography
} from '@mui/material';
import PropTypes from 'prop-types';

const SearchBox = (props) => {
  const {
    setArticleSearch,
    articleSearch,
    articleSearchIndex,
    articleSearchListLength,
    setArticleSearchIndex,
    setArticleSearchListLength,
  } = props;

  const [findOpen, setFindOpen] = useState(false);
  const searchRef = useRef(null);

  useEffect(() => {
    if (findOpen) {
      searchRef.current.focus();
    }
  }, [findOpen]);

  const toggleSearch = () => {
    setFindOpen(!findOpen);
  };

  const changeSearchIndex = (type) => {
    let newSearchIndex;

    if (articleSearchListLength) {
      if (type === 'next') {
        if (articleSearchIndex === undefined) {
          setArticleSearchIndex(0);
        } else {
          newSearchIndex = articleSearchIndex + 1;
          if (newSearchIndex > articleSearchListLength - 1) {
            setArticleSearchIndex(0);
          } else {
            setArticleSearchIndex(newSearchIndex);
          }
        }
      } else if (articleSearchIndex === undefined) {
        setArticleSearchIndex(articleSearchListLength - 1);
      } else {
        newSearchIndex = articleSearchIndex - 1;
        if (newSearchIndex < 0) {
          setArticleSearchIndex(articleSearchListLength - 1);
        } else {
          setArticleSearchIndex(newSearchIndex);
        }
      }
    }
  };

  const handleChange = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      changeSearchIndex('next');
    } else if (event.key === 'Enter' && event.shiftKey) {
      changeSearchIndex('previous');
    }
  };

  if (findOpen) {
    return (
      <Box
        sx={{
          display: 'inline-block',
          border: '1px solid',
          borderRadius: '30px',
          displayPrint: 'none',
          position: { xs: 'absolute', lg: 'relative' },
          top: { xs: '23px', lg: 0 },
          right: { xs: '16px', lg: 0 },
          zIndex: { xs: '1000', lg: 0 },
          backgroundColor: { xs: 'white.main', lg: 'transparent' },
        }}
      >
        <Button
          onClick={toggleSearch}
          variant="contained"
          color="white"
          sx={{
            borderRadius: '50%',
            minWidth: '0px',
            padding: '10px',
            boxShadow: 'none',
          }}
        >
          <CloseIcon />
        </Button>
        <InputBase
          sx={{
            ml: 1,
            flex: 1,
            width: { xs: 'auto', lg: '45%', xl: 'auto' },
          }}
          placeholder="Find In Article"
          inputProps={{ 'aria-label': 'search in article' }}
          value={articleSearch}
          inputRef={searchRef}
          onChange={(e) => {
            setArticleSearch(e.target.value);
            setArticleSearchListLength(undefined);
          }}
          onKeyDown={handleChange}
          endAdornment={
            articleSearch && articleSearchListLength !== undefined ? (
              <InputAdornment
                position="end"
                sx={{ minWidth: '65px' }}
              >
                <Typography
                  sx={{
                    fontWeight: 'bold',
                    color: 'secondary.main',
                  }}
                >
                  {articleSearchIndex === undefined ? 0 : articleSearchIndex + 1}
                </Typography>
                <Typography
                  sx={{
                    fontWeight: 'bold',
                    color: 'black',
                  }}
                >
                  /
                </Typography>
                <Typography
                  sx={{
                    fontWeight: 'bold',
                    color: 'black',
                  }}
                >
                  {articleSearchListLength}
                </Typography>
              </InputAdornment>
            ) : (
              <></>
            )
          }
        />
        <IconButton
          disabled={!articleSearch}
          onClick={() => {
            changeSearchIndex('previous');
          }}
        >
          <ArrowUpwardIcon />
        </IconButton>
        <IconButton
          disabled={!articleSearch}
          onClick={() => {
            changeSearchIndex('next');
          }}
        >
          <ArrowDownwardIcon />
        </IconButton>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: 'inline-block',
        border: '1px solid',
        borderRadius: '30px',
        displayPrint: 'none',
      }}
    >
      <Button
        variant="contained"
        color="white"
        sx={{
          borderRadius: '50%',
          minWidth: '0px',
          padding: '10px',
          boxShadow: 'none',
        }}
        onClick={toggleSearch}
      >
        <FindInPageOutlinedIcon />
      </Button>
    </Box>
  );
};

SearchBox.propTypes = {
  setArticleSearch: PropTypes.func.isRequired,
  setArticleSearchIndex: PropTypes.func.isRequired,
  setArticleSearchListLength: PropTypes.func.isRequired,
  articleSearch: PropTypes.string.isRequired,
  articleSearchListLength: PropTypes.number,
  articleSearchIndex: PropTypes.number,
};

SearchBox.defaultProps = {
  articleSearchIndex: undefined,
  articleSearchListLength: undefined,
};

export default SearchBox;
