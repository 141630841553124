import AuthRequest from '../api/AuthRequest';
import { RESET_REDUX, RESET_NAV, RESET_SEARCHRESULT } from './actions';

// ===== ACTION CREATORS =====
/**
 * Action creator for setting redux to its initial state.
 * @returns {object} - action
 */
function resetRedux() {
  return {
    type: RESET_REDUX
  };
}

// ===== MIDDLEWARE =====
/**
 * Clears auth request header.
 * Clears the user section of store of old session data by setting it to initial state.
 * @returns {Function} - executable function.
 */
export function clearRedux() {
  return (dispatch) => {
    AuthRequest.defaults.headers.common.Authorization = '';
    dispatch(resetRedux());
  };
}

/**
 *
 */
export function resetNav() {
  return {
    type: RESET_NAV
  };
}

/**
 *
 */
export function resetSearchResult() {
  return {
    type: RESET_SEARCHRESULT
  };
}
