import React, { useState } from 'react';
import * as Proptypes from 'prop-types';
import {
  Box,
  Link,
  Typography,
  Dialog,
  TextField,
  Button,
  IconButton,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import CancelIcon from '@mui/icons-material/Cancel';
import AddCircleIcon from '@mui/icons-material/AddCircle';

const ShareArticleModal = (props) => {
  const {
    open, title, subtitle, handleClose, id, publisher, link
  } = props;

  const [emails, setEmails] = useState(['', '']);
  const [message, setMessage] = useState('');
  const [emailErrors, setEmailErrors] = useState(['', '', '']);

  let numberWords = 0;

  if (message) {
    numberWords = message.trim().split(' ').length;
  }

  const clearModal = () => {
    setEmails(['', '']);
    setEmailErrors(['', '', '']);
    setMessage('');
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let error = false;

    // eslint-disable-next-line no-control-regex, no-useless-escape
    const expression = /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
    const errors = ['', '', ''];

    emails.forEach((email, index) => {
      if (!email && index === 0) {
        error = true;
        errors[index] = 'Email is required!';
      } else if (email && !expression.test(email.toLowerCase())) {
        error = true;
        errors[index] = 'Email is not valid!';
      } else {
        errors[index] = '';
      }
    });
    if (numberWords < 100 && !error) {
      handleClose('send', id, emails, message.trim(), title, subtitle, clearModal);
    } else {
      setEmailErrors(errors);
    }
  };

  let key = 0;
  const emailDisplay = emails.map((email, index) => {
    let rightSide = <Box sx={{ minWidth: '40px' }} />;
    if (index === 1 && emails.length < 3) {
      rightSide = (
        <Box>
          <IconButton
            onClick={() => {
              const newEmails = emails.slice(0);
              newEmails.push('');
              setEmails(newEmails);
            }}
          >
            <AddCircleIcon sx={{ color: 'black.main' }} />
          </IconButton>
        </Box>
      );
    }

    if (index === 2) {
      rightSide = (
        <Box>
          <IconButton
            onClick={() => {
              const newEmails = emails.slice(0, 2);
              setEmails(newEmails);
            }}
          >
            <CancelIcon sx={{ color: 'black.main' }} />
          </IconButton>
        </Box>
      );
    }

    key += 1;
    return (
      <Box
        key={key}
        py={1}
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <TextField
          fullWidth
          variant="outlined"
          value={emails[index]}
          error={!!emailErrors[index]}
          helperText={emailErrors[index]}
          onChange={(e) => {
            const newEmails = emails.slice(0);
            newEmails[index] = e.target.value;
            setEmails(newEmails);
          }}
          sx={{
            '& legend': {
              width: 'auto',
            },
            '& .MuiOutlinedInput-root': {
              height: '40px'
            }
          }}
        />
        {rightSide}
      </Box>
    );
  });

  const titleStyle = {
    fontSize: '1.5625rem',
    fontWeight: 'bold',
    color: grey[600],
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{
        '& .MuiPaper-root': {
          borderRadius: '16px',
        },
      }}
    >
      <Box
        sx={{
          p: 4,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography
            sx={{
              ...titleStyle,
              pr: 1,
              fontSize: '1.75rem',
            }}
          >
            {title}
          </Typography>
          <Box>
            <IconButton
              onClick={() => {
                handleClose('cancel');
              }}
            >
              <CancelIcon
                sx={{
                  fontSize: '2rem',
                  color: 'black.main',
                }}
              />
            </IconButton>
          </Box>
        </Box>
        <Box
          sx={{
            fontSize: '0.75rem',
            pt: 1.125,
            pb: 3.25,
          }}
        >
          <Typography sx={{ display: 'block', color: grey[600], fontSize: 'inherit' }}>
            {subtitle}
          </Typography>
          <Link
            href={link}
            sx={{
              color: 'lightBlue.main',
              '&:hover, &:active': {
                color: 'lightBlue.main',
              },
            }}
            underline="hover"
            target="_blank"
            rel="noopener noreferrer"
          >
            {publisher}
          </Link>
        </Box>
        <form onSubmit={handleSubmit}>
          <Box>
            <Typography sx={{ ...titleStyle, pb: 1.625 }}>Share With:</Typography>
            {emailDisplay}
            <Typography sx={{ ...titleStyle, pt: 1.6875, pb: 1.875 }}>Message</Typography>
            <TextField
              type="text"
              fullWidth
              variant="outlined"
              value={message}
              multiline
              rows={7}
              onChange={(e) => {
                if (e.target.value.trim().split(' ').length <= 100) {
                  setMessage(e.target.value);
                }
              }}
              sx={{
                pr: 5,
                '& legend': {
                  width: 'auto',
                },
              }}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              pr: 5,
            }}
          >
            <Typography
              sx={{
                color: 'secondary.main',
              }}
            >
              {`${numberWords}/100 Words`}
            </Typography>
          </Box>

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              pr: 5,
              pt: 4.25,
            }}
          >
            <Button
              variant="outlined"
              color="black"
              type="button"
              onClick={() => {
                handleClose('cancel');
              }}
              sx={{ minWidth: '100px', padding: '6px 10px' }}
            >
              Cancel
            </Button>
            <Box px={1} />
            <Button
              variant="contained"
              color="secondary"
              type="submit"
              sx={{ minWidth: '100px', padding: '6px 10px' }}
            >
              Send
            </Button>
          </Box>
        </form>
      </Box>
    </Dialog>
  );
};

export default ShareArticleModal;

ShareArticleModal.propTypes = {
  open: Proptypes.bool.isRequired,
  title: Proptypes.string.isRequired,
  subtitle: Proptypes.string.isRequired,
  handleClose: Proptypes.func.isRequired,
  id: Proptypes.string.isRequired,
  publisher: Proptypes.string.isRequired,
  link: Proptypes.string.isRequired,
};
