import React, { Component } from 'react';
import * as Proptypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import Image from 'react-bootstrap/Image';
import { Redirect } from 'react-router-dom';
import { Box } from '@mui/material';
import { acceptAgreement } from '../../actions/userUpdates/updateUser';
import AuthRequest from '../../api/AuthRequest';
import Button from '../Button/Button';
import './TermsPage.scss';
import Logo from '../Logo/Logo';

/**
 * Renders the terms and conditions page.
 */
class TermsPage extends Component {
  /**
   * Initializes component
   * @param {object} props - defined in proptypes
   */
  constructor(props) {
    super(props);
    this.state = {
      content: '',
    };
    this.logout = this.logout.bind(this);
  }

  /**
   * Fetches the HTML for the terms page.
   */
  componentDidMount() {
    AuthRequest({
      method: 'get',
      url: 'agreement',
    }).then((res) => {
      this.setState({ content: res.data.message });
    }).catch(() => {
      this.setState({ content: <p>there was an error!</p> });
    });
  }

  /**
   * Logs the user out if they do not accept the terms and conditions.
   */
  logout() {
    const { history } = this.props;
    history.push('/logout');
  }

  /**
   * Renders component
   * @returns {*} - DOM description
   */
  render() {
    const {
      accept, user, purina
    } = this.props;
    const { content } = this.state;

    if (user.agreementAgreed) {
      return <Redirect to="/ask" />;
    }

    let renderContent = (
      <div className="pt-4 d-flex justify-content-center">
        <Spinner animation="border" />
      </div>
    );

    let buttons = '';

    if (content) {
      renderContent = (
        // eslint-disable-next-line react/no-danger
        <div dangerouslySetInnerHTML={{
          __html: content
        }}
        />
      );
      buttons = (
        <div className="terms_buttons">
          <Button
            onClick={accept}
            customContent="Accept"
            customVariant="py-3 px-5 mr-4"
            variant="primaryLight-darken"
          />
        </div>
      );
    }

    let images = (
      <Box
        pl={{ xs: 1.875, lg: 5 }}
      >
        <Logo />
      </Box>
    );

    if (purina) {
      images = (
        <div className="terms_images">
          <Image src={`${UM_URL}/svg/logo.svg`} className="logo img-responsive" width="175px" />
          <Image src={`${UM_URL}ui-images/purinaLogo.png`} className="logo img-responsive" width="175px" />
        </div>
      );
    }

    return (
      <div className="terms">
        {images}
        <div className="container">
          <div className="row">
            <div className="col-lg-1" />
            <div className="col-lg-10">
              {renderContent}
            </div>
          </div>
          <div className="row">
            <div className="col-lg-1" />
            <div className="col-lg-10">
              {buttons}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

/**
 * Maps items from the redux store to apps props.
 * @param {object} state - des
 * @returns {{user: *}} - maps user from redux to apps props
 */
function mapStateToProps(state) {
  return {
    user: state.user
  };
}

/**
 * Maps actions to component props.
 * @param {Dispatch} dispatch - allows action creators to work with redux.
 * @returns {{logout: *, authToken: *, login: *}} - bound action creators
 */
function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    accept: acceptAgreement
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(TermsPage);

TermsPage.propTypes = {
  accept: Proptypes.func.isRequired,
  user: Proptypes.shape({
    agreementAgreed: Proptypes.bool
  }).isRequired,
  history: Proptypes.shape({
    push: Proptypes.func
  }).isRequired,
  purina: Proptypes.bool
};

TermsPage.defaultProps = {
  purina: false
};
