exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports

exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);"]);
exports.push([module.id, "@import url(https://use.fontawesome.com/releases/v5.7.2/css/all.css);"]);
// Module
exports.push([module.id, ".ConfirmationModal{display:flex;flex-flow:column wrap;align-self:flex-start;margin:3rem 0;background:#fff;overflow:auto;border-radius:4px;outline:none;min-width:383.5px;max-width:40%}.ConfirmationModal_header{display:flex;flex-flow:row nowrap;padding:1rem 2rem 1rem 2rem;background:#b20012;color:#fff}.ConfirmationModal_header_title{font-size:xx-large}.ConfirmationModal_body{display:flex;flex-flow:column wrap;margin:2rem 1rem 1rem 1rem;padding:0 20px 0 20px}.ConfirmationModal_body_message{font-size:large;margin-bottom:0}.ConfirmationModal_footer{display:flex;flex-flow:row nowrap;justify-content:space-around;margin:1rem 0;padding-top:1rem;border-top:2px solid #ececec}.ConfirmationModal_overlay{position:fixed;top:0;left:0;right:0;bottom:0;background-color:rgba(0,0,0,0.5);overflow-y:auto;display:flex;flex-flow:row nowrap;justify-content:center}.ConfirmationModal_parent{overflow:hidden}\n", ""]);
// Exports
exports.locals = {
	"primaryColor": "#006281",
	"secondaryColor": "#f8992d"
};