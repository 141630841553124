exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports

exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);"]);
exports.push([module.id, "@import url(https://use.fontawesome.com/releases/v5.7.2/css/all.css);"]);
// Module
exports.push([module.id, ".terms{display:flex;flex-flow:column nowrap}.terms_images{display:flex;flex-flow:row nowrap;flex:1 0 auto;padding-top:1.5rem}.terms_buttons{text-align:center;margin-bottom:3rem}.terms a{color:#2E2EFF}\n", ""]);
// Exports
exports.locals = {
	"primaryColor": "#006281",
	"secondaryColor": "#f8992d"
};