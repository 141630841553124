import React from 'react';
import * as Proptypes from 'prop-types';
import Image from 'react-bootstrap/Image';
import './ErrorPage.scss';

/**
 * Generates the various error pages.
 * @param {object} props - defined in proptypes
 * @returns {*} - 404 page
 * @class
 */
const ErrorPage = (props) => {
  const { type } = props;

  // currently only does 404 page.
  switch (type) {
    case 'generic':
      return (
        <div> test error </div>
      );

    case '404':
    default:
      return (
        <div className="errorPage">
          <div className="errorPage_left">
            <div className="errorPage_left_blue">
              OOPS... ERROR
            </div>
            <div className="errorPage_left_orange">
              404
            </div>
            <div className="errorPage_left_blue">
              This page does not exist.
            </div>
          </div>
          <div className="errorPage_right">
            <Image
              src={`${UM_URL}ui-images/ui-404.png`}
              className="logo img-responsive errorPage_right_image"
              width="100%"
            />
          </div>
        </div>
      );
  }
};

export default ErrorPage;

ErrorPage.propTypes = {
  type: Proptypes.oneOf([
    '404',
    'generic'
  ])
};

ErrorPage.defaultProps = {
  type: ''
};
