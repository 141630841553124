import {
  STORE_QUESTION, VIEW_ARTICLE, RESET_REDUX, LOGOUT_COMPLETE, LOGOUT_INVALID_TOKEN, RESET_NAV
} from '../../actions/actions';

const INIT_STATE = {
  question: '/ask',
  askPath: ''
};

const navigationDetails = (state = INIT_STATE, action) => {
  switch (action.type) {

    case STORE_QUESTION:
      return {
        ...state,
        question: action.payload,
        askPath: ''
      };
    case VIEW_ARTICLE:
      return {
        ...state,
        askPath: action.payload
      };

    case LOGOUT_INVALID_TOKEN:
    case LOGOUT_COMPLETE:
    case RESET_REDUX:
    case RESET_NAV:
      return INIT_STATE;

    default:
      return state;
  }
};

export default navigationDetails;
