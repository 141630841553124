/*
 * List of all actions types.
 */

// ===== AUTHENTICATION ACTIONS =====
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
/*
 * no logout failure since if token is invalid or can't establish connection to the backend
 * still want to log the use out.
 */
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_COMPLETE = 'LOGOUT_COMPLETE';
export const LOGOUT_INVALID_TOKEN = 'LOGOUT_INVALID_TOKEN';

export const AUTHENTICATE_TOKEN_REQUEST = 'AUTH_TOKEN_REQUEST';
export const AUTHENTICATE_TOKEN_SUCCESS = 'AUTH_TOKEN_SUCCESS';
export const AUTHENTICATE_TOKEN_FAILURE = 'AUTH_TOKEN_FAILURE';

// ===== REGISTRATION ACTIONS =====
export const REGISTRATION_REQUEST = 'REGISTRATION_REQUEST';
export const REGISTRATION_FAILURE = 'REGISTRATION_FAILURE';
export const REGISTRATION_SUCCESS = 'REGISTRATION_SUCCESS';

// ===== UPDATE USER =====
export const ACCEPT_AGREEMENT_REQUEST = 'ACCEPT_AGREEMENT_REQUEST';
export const ACCEPT_AGREEMENT_SUCCESS = 'ACCEPT_AGREEMENT_SUCCESS';
export const COMPLETED_SURVEY_REQUEST = 'COMPLETED_SURVEY_REQUEST';
export const COMPLETED_SURVEY_SUCCESS = 'COMPLETED_SURVEY_SUCCESS';
export const UPDATE_USER_DETAILS = 'UPDATE_USER_DETAILS';
export const SEEN_EXPIRY_WARNING = 'SEEN_EXPIRY_WARNING';

// ===== ASK QUERIES =====
export const ASK_REQUEST = 'ASK_REQUEST';
export const ASK_SUCCESS = 'ASK_SUCCESS';
export const ASK_FAILURE = 'ASK_FAILURE';
export const UPDATE_CACHE = 'UPDATE_CACHE';
export const SHOWED_MORE = 'SHOWED_MORE';

// ===== SELECTING/CHECKING ARTICLES =====
export const SELECT_ARTICLE = 'SELECT_ARTICLE';
export const UNSELECT_ARTICLE = 'UNSELECT_ARTICLE';
export const SET_CHECKED = 'SET_CHECKED';

// ===== MISC ACTIONS =====
export const RESET_REDUX = 'RESET_REDUX';
export const STORE_UUID = 'STORE_UUID';
export const UPDATE_CATEGORY = 'UPDATE_CATEGORY';

// ===== ADD & REMOVE FAVOURITE =====
export const FAVOURITE_ADD_REQUEST = 'FAVOURITE_ADD_REQUEST';
export const FAVOURITE_ADD_SUCCESS = 'FAVOURITE_ADD_SUCCESS';
export const FAVOURITE_ADD_FAILURE = 'FAVOURITE_ADD_FAILURE';

export const FAVOURITE_REMOVE_REQUEST = 'FAVOURITE_REMOVE_REQUEST';
export const FAVOURITE_REMOVE_SUCCESS = 'FAVOURITE_REMOVE_SUCCESS';
export const FAVOURITE_REMOVE_FAILURE = 'FAVOURITE_REMOVE_FAILURE';

export const FAVOURITE_LIST_REQUEST = 'FAVOURITE_LIST_REQUEST';
export const FAVOURITE_LIST_SUCCESS = 'FAVOURITE_LIST_SUCCESS';
export const FAVOURITE_LIST_FAILURE = 'FAVOURITE_LIST_FAILURE';

export const NOTES_ADD_REQUEST = 'NOTES_ADD_REQUEST';
export const NOTES_ADD_SUCCESS = 'NOTES_ADD_SUCCESS';
export const NOTES_ADD_FAILURE = 'NOTES_ADD_FAILURE';

export const STORE_QUESTION = 'STORE_QUESTION';
export const VIEW_ARTICLE = 'VIEW_ARTICLE';
export const NAVIGATION_DETAILS = 'NAVIGATION_DETAILS';
export const RESET_NAV = 'RESET_NAV';
export const RESET_SEARCHRESULT = 'RESET_SEARCHRESULT';

export const OPEN_BEST_PRACTICES_MODAL_MANUAL = 'OPEN_BEST_PRACTICES_MODAL_MANUAL';
export const CLOSE_BEST_PRACTICES_MODAL_MANUAL = 'CLOSE_BEST_PRACTICES_MODAL_MANUAL';
export const OPEN_BEST_PRACTICES_MODAL_AUTO = 'OPEN_BEST_PRACTICES_MODAL_AUTO';
export const CLOSE_BEST_PRACTICES_MODAL_AUTO = 'CLOSE_BEST_PRACTICES_MODAL_AUTO';
export const BEST_PRACTICES_SUCCESS = 'BEST_PRACTICES_SUCCESS';
export const BEST_PRACTICES_FAILURE = 'BEST_PRACTICES_FAILURE';

export const FAVOURITE_OPEN_MODAL = 'FAVOURITE_OPEN_MODAL';
export const FAVOURITE_CLOSE_MODAL = 'FAVOURITE_CLOSE_MODAL';

export const SAW_POPUP_REQUEST = 'SAW_POPUP_REQUEST';
export const SAW_POPUP_SUCCESS = 'SAW_POPUP_SUCCESS';
export const DISMISS_POPUP = 'DISMISS_POPUP';

export const NOTES_EDIT = 'NOTES_EDIT';
