/**
 * Finds all occurrences of synonmys for the hotspots
 * @param {array } hotspot - reference to parent DOM element
 */
export default function Synonmy(hotspot) {

  let synonmys = [
    {
      "expanded_terms": [
        "abg",
        "arterial blood gas"
      ]
    },
    {
      "expanded_terms": [
        "act",
        "activated coagulation time"
      ]
    },
    {
      "expanded_terms": [
        "acth",
        "adrenocorticotropic hormone"
      ]
    },
    {
      "expanded_terms": [
        "adh",
        "vasopressin",
        "antidiuretic hormone"
      ]
    },
    {
      "expanded_terms": [
        "af",
        "atrial fibrillation"
      ]
    },
    {
      "expanded_terms": [
        "ag",
        "antigen"
      ]
    },
    {
      "expanded_terms": [
        "ai",
        "artificial insemination"
      ]
    },
    {
      "expanded_terms": [
        "allergic inhalant dermatitis",
        "atopy",
        "atopic dermatitis"
      ]
    },
    {
      "expanded_terms": [
        "aiha",
        "autoimmune hemolytic anemia"
      ]
    },
    {
      "expanded_terms": [
        "alp",
        "alkaline phosphatase",
        "alk phos"
      ]
    },
    {
      "expanded_terms": [
        "aml",
        "acute myeloid leukemia"
      ]
    },
    {
      "expanded_terms": [
        "amm",
        "anterior mediastinal mass"
      ]
    },
    {
      "expanded_terms": [
        "ana",
        "antinuclear antibodies"
      ]
    },
    {
      "expanded_terms": [
        "apc",
        "atrial premature contraction"
      ]
    },
    {
      "expanded_terms": [
        "aptt",
        "activated partial thromboplastin time"
      ]
    },
    {
      "expanded_terms": [
        "ards",
        "adult respiratory distress syndrome"
      ]
    },
    {
      "expanded_terms": [
        "asd",
        "atrial septal defect"
      ]
    },
    {
      "expanded_terms": [
        "ast",
        "aspartate transaminase",
        "aspartate aminotransferase",
        "aspartic acid transaminase",
        "aspartic acid aminotransferase"
      ]
    },
    {
      "expanded_terms": [
        "atp",
        "adenosine triphosphate"
      ]
    },
    {
      "expanded_terms": [
        "avf",
        "augmented voltage foot"
      ]
    },
    {
      "expanded_terms": [
        "avl",
        "augmented voltage left arm"
      ]
    },
    {
      "expanded_terms": [
        "avr",
        "augmented voltage right arm"
      ]
    },
    {
      "expanded_terms": [
        "ba",
        "bile acids"
      ]
    },
    {
      "expanded_terms": [
        "bcs",
        "body condition score"
      ]
    },
    {
      "expanded_terms": [
        "blasto",
        "blastomyces",
        "blastomycosis"
      ]
    },
    {
      "expanded_terms": [
        "bmbt",
        "buccal mucosal bleeding time"
      ]
    },
    {
      "expanded_terms": [
        "bmr",
        "basal metabolic rate"
      ]
    },
    {
      "expanded_terms": [
        "bp",
        "blood pressure"
      ]
    },
    {
      "expanded_terms": [
        "bun",
        "blood urea nitrogen"
      ]
    },
    {
      "expanded_terms": [
        "cah",
        "chronic active hepatitis"
      ]
    },
    {
      "expanded_terms": [
        "cav",
        "canine adenovirus"
      ]
    },
    {
      "expanded_terms": [
        "cav-1",
        "cav1",
        "canine adenovirus type 1"
      ]
    },
    {
      "expanded_terms": [
        "cav-2",
        "cav2",
        "canine adenovirus type 2"
      ]
    },
    {
      "expanded_terms": [
        "cbc",
        "complete blood count",
        "hemogram",
        "haemogram"
      ]
    },
    {
      "expanded_terms": [
        "cch",
        "cholangitis-cholangiohepatitis"
      ]
    },
    {
      "expanded_terms": [
        "acl",
        "anterior cruciate ligament",
        "ccl",
        "cranial cruciate ligament"
      ]
    },
    {
      "expanded_terms": [
        "ccs",
        "canine cushing's syndrome",
        "cushing’s disease",
        "hyperadrenocorticism",
        "hac",
        "cushing’s syndrome",
        "cushings"
      ]
    },
    {
      "expanded_terms": [
        "cdi",
        "central diabetes insipidus"
      ]
    },
    {
      "expanded_terms": [
        "cdv",
        "canine distemper virus"
      ]
    },
    {
      "expanded_terms": [
        "chd",
        "canine hip dysplasia"
      ]
    },
    {
      "expanded_terms": [
        "chf",
        "congestive heart failure"
      ]
    },
    {
      "expanded_terms": [
        "chv",
        "canine herpesvirus"
      ]
    },
    {
      "expanded_terms": [
        "civ",
        "canine influenza virus"
      ]
    },
    {
      "expanded_terms": [
        "ck",
        "creatine kinase"
      ]
    },
    {
      "expanded_terms": [
        "cll",
        "chronic lymphocytic leukemia"
      ]
    },
    {
      "expanded_terms": [
        "cmi",
        "cell-mediated immunity"
      ]
    },
    {
      "expanded_terms": [
        "cmo",
        "craniomandibular osteopathy"
      ]
    },
    {
      "expanded_terms": [
        "cns",
        "central nervous system"
      ]
    },
    {
      "expanded_terms": [
        "co2",
        "carbon dioxide"
      ]
    },
    {
      "expanded_terms": [
        "copd",
        "chronic obstructive pulmonary disease",
        "heaves"
      ]
    },
    {
      "expanded_terms": [
        "cvc",
        "caudal vena cava"
      ]
    },
    {
      "expanded_terms": [
        "cpap",
        "continuous positive airway pressure"
      ]
    },
    {
      "expanded_terms": [
        "cpcr",
        "cardiopulmonary cerebrovascular resuscitation"
      ]
    },
    {
      "expanded_terms": [
        "cpiv",
        "canine parainfluenza virus"
      ]
    },
    {
      "expanded_terms": [
        "cpk",
        "creatinine phosphokinase"
      ]
    },
    {
      "expanded_terms": [
        "cpli",
        "canine pancreatic lipase"
      ]
    },
    {
      "expanded_terms": [
        "cpr",
        "cardiopulmonary resuscitation"
      ]
    },
    {
      "expanded_terms": [
        "cpra",
        "central progressive retinal atrophy"
      ]
    },
    {
      "expanded_terms": [
        "cpv",
        "canine parvovirus"
      ]
    },
    {
      "expanded_terms": [
        "cpv-2",
        "cpv2",
        "canine parvovirus type 2"
      ]
    },
    {
      "expanded_terms": [
        "crd",
        "chronic renal disease"
      ]
    },
    {
      "expanded_terms": [
        "crf",
        "chronic renal failure"
      ]
    },
    {
      "expanded_terms": [
        "cri",
        "constant rate infusion"
      ]
    },
    {
      "expanded_terms": [
        "crit",
        "hematocrit",
        "hct"
      ]
    },
    {
      "expanded_terms": [
        "crt",
        "capillary refill time"
      ]
    },
    {
      "expanded_terms": [
        "crvc",
        "cranial vena cava"
      ]
    },
    {
      "expanded_terms": [
        "crypto",
        "cryptococcus sp.",
        "cryptococcus species",
        "cryptococcosis",
        "cryptococcus"
      ]
    },
    {
      "expanded_terms": [
        "csf",
        "cerebrospinal fluid"
      ]
    },
    {
      "expanded_terms": [
        "cv",
        "cardiovascular",
        "cardio"
      ]
    },
    {
      "expanded_terms": [
        "cvd",
        "chronic valvular disease"
      ]
    },
    {
      "expanded_terms": [
        "cvp",
        "central venous pressure"
      ]
    },
    {
      "expanded_terms": [
        "d5w",
        "5% dextrose in water"
      ]
    },
    {
      "expanded_terms": [
        "dcm",
        "dilated cardiomyopathy"
      ]
    },
    {
      "expanded_terms": [
        "dic",
        "disseminated intravascular coagulation"
      ]
    },
    {
      "expanded_terms": [
        "dle",
        "discoid lupus erythematosus"
      ]
    },
    {
      "expanded_terms": [
        "dm",
        "diabetes mellitus"
      ]
    },
    {
      "expanded_terms": [
        "dmso",
        "dimethyl sulfoxide"
      ]
    },
    {
      "expanded_terms": [
        "dna",
        "deoxyribonucleic acid"
      ]
    },
    {
      "expanded_terms": [
        "dv",
        "dorsoventral"
      ]
    },
    {
      "expanded_terms": [
        "ecf",
        "extracellular fluid"
      ]
    },
    {
      "expanded_terms": [
        "echo",
        "echocardiography",
        "echocardiogram"
      ]
    },
    {
      "expanded_terms": [
        "ed",
        "elbow dysplasia"
      ]
    },
    {
      "expanded_terms": [
        "edp",
        "edema disease principle"
      ]
    },
    {
      "expanded_terms": [
        "edta",
        "ethylenediaminetetraacetic acid"
      ]
    },
    {
      "expanded_terms": [
        "eeg",
        "electroencephalogram"
      ]
    },
    {
      "expanded_terms": [
        "eent",
        "eyes ears nose throat",
        "ears eyes nose throat"
      ]
    },
    {
      "expanded_terms": [
        "efa",
        "essential fatty acids"
      ]
    },
    {
      "expanded_terms": [
        "egc",
        "eosinophilic granuloma complex"
      ]
    },
    {
      "expanded_terms": [
        "elisa",
        "enzyme-linked immunosorbent assay"
      ]
    },
    {
      "expanded_terms": [
        "epi",
        "exocrine pancreatic insufficiency"
      ]
    },
    {
      "expanded_terms": [
        "epo",
        "erythropoietin"
      ]
    },
    {
      "expanded_terms": [
        "et",
        "endotracheal tube"
      ]
    },
    {
      "expanded_terms": [
        "etw",
        "endotracheal wash"
      ]
    },
    {
      "expanded_terms": [
        "fa",
        "fatty acid"
      ]
    },
    {
      "expanded_terms": [
        "fad",
        "flea allergy dermatitis"
      ]
    },
    {
      "expanded_terms": [
        "fb",
        "foreign body"
      ]
    },
    {
      "expanded_terms": [
        "fce",
        "fibrocartilaginous embolism"
      ]
    },
    {
      "expanded_terms": [
        "fcp",
        "fragmentation coronoid process",
        "fragmented coronoid process"
      ]
    },
    {
      "expanded_terms": [
        "fcv",
        "feline calicivirus"
      ]
    },
    {
      "expanded_terms": [
        "fda",
        "food drug administration"
      ]
    },
    {
      "expanded_terms": [
        "fdp",
        "fibrin degradation products"
      ]
    },
    {
      "expanded_terms": [
        "fdv",
        "feline distemper virus"
      ]
    },
    {
      "expanded_terms": [
        "fe",
        "iron"
      ]
    },
    {
      "expanded_terms": [
        "fecv",
        "feline enteric coronavirus"
      ]
    },
    {
      "expanded_terms": [
        "feline",
        "cat"
      ]
    },
    {
      "expanded_terms": [
        "felv",
        "feline leukemia virus"
      ]
    },
    {
      "expanded_terms": [
        "ffa",
        "free fatty acids"
      ]
    },
    {
      "expanded_terms": [
        "ffp",
        "fresh frozen plasma"
      ]
    },
    {
      "expanded_terms": [
        "fho",
        "femoral head osteotomy"
      ]
    },
    {
      "expanded_terms": [
        "fhv",
        "feline herpesvirus"
      ]
    },
    {
      "expanded_terms": [
        "fip",
        "feline infectious peritonitis"
      ]
    },
    {
      "expanded_terms": [
        "fiv",
        "feline immunodeficiency virus"
      ]
    },
    {
      "expanded_terms": [
        "flutd",
        "feline lower urinary tract disease"
      ]
    },
    {
      "expanded_terms": [
        "fna",
        "fine needle aspiration",
        "fine needle aspirate"
      ]
    },
    {
      "expanded_terms": [
        "fnb",
        "fine needle biopsy"
      ]
    },
    {
      "expanded_terms": [
        "focma",
        "feline oncornavirus-associated cell membrane antigen"
      ]
    },
    {
      "expanded_terms": [
        "forl",
        "feline odontoclastic resorptive lesion"
      ]
    },
    {
      "expanded_terms": [
        "fsa",
        "fibrosarcoma"
      ]
    },
    {
      "expanded_terms": [
        "fsh",
        "follicle-stimulating hormone",
        "follicle stimulating hormone"
      ]
    },
    {
      "expanded_terms": [
        "fsv",
        "feline sarcoma virus"
      ]
    },
    {
      "expanded_terms": [
        "ft4ed",
        "free t4 equilibrium dialysis"
      ]
    },
    {
      "expanded_terms": [
        "fever undetermined origin",
        "fever unknown origin",
        "fuo"
      ]
    },
    {
      "expanded_terms": [
        "fvr",
        "feline viral rhinotracheitis"
      ]
    },
    {
      "expanded_terms": [
        "galt",
        "gut-associated lymphoid tissue",
        "gut associated lymphoid tissue"
      ]
    },
    {
      "expanded_terms": [
        "gb",
        "gallbladder"
      ]
    },
    {
      "expanded_terms": [
        "gdv",
        "gastric dilatation volvulus",
        "bloat"
      ]
    },
    {
      "expanded_terms": [
        "gfr",
        "glomerular filtration rate"
      ]
    },
    {
      "expanded_terms": [
        "ggt",
        "gamma-glutamyl transferase",
        "gamma-glutamyltransferase"
      ]
    },
    {
      "expanded_terms": [
        "gh",
        "growth hormone"
      ]
    },
    {
      "expanded_terms": [
        "ghrh",
        "growth hormone releasing hormone"
      ]
    },
    {
      "expanded_terms": [
        "gi",
        "gastrointestinal"
      ]
    },
    {
      "expanded_terms": [
        "gme",
        "granulomatous meningoencephalomyelitis"
      ]
    },
    {
      "expanded_terms": [
        "gn",
        "glomerulonephritis"
      ]
    },
    {
      "expanded_terms": [
        "gn-rh",
        "gonadotropin releasing hormone",
        "gnrh"
      ]
    },
    {
      "expanded_terms": [
        "h2o",
        "water"
      ]
    },
    {
      "expanded_terms": [
        "h2o2",
        "hydrogen peroxide"
      ]
    },
    {
      "expanded_terms": [
        "hcg",
        "human chorionic gonadotropin"
      ]
    },
    {
      "expanded_terms": [
        "hcl",
        "hydrochloride"
      ]
    },
    {
      "expanded_terms": [
        "hcm",
        "hypertrophic cardiomyopathy"
      ]
    },
    {
      "expanded_terms": [
        "hco3",
        "bicarbonate"
      ]
    },
    {
      "expanded_terms": [
        "hdds",
        "high dose dexamethasone suppression test",
        "high dose dex suppression test"
      ]
    },
    {
      "expanded_terms": [
        "he",
        "hepatic encephalopathy"
      ]
    },
    {
      "expanded_terms": [
        "hgb",
        "hemoglobin",
        "hb",
        "haemaglobin"
      ]
    },
    {
      "expanded_terms": [
        "hge",
        "hemorrhagic gastroenteritis"
      ]
    },
    {
      "expanded_terms": [
        "hhm",
        "humoral hypercalcemia malignancy"
      ]
    },
    {
      "expanded_terms": [
        "hl",
        "hepatic lipidosis"
      ]
    },
    {
      "expanded_terms": [
        "hocm",
        "hypertrophic obstructive cardiomyopathy"
      ]
    },
    {
      "expanded_terms": [
        "hod",
        "hypertrophic osteodystrophy"
      ]
    },
    {
      "expanded_terms": [
        "Fibre",
        "Fiber"
      ]
    },
    {
      "expanded_terms": [
        "hsa",
        "hemangiosarcoma"
      ]
    },
    {
      "expanded_terms": [
        "hw",
        "heartworm",
        "dirofilarial",
        "dirofilariasis",
        "dirofilaria"
      ]
    },
    {
      "expanded_terms": [
        "hwt",
        "heartworm test"
      ]
    },
    {
      "expanded_terms": [
        "hypoac",
        "hypoadrenocorticism",
        "addisons",
        "addisons disease",
        "addison’s",
        "addison’s disease"
      ]
    },
    {
      "expanded_terms": [
        "ibd",
        "inflammatory bowel disease"
      ]
    },
    {
      "expanded_terms": [
        "icf",
        "intracellular fluid"
      ]
    },
    {
      "expanded_terms": [
        "icp",
        "intracranial pressure"
      ]
    },
    {
      "expanded_terms": [
        "iddm",
        "insulin dependent diabetes mellitus"
      ]
    },
    {
      "expanded_terms": [
        "igr",
        "insect growth regulators"
      ]
    },
    {
      "expanded_terms": [
        "iha",
        "immune hemolytic anemia",
        "immune haemolytic anemia"
      ]
    },
    {
      "expanded_terms": [
        "ilutd",
        "idiopathic lower urinary tract disease",
        "feline idiopathic lower urinary tract disease",
        "feline urologic syndrome"
      ]
    },
    {
      "expanded_terms": [
        "imha",
        "immune mediated hemolytic anemia",
        "immune-mediated haemolytic anemia"
      ]
    },
    {
      "expanded_terms": [
        "imtp",
        "immune mediated thrombocytopenia"
      ]
    },
    {
      "expanded_terms": [
        "ippv",
        "intermittent positive pressure ventilation"
      ]
    },
    {
      "expanded_terms": [
        "ivdd",
        "intervertebral disk disease",
        "intervertebral disc disease"
      ]
    },
    {
      "expanded_terms": [
        "ivp",
        "intravenous pyelogram"
      ]
    },
    {
      "expanded_terms": [
        "canine",
        "dog"
      ]
    },
    {
      "expanded_terms": [
        "kbr",
        "potassium bromide"
      ]
    },
    {
      "expanded_terms": [
        "kcal",
        "kilocalorie"
      ]
    },
    {
      "expanded_terms": [
        "kcl",
        "potassium chloride"
      ]
    },
    {
      "expanded_terms": [
        "kcs",
        "keratoconjunctivitis sicca"
      ]
    },
    {
      "expanded_terms": [
        "lae",
        "left atrial enlargement"
      ]
    },
    {
      "expanded_terms": [
        "lau",
        "left auricle"
      ]
    },
    {
      "expanded_terms": [
        "ldds",
        "low dose dexamethasone suppression test",
        "low dose dex suppression test"
      ]
    },
    {
      "expanded_terms": [
        "ldh",
        "lactate dehydrogenase"
      ]
    },
    {
      "expanded_terms": [
        "le",
        "lupus erythematosus"
      ]
    },
    {
      "expanded_terms": [
        "lmn",
        "lower motor neurons"
      ]
    },
    {
      "expanded_terms": [
        "lpe",
        "lymphocytic plasmacytic enteritis"
      ]
    },
    {
      "expanded_terms": [
        "lrs",
        "lactated ringer's solution"
      ]
    },
    {
      "expanded_terms": [
        "lsa",
        "lymphosarcoma",
        "lymphoma"
      ]
    },
    {
      "expanded_terms": [
        "lvfw",
        "left ventricular free wall"
      ]
    },
    {
      "expanded_terms": [
        "lvot",
        "left ventricular outflow tract"
      ]
    },
    {
      "expanded_terms": [
        "lvw",
        "left ventricular wall"
      ]
    },
    {
      "expanded_terms": [
        "malt",
        "mucosa associated lymphoid tissue",
        "mucosa-associated lymphoid tissue"
      ]
    },
    {
      "expanded_terms": [
        "bmbt",
        "buccal mucosal bleeding time"
      ]
    },
    {
      "expanded_terms": [
        "mch",
        "mean corpuscular hemoglobin"
      ]
    },
    {
      "expanded_terms": [
        "mchc",
        "mean corpuscular hemoglobin concentration"
      ]
    },
    {
      "expanded_terms": [
        "mcv",
        "mean cell volume"
      ]
    },
    {
      "expanded_terms": [
        "mda",
        "maternally derived antibody"
      ]
    },
    {
      "expanded_terms": [
        "mgt",
        "mammary gland tumor",
        "mammary gland tumour"
      ]
    },
    {
      "expanded_terms": [
        "mic",
        "minimal inhibitory concentration"
      ]
    },
    {
      "expanded_terms": [
        "mods",
        "multiple organ dysfunction syndrome"
      ]
    },
    {
      "expanded_terms": [
        "mpl",
        "medial patellar luxation"
      ]
    },
    {
      "expanded_terms": [
        "mr",
        "mitral regurgitation"
      ]
    },
    {
      "expanded_terms": [
        "mri",
        "magnetic resonance imaging"
      ]
    },
    {
      "expanded_terms": [
        "mrna",
        "messenger ribonucleic acid"
      ]
    },
    {
      "expanded_terms": [
        "mrsa",
        "methicillin resistant staphylococcus aureus"
      ]
    },
    {
      "expanded_terms": [
        "nacl",
        "sodium chloride"
      ]
    },
    {
      "expanded_terms": [
        "ndi",
        "nephrogenic diabetes insipidus"
      ]
    },
    {
      "expanded_terms": [
        "niddm",
        "non insulin dependent diabetes mellitus"
      ]
    },
    {
      "expanded_terms": [
        "nrbc",
        "nucleated red blood cells"
      ]
    },
    {
      "expanded_terms": [
        "nsaid",
        "nonsteroidal anti inflammatory drug",
        "nonsteroidal anti-inflammatory drug"
      ]
    },
    {
      "expanded_terms": [
        "oa",
        "osteoarthritis"
      ]
    },
    {
      "expanded_terms": [
        "ocd",
        "osteochondritis dissecans",
        "osteochondrosis"
      ]
    },
    {
      "expanded_terms": [
        "castration",
        "orchiectomy",
        "orchidectomy",
        "orchectomy"
      ]
    },
    {
      "expanded_terms": [
        "ofa",
        "orthopedic foundation animals"
      ]
    },
    {
      "expanded_terms": [
        "olm",
        "ocular larval migrans"
      ]
    },
    {
      "expanded_terms": [
        "osa",
        "osteosarcoma"
      ]
    },
    {
      "expanded_terms": [
        "paba",
        "para-aminobenzoic acid"
      ]
    },
    {
      "expanded_terms": [
        "pbfd",
        "psittacine beak feather disease"
      ]
    },
    {
      "expanded_terms": [
        "pcr",
        "polymerase chain reaction"
      ]
    },
    {
      "expanded_terms": [
        "pcv",
        "packed cell volume"
      ]
    },
    {
      "expanded_terms": [
        "pda",
        "patent ductus arteriosus"
      ]
    },
    {
      "expanded_terms": [
        "pdh",
        "pituitary-dependent hyperadrenocorticism"
      ]
    },
    {
      "expanded_terms": [
        "pkd",
        "polycystic kidney disease"
      ]
    },
    {
      "expanded_terms": [
        "ple",
        "protein losing enteropathy"
      ]
    },
    {
      "expanded_terms": [
        "plr",
        "pupillary light response"
      ]
    },
    {
      "expanded_terms": [
        "pn",
        "parenteral nutrition"
      ]
    },
    {
      "expanded_terms": [
        "ppv",
        "positive pressure ventilation"
      ]
    },
    {
      "expanded_terms": [
        "pra",
        "progressive retinal atrophy"
      ]
    },
    {
      "expanded_terms": [
        "praa",
        "persistent right aortic arch"
      ]
    },
    {
      "expanded_terms": [
        "ps",
        "pulmonic stenosis"
      ]
    },
    {
      "expanded_terms": [
        "psgag",
        "polysulfated glycosaminoglycan"
      ]
    },
    {
      "expanded_terms": [
        "pth",
        "parathormone",
        "parathyroid hormone"
      ]
    },
    {
      "expanded_terms": [
        "ptt",
        "partial thromboplastin time"
      ]
    },
    {
      "expanded_terms": [
        "pupd",
        "polyuria polydipsia"
      ]
    },
    {
      "expanded_terms": [
        "pzi",
        "protamine zinc insulin"
      ]
    },
    {
      "expanded_terms": [
        "ra",
        "rheumatoid arthritis"
      ]
    },
    {
      "expanded_terms": [
        "rast",
        "radioallergosorbent test"
      ]
    },
    {
      "expanded_terms": [
        "rcm",
        "restrictive cardiomyopathy"
      ]
    },
    {
      "expanded_terms": [
        "rer",
        "resting energy requirement"
      ]
    },
    {
      "expanded_terms": [
        "rmsf",
        "rocky mountain spotted fever"
      ]
    },
    {
      "expanded_terms": [
        "rna",
        "ribonucleic acid"
      ]
    },
    {
      "expanded_terms": [
        "rsat",
        "rapid slide agglutination test"
      ]
    },
    {
      "expanded_terms": [
        "sap",
        "serum alkaline phosphatase "
      ]
    },
    {
      "expanded_terms": [
        "sard",
        "sudden acquired retinal degeneration"
      ]
    },
    {
      "expanded_terms": [
        "scc",
        "squamous cell carcinoma"
      ]
    },
    {
      "expanded_terms": [
        "sibo",
        "small intestinal bacterial overgrowth"
      ]
    },
    {
      "expanded_terms": [
        "sirs",
        "systemic inflammatory response syndrome"
      ]
    },
    {
      "expanded_terms": [
        "sle",
        "systemic lupus erythematosus"
      ]
    },
    {
      "expanded_terms": [
        "spd",
        "salmon poisoning disease"
      ]
    },
    {
      "expanded_terms": [
        "ssri",
        "selective serotonin reuptake inhibitors"
      ]
    },
    {
      "expanded_terms": [
        "staph",
        "staphylococcus",
        "staphylococcal"
      ]
    },
    {
      "expanded_terms": [
        "strep",
        "streptococcus"
      ]
    },
    {
      "expanded_terms": [
        "stt",
        "schirmer tear test"
      ]
    },
    {
      "expanded_terms": [
        "svt",
        "supraventricular tachycardia"
      ]
    },
    {
      "expanded_terms": [
        "tuberculosis",
        "mycobacterium tuberculosis"
      ]
    },
    {
      "expanded_terms": [
        "tbf",
        "tick-borne fever"
      ]
    },
    {
      "expanded_terms": [
        "tcc",
        "transitional cell carcinoma"
      ]
    },
    {
      "expanded_terms": [
        "testis",
        "testicle"
      ]
    },
    {
      "expanded_terms": [
        "tli",
        "trypsin-like immunoreactivity"
      ]
    },
    {
      "expanded_terms": [
        "tnf",
        "tumor necrosis factor",
        "tumour necrosis factor"
      ]
    },
    {
      "expanded_terms": [
        "tpo",
        "total pelvic osteotomy"
      ]
    },
    {
      "expanded_terms": [
        "trh",
        "thyrotropin releasing hormone"
      ]
    },
    {
      "expanded_terms": [
        "tsh",
        "thyroid stimulating hormone",
        "thyrotropin"
      ]
    },
    {
      "expanded_terms": [
        "ttw",
        "transtracheal wash",
        "transtracheal washing"
      ]
    },
    {
      "expanded_terms": [
        "uap",
        "ununited anconeal process"
      ]
    },
    {
      "expanded_terms": [
        "umn",
        "upper motor neurons"
      ]
    },
    {
      "expanded_terms": [
        "uri",
        "upper respiratory infection"
      ]
    },
    {
      "expanded_terms": [
        "urt",
        "upper respiratory tract"
      ]
    },
    {
      "expanded_terms": [
        "usg",
        "urine specific gravity"
      ]
    },
    {
      "expanded_terms": [
        "uti",
        "urinary tract infection"
      ]
    },
    {
      "expanded_terms": [
        "vf",
        "ventricular fibrillation"
      ]
    },
    {
      "expanded_terms": [
        "vpc",
        "ventricular premature contraction"
      ]
    },
    {
      "expanded_terms": [
        "vsd",
        "ventricular septal defect"
      ]
    },
    {
      "expanded_terms": [
        "vwf",
        "von willebrand's factor"
      ]
    },
    {
      "expanded_terms": [
        "bal",
        "bronchoalveolar lavage"
      ]
    },
    {
      "expanded_terms": [
        "ca",
        "calcium"
      ]
    },
    {
      "expanded_terms": [
        "cu",
        "copper"
      ]
    },
    {
      "expanded_terms": [
        "ecg",
        "electrocardiogram",
        "ekg"
      ]
    },
    {
      "expanded_terms": [
        "pvc",
        "premature ventricular contraction"
      ]
    },
    {
      "expanded_terms": [
        "rvot",
        "right ventricular outflow"
      ]
    },
    {
      "expanded_terms": [
        "tia",
        "transient ischemic attack"
      ]
    },
    {
      "expanded_terms": [
        "anemia",
        "anaemia"
      ]
    },
    {
      "expanded_terms": [
        "constipation",
        "obstipation"
      ]
    },
    {
      "expanded_terms": [
        "ketoacidosis",
        "dka",
        "diabetic ketoacidosis"
      ]
    },
    {
      "expanded_terms": [
        "fever",
        "pyrexia"
      ]
    },
    {
      "expanded_terms": [
        "petechiae",
        "petechia"
      ]
    },
    {
      "expanded_terms": [
        "pruritus",
        "itch",
        "itchy",
        "pruritis",
        "scratch"
      ]
    },
    {
      "expanded_terms": [
        "diarrhea",
        "diarrhoea"
      ]
    },
    {
      "expanded_terms": [
        "stomatitis",
        "gingivostomatitis"
      ]
    },
    {
      "expanded_terms": [
        "electroretinography",
        "electroretinogram",
        "erg"
      ]
    },
    {
      "expanded_terms": [
        "radiography",
        "xray",
        "x-ray"
      ]
    },
    {
      "expanded_terms": [
        "vomiting",
        "emesis"
      ]
    },
    {
      "expanded_terms": [
        "c-section",
        "caesarian section",
        "cesarian",
        "caesarien",
        "cesarien"
      ]
    },
    {
      "expanded_terms": [
        "acanthocyte",
        "echinoacanthocyte "
      ]
    },
    {
      "expanded_terms": [
        "ammonia",
        "nh3",
        "nh4",
        "ammonium"
      ]
    },
    {
      "expanded_terms": [
        "chloride",
        "cl"
      ]
    },
    {
      "expanded_terms": [
        "cobalamin",
        "cyanocobalamin",
        "b12"
      ]
    },
    {
      "expanded_terms": [
        "glucosuria",
        "glycosuria"
      ]
    },
    {
      "expanded_terms": [
        "pli",
        "pancreatic lipase immunoreactivity"
      ]
    },
    {
      "expanded_terms": [
        "abscess",
        "abscessation"
      ]
    },
    {
      "expanded_terms": [
        "actinomycosis",
        "actinomyces"
      ]
    },
    {
      "expanded_terms": [
        "amebiasis",
        "amoeba",
        "entamoeba",
        "acanthamoeba",
        "ameba",
        "amebic",
        "amoebic"
      ]
    },
    {
      "expanded_terms": [
        "amyloidosis",
        "amyloid"
      ]
    },
    {
      "expanded_terms": [
        "bartonellosis",
        "bartonella",
        "hemobartonella"
      ]
    },
    {
      "expanded_terms": [
        "baylisascariasis",
        "baylisascaris"
      ]
    },
    {
      "expanded_terms": [
        "blastomycosis",
        "blastomyces"
      ]
    },
    {
      "expanded_terms": [
        "bordetellosis",
        "tracheobronchitis",
        "bordetella",
        "kennel cough"
      ]
    },
    {
      "expanded_terms": [
        "borreliosis",
        "borrelia",
        "lyme disease"
      ]
    },
    {
      "expanded_terms": [
        "botulism",
        "clostridium botulinum"
      ]
    },
    {
      "expanded_terms": [
        "brucellosis",
        "brucella "
      ]
    },
    {
      "expanded_terms": [
        "campylobacteriosis",
        "campylobacter"
      ]
    },
    {
      "expanded_terms": [
        "candidiasis",
        "candida"
      ]
    },
    {
      "expanded_terms": [
        "influenza",
        "flu"
      ]
    },
    {
      "expanded_terms": [
        "retinopathy",
        "canine multifocal retinopathy"
      ]
    },
    {
      "expanded_terms": [
        "schistosomiasis",
        "heterobilharziasis",
        "schistosoma",
        "bilharzia"
      ]
    },
    {
      "expanded_terms": [
        "pearsonema",
        "capillaria"
      ]
    },
    {
      "expanded_terms": [
        "cheyletiellosis",
        "cheyletiella",
        "walking dandruff"
      ]
    },
    {
      "expanded_terms": [
        "chlamydiosis",
        "chlamydia",
        "chlamydophila"
      ]
    },
    {
      "expanded_terms": [
        "cholelithiasis",
        "gallstone"
      ]
    },
    {
      "expanded_terms": [
        "chorioretinitis",
        "retinochoroiditis"
      ]
    },
    {
      "expanded_terms": [
        "coccidioidomycosis",
        "coccidioides "
      ]
    },
    {
      "expanded_terms": [
        "coccidiosis",
        "coccidia",
        "isospora",
        "eimeria"
      ]
    },
    {
      "expanded_terms": [
        "dementia",
        "senility"
      ]
    },
    {
      "expanded_terms": [
        "colibacillosis",
        "ecoli",
        "e. coli"
      ]
    },
    {
      "expanded_terms": [
        "coprophagia",
        "coprophagy"
      ]
    },
    {
      "expanded_terms": [
        "cryptosporidiosis",
        "cryptosporidium"
      ]
    },
    {
      "expanded_terms": [
        "cuterebriasis",
        "cuterebra",
        "warbles",
        "botfly"
      ]
    },
    {
      "expanded_terms": [
        "cysticercosis",
        "cysticerci",
        "taenia",
        "cysticercus",
        "tapeworm"
      ]
    },
    {
      "expanded_terms": [
        "demodicosis",
        "demodectic mange",
        "demodex"
      ]
    },
    {
      "expanded_terms": [
        "cavities",
        "caries"
      ]
    },
    {
      "expanded_terms": [
        "dermatophilosis",
        "dermatophilus"
      ]
    },
    {
      "expanded_terms": [
        "dermatophytosis",
        "ringworm",
        "tinea",
        "microsporum",
        "trichophyton"
      ]
    },
    {
      "expanded_terms": [
        "hermaphrodites",
        "intersexes",
        "pseudohermaphrodites"
      ]
    },
    {
      "expanded_terms": [
        "diskospondylitis",
        "discospondylitis",
        "diskitis",
        "discitis"
      ]
    },
    {
      "expanded_terms": [
        "distichiasis",
        "distichia"
      ]
    },
    {
      "expanded_terms": [
        "myelodysplasia",
        "hydromyelia",
        "syringomyelia"
      ]
    },
    {
      "expanded_terms": [
        "otodectiasis",
        "otodectes"
      ]
    },
    {
      "expanded_terms": [
        "ehrlichiosis",
        "ehrlichia"
      ]
    },
    {
      "expanded_terms": [
        "epulis",
        "epulides"
      ]
    },
    {
      "expanded_terms": [
        "pseudopregnancy",
        "pseudocyesis",
        "pseudogestation",
        "false pregnancy"
      ]
    },
    {
      "expanded_terms": [
        "fear",
        "phobia"
      ]
    },
    {
      "expanded_terms": [
        "giardiasis",
        "giardia"
      ]
    },
    {
      "expanded_terms": [
        "glomerulonephritis",
        "glomerulonephropathy"
      ]
    },
    {
      "expanded_terms": [
        "hairballs",
        "trichobezoar"
      ]
    },
    {
      "expanded_terms": [
        "helicobacteriosis",
        "gastrospirillum",
        "helicobacter"
      ]
    },
    {
      "expanded_terms": [
        "hemeralopia",
        "achromatopsia"
      ]
    },
    {
      "expanded_terms": [
        "hepatozoonosis",
        "hepatozoon"
      ]
    },
    {
      "expanded_terms": [
        "histoplasmosis",
        "histoplasma"
      ]
    },
    {
      "expanded_terms": [
        "ancylostomiasis",
        "ancylostoma",
        "uncinaria",
        "hookworm"
      ]
    },
    {
      "expanded_terms": [
        "hyperlipidemia",
        "lipemia"
      ]
    },
    {
      "expanded_terms": [
        "hyperthyroidism",
        "thyrotoxicosis"
      ]
    },
    {
      "expanded_terms": [
        "hypothyroidism",
        "hypothyroid"
      ]
    },
    {
      "expanded_terms": [
        "immunodeficiencies",
        "immunodeficiency"
      ]
    },
    {
      "expanded_terms": [
        "leishmaniasis",
        "leishmania"
      ]
    },
    {
      "expanded_terms": [
        "leptospirosis",
        "leptospira",
        "lepto"
      ]
    },
    {
      "expanded_terms": [
        "sarcoptes",
        "scabies"
      ]
    },
    {
      "expanded_terms": [
        "mastocytosis",
        "mastocytoma"
      ]
    },
    {
      "expanded_terms": [
        "melanocytoma",
        "melanoma",
        "melanosarcoma"
      ]
    },
    {
      "expanded_terms": [
        "mucopolysaccharidosis",
        "mucopolysaccharidoses"
      ]
    },
    {
      "expanded_terms": [
        "mycobacteria",
        "mycobacterium"
      ]
    },
    {
      "expanded_terms": [
        "mycoplasmosis",
        "mycoplasma"
      ]
    },
    {
      "expanded_terms": [
        "myopathies",
        "myopathy"
      ]
    },
    {
      "expanded_terms": [
        "neosporosis",
        "neospora"
      ]
    },
    {
      "expanded_terms": [
        "nephrolithiasis",
        "nephrolith",
        "renoliths"
      ]
    },
    {
      "expanded_terms": [
        "nocardiosis",
        "nocardia"
      ]
    },
    {
      "expanded_terms": [
        "obesity",
        "overweight"
      ]
    },
    {
      "expanded_terms": [
        "panniculitis",
        "steatitis",
        "pansteatitis"
      ]
    },
    {
      "expanded_terms": [
        "papillomatosis",
        "papilloma"
      ]
    },
    {
      "expanded_terms": [
        "phlebitis",
        "thrombophlebitis"
      ]
    },
    {
      "expanded_terms": [
        "yersinia",
        "yersinia pestis",
        "plague"
      ]
    },
    {
      "expanded_terms": [
        "pneumocystosis",
        "pneumocystis"
      ]
    },
    {
      "expanded_terms": [
        "poisoning",
        "intoxication",
        "toxicosis",
        "toxicity"
      ]
    },
    {
      "expanded_terms": [
        "polycythemia",
        "erythrocytosis"
      ]
    },
    {
      "expanded_terms": [
        "polyphagia",
        "hyperphagia"
      ]
    },
    {
      "expanded_terms": [
        "protothecosis",
        "prototheca"
      ]
    },
    {
      "expanded_terms": [
        "pseudoachondroplasia",
        "pseudoachondrodysplasia"
      ]
    },
    {
      "expanded_terms": [
        "ptyalism",
        "hypersalivation",
        "drooling",
        "sialorrhea",
        "salivation"
      ]
    },
    {
      "expanded_terms": [
        "pyelonephritis",
        "pyelitis"
      ]
    },
    {
      "expanded_terms": [
        "pyothorax",
        "empyema"
      ]
    },
    {
      "expanded_terms": [
        "pythiosis",
        "pythium"
      ]
    },
    {
      "expanded_terms": [
        "pyuria",
        "leukocyturia"
      ]
    },
    {
      "expanded_terms": [
        "rhinosporidiosis",
        "rhinosporidium"
      ]
    },
    {
      "expanded_terms": [
        "roundworms",
        "ascariasis",
        "toxocara",
        "toxascaris",
        "baylisascaris",
        "roundworm"
      ]
    },
    {
      "expanded_terms": [
        "salmonellosis",
        "salmonella"
      ]
    },
    {
      "expanded_terms": [
        "convulsions",
        "epilepsy"
      ]
    },
    {
      "expanded_terms": [
        "sepsis",
        "septicemia"
      ]
    },
    {
      "expanded_terms": [
        "snakebite",
        "envenomation"
      ]
    },
    {
      "expanded_terms": [
        "sporotrichosis",
        "sporothrix"
      ]
    },
    {
      "expanded_terms": [
        "strongyloidiasis",
        "strongyloides"
      ]
    },
    {
      "expanded_terms": [
        "tetanus",
        "clostridium",
        "clostridium tetani",
        "lockjaw"
      ]
    },
    {
      "expanded_terms": [
        "tracheobronchomalacia",
        "tracheomalacia"
      ]
    },
    {
      "expanded_terms": [
        "shaking",
        "shuddering",
        "tremors"
      ]
    },
    {
      "expanded_terms": [
        "trichinosis",
        "trichinella"
      ]
    },
    {
      "expanded_terms": [
        "trichomoniasis",
        "trichomonas",
        "tritrichomonas"
      ]
    },
    {
      "expanded_terms": [
        "francisella",
        "tularemia"
      ]
    },
    {
      "expanded_terms": [
        "tumor",
        "tumour"
      ]
    },
    {
      "expanded_terms": [
        "ureterolithiasis",
        "ureterolith"
      ]
    },
    {
      "expanded_terms": [
        "urolithiasis",
        "uroliths"
      ]
    },
    {
      "expanded_terms": [
        "bph",
        "prostatomegaly",
        "benign prostatic hypertrophy"
      ]
    },
    {
      "expanded_terms": [
        "lysine",
        "l-lysine"
      ]
    },
    {
      "expanded_terms": [
        "oncovin",
        "vincristine"
      ]
    },
    {
      "expanded_terms": [
        "adriamycin",
        "doxorubicin"
      ]
    },
    {
      "expanded_terms": [
        "cytosine arabinoside",
        "cytarabine"
      ]
    },
    {
      "expanded_terms": [
        "test",
        "tests",
        "testing",
        "tested"
      ]
    },
    {
      "expanded_terms": [
        "diagnose",
        "diagnosing",
        "diagnosed",
        "diagnosis",
        "diagnoses"
      ]
    },
    {
      "expanded_terms": [
        "treat",
        "treatment",
        "management",
        "treating"
      ]
    },
    {
      "expanded_terms": [
        "differential",
        "differentials"
      ]
    },
    {
      "expanded_terms": [
        "cause",
        "etiology"
      ]
    },
    {
      "expanded_terms": [
        "place",
        "placement"
      ]
    },
    {
      "expanded_terms": [
        "perform",
        "performs",
        "performing"
      ]
    }
  ];

  var synonmysArray = [];
  for (var i = 0; i < hotspot.length; i++) {
    for (var j = 0; j < synonmys.length; j++) {
      var matchKey = synonmys[j]['expanded_terms'].indexOf(hotspot[i]);
      if (matchKey != -1) {
        synonmys[j]['expanded_terms'].splice(matchKey, 1);

        var result = synonmys[j]['expanded_terms'].reduce(function (res, current) {
          return res.concat([current, current + ","]);
        }, []);

        synonmysArray.push(...result)
      }
    }
  }

  return synonmysArray;
}
