import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';

/**
 * Renders Component
 * @param {object} props - Defined in proptypes
 * @returns {JSX.Element} - DOM description
 */
const BlackCheckbox = (props) => {
  const {
    checked, onChange, label, size
  } = props;

  return (
    <FormGroup>
      <FormControlLabel
        label={label}
        sx={{
          mr: { xs: 1, lg: 2.25 },
        }}
        control={(
          <Checkbox
            checked={checked}
            color="black"
            onChange={onChange}
            checkedIcon={<CheckBoxOutlinedIcon />}
            size={size}
            sx={{
              pr: { xs: 0, lg: 1.125 },
              pt: { xs: 0.625, lg: 1.125 },
            }}
          />
        )}
      />
    </FormGroup>
  );
};

export default BlackCheckbox;

BlackCheckbox.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  size: PropTypes.string,
};

BlackCheckbox.defaultProps = {
  label: '',
  size: 'large'
};
