import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import { toast } from 'react-toastify';
import * as Proptypes from 'prop-types';
import Validation from '../../../helpers/Validation';
import AuthRequest from '../../../api/AuthRequest';
import Notification from '../../Notification/Notification';
import Button from '../../Button/Button';
import Checkbox from '../../Checkbox/Checkbox';
import './UserProfile.scss';

/**
 * This component handles displaying and updating an existing user.
 */
class UserProfile extends Component {
  /**
   * Initializes component
   * @param {object} props - defined in proptypes
   */
  constructor(props) {
    super(props);
    this.state = {
      editing: false,
      fname: '',
      lname: '',
      email: '',
      role: '',
      id: '',
      oldFName: '',
      oldLName: '',
      oldEmail: '',
      oldRole: '',
      error: {}
    };
    this.edit = this.edit.bind(this);
    this.fnameChanged = this.fnameChanged.bind(this);
    this.lnameChanged = this.lnameChanged.bind(this);
    this.emailChanged = this.emailChanged.bind(this);
    this.roleChanged = this.roleChanged.bind(this);
    this.saveChanges = this.saveChanges.bind(this);
    this.cancel = this.cancel.bind(this);
    this.select = this.select.bind(this);
  }

  /**
   * Initialize the component with the users data.
   */
  componentDidMount() {
    const { user } = this.props;
    this.setState({
      fname: user.first_name,
      lname: user.last_name,
      email: user.email,
      role: user.role_id,
      id: user.user_id
    });
  }

  /**
   * Turn on the editing state and retain the old information.
   */
  edit() {
    const {
      fname, lname, email, role
    } = this.state;
    this.setState({
      editing: true,
      oldFName: fname,
      oldLName: lname,
      oldEmail: email,
      oldRole: role
    });
  }

  /**
   * Cancel editing and reset the user information.
   */
  cancel() {
    const {
      oldFName, oldLName, oldEmail, oldRole
    } = this.state;
    this.setState({
      fname: oldFName,
      lname: oldLName,
      email: oldEmail,
      role: oldRole,
      editing: false,
      error: {}
    });
  }

  /**
   * Handles changes to the users first name.
   * @param {object} e - event that contains the new data
   */
  fnameChanged(e) {
    const { error } = this.state;
    const newError = error;
    if (error && error['first name']) {
      delete newError['first name'];
    }

    this.setState({
      fname: e.target.value,
      error: newError
    });
  }

  /**
   * Handles changes to the users last name.
   * @param {object} e - event that contains the new data
   */
  lnameChanged(e) {
    const { error } = this.state;
    const newError = error;
    if (error && error['last name']) {
      delete newError['last name'];
    }

    this.setState({
      lname: e.target.value,
      error: newError
    });
  }

  /**
   * Handles changes to the users email.
   * @param {object} e - event that contains the new data
   */
  emailChanged(e) {
    const { error } = this.state;
    const newError = error;
    if (error && error.email) {
      delete newError.email;
    }

    this.setState({
      email: e.target.value,
      error: newError
    });
  }

  /**
   * Handles changes to the users role. Saves the roles ID not the name.
   * @param {object} e - event that contains the new data
   */
  roleChanged(e) {
    const { roles } = this.props;
    const selected = roles.filter((role) => role.name === e.target.value);
    this.setState({
      role: selected[0].id
    });
  }

  /**
   * Saves the changes to the user by sending them to the API.
   * Notifies the user if it succeeds or returns the error message.
   */
  saveChanges() {
    const { user, loggedInUser, updateLoggedInUser } = this.props;
    const {
      id, lname, fname, email, role
    } = this.state;

    const validationObject = [
      {
        name: 'first name',
        value: fname,
        validationType: 'required|alpha',
      },
      {
        name: 'last name',
        value: lname,
        validationType: 'required|alpha',
      },
      {
        name: 'email',
        value: email,
        validationType: 'required|email',
      }
    ];

    const error = Validation.formValidation(validationObject);
    if (Object.keys(error).length !== 0) {
      this.setState({
        error
      });
      return;
    }

    AuthRequest({
      method: 'put',
      url: 'user/update',
      params: {
        email,
        user_id: id,
        first_name: fname,
        last_name: lname,
        role_id: role
      },
    }).then((res) => {
      toast.success(
        <Notification
          title={res.data.message}
          body={`Updated user ${fname} ${lname}`}
        />
      );
      this.setState({
        editing: false,
        oldFName: '',
        oldLName: '',
        oldEmail: '',
        oldRole: ''
      });
      if (user.user_id === loggedInUser.userID) {
        updateLoggedInUser({
          email,
          role,
          firstName: fname,
          lastName: lname
        });
      }
    });
  }

  /**
   * Select or unselect the row when the user clicks on the checkbox.
   */
  select() {
    const { user, select } = this.props;
    select(user.user_id);
  }

  /**
   * Renders component
   * @returns {*} - DOM description
   */
  render() {
    const {
      user, roles, isSelected, loggedInUser
    } = this.props;

    const {
      fname, lname, email, role, editing, error
    } = this.state;

    const id = user.user_id;
    // is this the logged in user
    const isUser = user.user_id === loggedInUser.userID;
    // is user same or higher permission level
    const canEdit = loggedInUser.role < user.role_id;

    if (editing) {
      const selectedRole = roles.filter((r) => r.id === role);
      const roleName = selectedRole[0].name;
      return (
        <tr className="UserProfile">
          <td className="UserProfile_check">
            <div className="UserProfile_data">
              {!isUser && role !== 5 && (
                <Checkbox
                  id={id}
                  checked={isSelected}
                  onClick={this.select}
                  type="secondary"
                  effect
                />
              )}
            </div>
          </td>
          <td className="UserProfile_col">
            <div className="UserProfile_data">
              <TextField
                id="firstName"
                type="text"
                autoComplete="family-name"
                size="small"
                variant="outlined"
                color="primary"
                fullWidth
                inputProps={{ className: 'UserProfile_input' }}
                value={fname}
                onChange={this.fnameChanged}
                error={!!(error && error['first name'])}
                helperText={error && error['first name'] ? error['first name'] : ''}
              />
            </div>
          </td>
          <td className="UserProfile_col">
            <div className="UserProfile_data">
              <TextField
                id="lastName"
                type="text"
                autoComplete="given-name"
                size="small"
                variant="outlined"
                color="primary"
                fullWidth
                inputProps={{ className: 'UserProfile_input' }}
                value={lname}
                onChange={this.lnameChanged}
                error={!!(error && error['last name'])}
                helperText={error && error['last name'] ? error['last name'] : ''}
              />
            </div>
          </td>
          <td className="UserProfile_col">
            <div className="UserProfile_data">
              <TextField
                id="email"
                type="email"
                autoComplete="email"
                size="small"
                variant="outlined"
                color="primary"
                fullWidth
                inputProps={{ className: 'UserProfile_input' }}
                value={email}
                onChange={this.emailChanged}
                error={!!(error && error.email)}
                helperText={error && error.email ? error.email : ''}
                disabled
              />
            </div>
          </td>
          <td className="UserProfile_role">
            <div className="UserProfile_data">
              {user.role_id === 5 || loggedInUser.role !== 5
                ? (role && roles.filter((r) => (r.id === role))[0].name)
                : (
                  <select
                    value={roleName}
                    onChange={this.roleChanged}
                    disabled={user.role_id === 5}
                  >
                    { roles.filter((rle) => (
                      rle.id !== 5 && rle.id > loggedInUser.role)).map((rle) => (
                        <option key={rle.id}>{rle.name}</option>
                    ))}
                  </select>
                )}
            </div>
          </td>
          <td className="UserProfile_buttons">
            <div className="UserProfile_data">
              <Button
                onClick={this.cancel}
                variant="primary-inverse"
                customVariant="UserProfile_button"
                customContent="Cancel"
              />
              <Button
                onClick={this.saveChanges}
                variant="primary-inverse"
                customVariant="UserProfile_button"
                customContent="Save Changes"
              />
            </div>
          </td>
        </tr>
      );
    }

    const roleDetails = roles.filter((r) => (r.id === role))[0];
    return (
      <tr className="UserProfile">
        <td className="UserProfile_check">
          <div className="UserProfile_data">
            {!isUser && canEdit && role !== 5 && (
              <Checkbox
                id={id}
                checked={isSelected}
                onClick={this.select}
                type="secondary"
                effect
              />
            )}
          </div>
        </td>
        <td className="UserProfile_col">
          <div className="UserProfile_data">
            {fname}
          </div>
        </td>
        <td className="UserProfile_col">
          <div className="UserProfile_data">
            {lname}
          </div>
        </td>
        <td className="UserProfile_col">
          <div className="UserProfile_data">
            {email}
          </div>
        </td>
        <td className="UserProfile_role">
          <div className="UserProfile_data">
            {role && roleDetails ? roleDetails.name : ''}
          </div>
        </td>
        <td className="UserProfile_buttons">
          <div className="UserProfile_data">
            <Button
              onClick={this.edit}
              variant="primary-inverse"
              customVariant="UserProfile_button"
              customContent="Edit User"
              disabled={(!canEdit && !isUser)}
            />
          </div>
        </td>
      </tr>
    );
  }
}

export default UserProfile;

UserProfile.propTypes = {
  user: Proptypes.shape({
    user_id: Proptypes.number,
    invitation_id: Proptypes.number,
    email: Proptypes.string,
    first_name: Proptypes.string,
    last_name: Proptypes.string,
    role_id: Proptypes.number
  }).isRequired,

  loggedInUser: Proptypes.shape({
    role: Proptypes.number,
    userID: Proptypes.number
  }).isRequired,
  updateLoggedInUser: Proptypes.func.isRequired,

  roles: Proptypes.arrayOf(Proptypes.shape({
    id: Proptypes.number,
    name: Proptypes.string
  })).isRequired,
  isSelected: Proptypes.bool.isRequired,
  select: Proptypes.func.isRequired
};
