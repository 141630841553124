import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const CompareSection = (props) => {
  const {
    searchResults,
    currentArticleId,
    setCompareIndex,
    articleIDs,
    setArticleSearch,
    setArticleSearchIndex,
    setArticleSearchList,
    setArticleSearchListLength,
  } = props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

  const compareList = articleIDs.map((id, index) => {
    const isActive = id === currentArticleId;
    const article = searchResults.answers.find((result) => result.id === id);

    return (
      <Box
        key={id}
        sx={{
          px: 2,
          backgroundColor: isActive ? 'white.main' : 'transparent',
          py: 1,
        }}
      >
        <Button
          variant="contained"
          color="white"
          sx={{
            textTransform: 'none',
            pl: '10px',
            pr: '10px',
            fontSize: '1rem',
            fontWeight: isActive ? 'bold' : 600,
            boxShadow: 'none',
            backgroundColor: isActive ? 'white.main' : grey[100],
            width: '100%',
            borderRadius: isActive ? '0' : '10px',
            justifyContent: 'flex-start',
            cursor: isActive ? 'default !important' : 'pointer',
            textAlign: 'left',
            display: 'block',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            '&:hover': {
              boxShadow: 'none',
              backgroundColor: 'white.main',
            },
          }}
          onClick={() => {
            if (!isActive) {
              setCompareIndex(index);
              setArticleSearch('');
              setArticleSearchIndex(undefined);
              setArticleSearchList({});
              setArticleSearchListLength(0);
            }
          }}
        >
          {article.title.length > 30 ? `${article.title.slice(0, 30)}...` : article.title}
        </Button>
      </Box>
    );
  });

  return (
    <Box
      pt={isMobile ? 1 : 6.75}
      sx={{
        displayPrint: 'none',
      }}
    >
      <Box
        pb={{ xs: 2, lg: 3 }}
        px={2}
      >
        <Typography
          sx={{
            fontSize: '2rem',
            fontWeight: 'bold',
          }}
        >
          Compare
        </Typography>
      </Box>
      <Box>{compareList}</Box>
    </Box>
  );
};

CompareSection.propTypes = {
  searchResults: PropTypes.shape({
    answers: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        title: PropTypes.string,
      })
    ),
  }).isRequired,
  currentArticleId: PropTypes.string.isRequired,
  setCompareIndex: PropTypes.func.isRequired,
  setArticleSearch: PropTypes.func.isRequired,
  setArticleSearchIndex: PropTypes.func.isRequired,
  setArticleSearchList: PropTypes.func.isRequired,
  setArticleSearchListLength: PropTypes.func.isRequired,
  articleIDs: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default CompareSection;
