import React from 'react';
import * as Proptypes from 'prop-types';
import {
  Dialog, DialogTitle, DialogContent, Box, Button, DialogActions
} from '@mui/material';
import { grey } from '@mui/material/colors';

/**
 * A modal that alerts the user to some imporant piece of information.
 * @param {object} props - defined in proptypes
 * @returns {*} - modal pop up
 * @class
 */
const AlertModal = (props) => {
  const {
    open, handleClose, title, children, buttonText, bodyPadding, maxWidth
  } = props;
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={maxWidth}
      PaperProps={{
        sx: {
          borderRadius: '16px',
        },
      }}
    >
      <DialogTitle
        id="alert-dialog-title"
        sx={{
          fontSize: '2rem',
          backgroundColor: grey[200],
          textAlign: 'center',
          py: 3,
          px: 2,
        }}
      >
        {title}
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            fontSize: '1.2rem',
            textAlign: 'center',
            pt: 5,
            px: bodyPadding,
            pb: 1,
            color: 'black.main',
          }}
          id="alert-dialog-description"
        >
          {children}
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: 'center',
          pb: 5,
        }}
      >
        <Button
          onClick={handleClose}
          variant="contained"
          color="secondary"
          sx={{
            padding: '10px 40px',
            fontSize: '1rem',
            borderRadius: '8px',
          }}
          autoFocus
        >
          {buttonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AlertModal;

AlertModal.propTypes = {
  open: Proptypes.bool.isRequired,
  title: Proptypes.string.isRequired,
  buttonText: Proptypes.string.isRequired,
  handleClose: Proptypes.func.isRequired,
  children: Proptypes.element.isRequired,
  bodyPadding: Proptypes.number,
  maxWidth: Proptypes.oneOf([
    'sm',
    'md',
    'lg'
  ])
};

AlertModal.defaultProps = {
  bodyPadding: 9,
  maxWidth: 'sm'
};
