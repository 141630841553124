import axios from 'axios';
import { useRef, useEffect } from 'react';

const useCancelToken = () => {
  const axiosSource = useRef(axios.CancelToken.source());
  const isCancel = axios.isCancel;

  useEffect(
    () => () => {
      axiosSource.current.cancel();
    },
    []
  );

  return { cancelToken: axiosSource.current.token, isCancel };
};

export default useCancelToken;
