import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';
import * as Proptypes from 'prop-types';
import Spinner from 'react-bootstrap/Spinner';
import { clearRedux } from '../../actions/general';
import { thirdPartyLogin } from '../../actions/authentication/authentication';
import Alert from '../Alert/Alert';
import './ThirdPartyRegistration.scss';

/**
 * This component controls the add user form and the number of licenses information.
 */
class ThirdPartyRegistration extends Component {
  /**
   * Initializes component
   * @param {object} props - defined in proptypes
   */
  constructor(props) {
    super(props);
    this.state = {
      error: '',
      registered: false,
    };
    this.loginEmbed = this.loginEmbed.bind(this);
  }

  /**
   * Trigger 3rd party login process. Special case for mars embed.
   */
  componentDidMount() {
    const { credentials, user } = this.props;

    if (user.errorMsg) {
      this.setState({
        error: user.errorMsg
      });
    } else if ((credentials && credentials.third_party_token && credentials.user_details.user_id)) {
      this.loginEmbed();
    } else {
      this.setState({
        error: 'Not enough information to log the user in!'
      });
    }
  }

  /**
   * Handles logging in non mars 3rd party users.
   * Either triggers the login process if only given an email and password else saves their creds.
   */
  loginEmbed() {
    const {
      credentials, user, login, resetRedux
    } = this.props;
    const token = user.token;

    // app component will have already verified if token is alive
    if (token) {
      if (user.userID !== credentials.user_details.user_id) {
        resetRedux();
        login(credentials.third_party_token, credentials.user_details.user_id);
      }
      // user IDs match use the current data in Redux (old session).
      this.setState({
        registered: true,
      });
    } else {
      login(credentials.third_party_token, credentials.user_details.user_id);
      this.setState({
        registered: true,
      });
    }
  }

  /**
   * Renders component
   * @returns {*} - DOM description
   */
  render() {
    const { user, credentials, auth } = this.props;
    const { error, registered } = this.state;

    // ensure redux matches credentials
    const loggedIn = user.userID && credentials.user_details.user_id
      && user.userID === credentials.user_details.user_id;

    if (error || auth.errorMsg) {
      return (
        <div className="ThirdPartyRegistration">
          <Alert
            show
            customClass={{
              root: 'ThirdPartyRegistration_error',
              icon: 'ThirdPartyRegistration_error_icon',
              message: 'ThirdPartyRegistration_error_message'
            }}
          >
            {error || auth.errorMsg}
          </Alert>
        </div>
      );
    }

    if (registered && loggedIn) {
      if (user.agreementAgreed) {
        return (<Redirect to="/ask" />);
      }
      return (<Redirect to="/terms-and-conditions" />);
    }

    return (
      <div className="ThirdPartyRegistration">
        <div className="ThirdPartyRegistration_title">
          Authenticating
        </div>
        <div className="ThirdPartyRegistration_loader">
          <Spinner animation="border" />
        </div>
      </div>
    );
  }
}

/**
 * Maps items from the redux store to apps props.
 * @param {object} state - des
 * @returns {{user: *}} - maps user from redux to apps props
 */
function mapStateToProps(state) {
  return {
    user: state.user,
    auth: state.auth
  };
}

/**
 * Maps actions to component props.
 * @param {Dispatch} dispatch - allows action creators to work with redux.
 * @returns {{logout: *, authToken: *, login: *}} - bound action creators
 */
function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    login: thirdPartyLogin,
    resetRedux: clearRedux,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ThirdPartyRegistration);

ThirdPartyRegistration.propTypes = {
  login: Proptypes.func.isRequired,
  resetRedux: Proptypes.func.isRequired,
  user: Proptypes.shape({
    email: Proptypes.string,
    errorMsg: Proptypes.string,
    agreementAgreed: Proptypes.bool,
    userID: Proptypes.number,
    token: Proptypes.string
  }).isRequired,
  auth: Proptypes.shape({
    errorMsg: Proptypes.string
  }).isRequired,

  credentials: Proptypes.shape({
    error: Proptypes.string,
    access_token: Proptypes.string,
    third_party_token: Proptypes.string,
    registered: Proptypes.bool,
    remember_me: Proptypes.bool,
    user_details: Proptypes.shape({
      user_id: Proptypes.number,
      first_name: Proptypes.string,
      last_name: Proptypes.string,
      email: Proptypes.string,
      account_id: Proptypes.number,
      password: Proptypes.string,
      role_id: Proptypes.number,
      agreement_agreed: Proptypes.bool
    })
  })
};

ThirdPartyRegistration.defaultProps = {
  credentials: null,
};
