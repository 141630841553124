import React from 'react';
import Proptypes from 'prop-types';
import { Box, Typography, Button } from '@mui/material';

const LoginError = (props) => {
  const { error } = props;
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
      }}
    >
      <Box
        mt={4}
      >
        <Typography
          sx={{
            fontSize: '3rem',
            fontWeight: 500,
          }}
        >
          Oops something went wrong!
        </Typography>
        <Typography
          sx={{
            fontSize: '1.5rem',
            fontWeight: 500,
            marginTop: 3
          }}
        >
          {error}
        </Typography>
        <Typography
          sx={{
            fontSize: '1rem',
            marginTop: 3
          }}
        >
          Please use the below button to attempt to sign in again.
        </Typography>
        <Box
          mt={1}
          display="flex"
          justifyContent="center"
        >
          <Button onClick={() => { window.location.reload(); }} variant="contained" color="secondary">
            Sign In
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default LoginError;

LoginError.propTypes = {
  error: Proptypes.string.isRequired,
};
