import { AUTHENTICATE_TOKEN_REQUEST, AUTHENTICATE_TOKEN_SUCCESS, AUTHENTICATE_TOKEN_FAILURE } from '../actions';

// ===== ACTION CREATORS =====
/**
 * Action creator for authenticating token request
 * @returns {object} - action
 */
export function authTokenRequest() {
  return {
    type: AUTHENTICATE_TOKEN_REQUEST
  };
}

/**
 * Action creator for authenticating token success
 * @returns {object} - action
 */
export function authTokenSuccess() { // do nothing
  return {
    type: AUTHENTICATE_TOKEN_SUCCESS
  };
}

/**
 * Action creator for authenticating token success
 * @returns {object} - action
 */
export function authTokenFailure() { // do nothing
  return {
    type: AUTHENTICATE_TOKEN_FAILURE
  };
}
