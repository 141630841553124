import React from 'react';
import * as Proptypes from 'prop-types';
import Modal from 'react-modal';
import Button from '../../Button/Button';
import './ConfirmationModal.scss';

// This helps make the modal accessable.
Modal.setAppElement(document.getElementById('sofie-embed'));

/**
 * A modal asking the user to confirm an action.
 * If the user confirms the action should be executed.
 * On any other action the modal should be closed and the action cancelled.
 * @param {object} props - defined in proptypes
 * @returns {*} - modal pop up
 * @class
 */
const ConfirmationModal = (props) => {
  const {
    isOpen, message, onCancel, onConfirm, title
  } = props;
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onCancel}
      contentLabel="Confirmation Modal"
      className="ConfirmationModal"
      overlayClassName="ConfirmationModal_overlay"
      bodyOpenClassName="ConfirmationModal_parent"
    >
      <>
        <div className="ConfirmationModal_header">
          <div className="ConfirmationModal_header_title">{title}</div>
        </div>
        <div className="ConfirmationModal_body">
          <div className="ConfirmationModal_body_message">{message}</div>
        </div>
        <div className="ConfirmationModal_footer">
          <Button
            onClick={onCancel}
            customContent="Cancel"
            variant="grey"
            height="3rem"
            width="10rem"
          />
          <Button
            onClick={onConfirm}
            customContent="Confirm"
            variant="errorDark-darken"
            height="3rem"
            width="10rem"
          />
        </div>
      </>
    </Modal>
  );
};

export default ConfirmationModal;

ConfirmationModal.propTypes = {
  isOpen: Proptypes.bool.isRequired,
  title: Proptypes.string.isRequired,
  message: Proptypes.string.isRequired,
  onCancel: Proptypes.func.isRequired,
  onConfirm: Proptypes.func.isRequired
};
