/**
 * @param {synonmys} synonmys - reference to the array of keywords
 * @returns Void
 */

/**
 * @param synonmys
 */
export default function SynonmyHighLighter(synonmys) {
  const elem = document.getElementById('viewArticles');
  const instance = new Mark(elem);
  const options = {
    element: 'synonmys',
    "separateWordSearch": false,
    "caseSensitive": false,
    "accuracy": "exactly"
  };

  instance.mark(synonmys, options);
}
