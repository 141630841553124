import React from 'react';
import classNames from 'classnames';
import * as Proptypes from 'prop-types';
import Spinner from 'react-bootstrap/Spinner';
import './LoadingPage.scss';

/**
 * Generates the loading page that appears until a page is ready to be shown.
 * @param {object} props - defined in proptypes
 * @returns {*} - loading screen
 * @class
 */
const LoadingPage = (props) => {
  const { title } = props;

  return (
    <div className="loadingPage">
      <div className="loadingPage_title">
        {title}
      </div>
      <div className="loadingPage_loader">
        <Spinner
          bsPrefix={classNames('spinner-border', 'loadingPage_spinner')}
          animation="border"
        />
      </div>
    </div>
  );
};

export default LoadingPage;

LoadingPage.propTypes = {
  title: Proptypes.string.isRequired,
};
