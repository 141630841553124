import React, { useState } from 'react';
import ReactGA from 'react-ga';
import ReactGA4 from 'react-ga4';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import StarOutlineRoundedIcon from '@mui/icons-material/StarOutlineRounded';
import MailOutlineRoundedIcon from '@mui/icons-material/MailOutlineRounded';
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';
import { Box, IconButton, Tooltip } from '@mui/material';
import { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { grey } from '@mui/material/colors';
import InfoIcon from '@mui/icons-material/Info';
import {
  addtoFavourite,
  removeFavourite,
  openFavoriteConfirmationModal,
} from '../../actions/addFavourite/addFavourite';

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
    classes={{ popper: className }}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: grey[600],
    color: theme.palette.common.white,
    boxShadow: theme.shadows[5],
    fontSize: '1rem',
    fontWeight: 'bold',
    padding: '16px',
    borderRadius: '16px',
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: grey[600],
  },
}));

const ArticleButtons = (props) => {
  const {
    favourites,
    id,
    dispatchAddtoFavourite,
    dispatchRemoveFavourite,
    handleShareArticleClick,
    showPrint,
    size,
    showShare,
    setAddedFav,
    information,
    dispatchOpenFavoriteConfirmationModal,
    popupRequired,
    title,
  } = props;

  const [open, setOpen] = useState(false);

  const spacer = {
    mx: 1,
    display: 'inline-block',
  };

  const icon = {
    fontSize: `${size}rem`,
    cursor: 'pointer',
  };

  let isFavourite = false;
  if (favourites.includes(id)) {
    isFavourite = true;
  }

  const handlePrint = () => {
    ReactGA.event({
      category: 'article-view',
      action: 'Print',
    });
    ReactGA4.event('print-article');
    window.print();
  };

  const handleToolTip = () => {
    setOpen(!open);
  };

  return (
    <Box
      sx={{
        whiteSpace: 'nowrap',
        displayPrint: 'none',
        mr: 3.1875,
      }}
    >
      {information && (
        <LightTooltip
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title={information}
          onClick={handleToolTip}
          arrow
        >
          <IconButton aria-label={information}>
            <InfoIcon color="success" />
          </IconButton>
        </LightTooltip>
      )}
      {isFavourite ? (
        <IconButton
          onClick={() => {
            if (popupRequired) {
              dispatchOpenFavoriteConfirmationModal(id, title);
            } else {
              dispatchRemoveFavourite(id);
            }
          }}
          sx={{
            py: { xs: 0, lg: 1 },
          }}
        >
          <StarRoundedIcon
            sx={icon}
            color="secondary"
          />
        </IconButton>
      ) : (
        <IconButton
          onClick={() => {
            dispatchAddtoFavourite(id);
            setAddedFav(true);
          }}
          sx={{
            py: { xs: 0, lg: 1 },
          }}
        >
          <StarOutlineRoundedIcon sx={icon} />
        </IconButton>
      )}
      {showShare ? (
        <>
          <Box sx={spacer} />
          <IconButton onClick={handleShareArticleClick}>
            <MailOutlineRoundedIcon sx={{ fontSize: `${size - 0.38}rem`, cursor: 'pointer' }} />
          </IconButton>
        </>
      ) : (
        <></>
      )}
      {showPrint ? (
        <>
          <Box sx={spacer} />
          <IconButton onClick={handlePrint}>
            <PrintOutlinedIcon sx={icon} />
          </IconButton>
        </>
      ) : (
        <></>
      )}
    </Box>
  );
};

/**
 * Maps items from the redux store to apps props.
 * @param {object} state - des
 * @returns {{ user: *}} - maps user from redux to apps props
 */
function mapStateToProps(state) {
  return {
    favourites: state.user.favorites,
  };
}

/**
 * Maps actions to component props.
 * @param {Dispatch} dispatch - allows action creators to work with redux.
 * @returns {{ setSelectedArticles: *}} - bound action creators
 */
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      dispatchAddtoFavourite: addtoFavourite,
      dispatchRemoveFavourite: removeFavourite,
      dispatchOpenFavoriteConfirmationModal: openFavoriteConfirmationModal,
    },
    dispatch
  );
}

ArticleButtons.propTypes = {
  id: PropTypes.string.isRequired,
  favourites: PropTypes.arrayOf(PropTypes.string).isRequired,
  dispatchAddtoFavourite: PropTypes.func.isRequired,
  setAddedFav: PropTypes.func,
  dispatchOpenFavoriteConfirmationModal: PropTypes.func.isRequired,
  dispatchRemoveFavourite: PropTypes.func.isRequired,
  handleShareArticleClick: PropTypes.func.isRequired,
  showPrint: PropTypes.bool,
  showShare: PropTypes.bool,
  popupRequired: PropTypes.bool,
  size: PropTypes.number,
  information: PropTypes.string,
  title: PropTypes.string,
};
ArticleButtons.defaultProps = {
  showPrint: true,
  showShare: true,
  size: 2.18,
  setAddedFav: () => {},
  information: '',
  popupRequired: false,
  title: '',
};

export default connect(mapStateToProps, mapDispatchToProps)(ArticleButtons);
