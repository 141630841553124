import React from 'react';
import { Typography } from '@mui/material';

const Logo = () => (
  <>
    <Typography
      sx={{
        fontSize: '3.4375rem',
        lineHeight: 1,
        color: 'lightBlue.main',
      }}
    >
      Sofie
    </Typography>
    <Typography
      sx={{
        fontSize: '1.25rem',
        opacity: 0.59,
        color: 'lightBlue.main',
      }}
    >
      Medical Search Tool
    </Typography>
  </>
);

export default Logo;
