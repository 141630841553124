import React, { createRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Box } from '@mui/material';
import './ArticleBody.scss';
import Synonmy from '../../helpers/Synonmy';
import SynonmyHighLighter from '../../helpers/SynonmyHighLighter';
import Highlighter from '../../helpers/Highlighter';

const ArticleBody = (props) => {
  const {
    body,
    highlighting,
    articleSearch,
    setArticleSearchList,
    articleSearchIndex,
    setArticleSearchIndex,
    setArticleSearchListLength,
  } = props;

  const articleRef = createRef();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

  useEffect(() => {
    if (highlighting) {
      const synonyms = Synonmy(highlighting);
      Highlighter(articleRef.current, highlighting);
      SynonmyHighLighter(synonyms);
    }
  }, [body]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      let index = 0;
      const rootElement = document.querySelector('#article_innerHTML_content');
      // eslint-disable-next-line no-undef
      const instance = new Mark(rootElement);

      if (articleSearch !== '') {
        const markOptions = {
          done: () => {
            const elm = document.getElementById('article_innerHTML_content');
            setArticleSearchList(elm.getElementsByTagName('mark'));
            setArticleSearchListLength(elm.getElementsByTagName('mark').length);
            setArticleSearchIndex(undefined);
          },
          each: (element) => {
            element.classList.add(`mark${index}`);
            index += 1;
          },
          exclude: ['h1'],
        };

        const unMarkOptions = {
          done: () => {
            instance.mark(articleSearch, markOptions);
          }
        };
        instance.unmark(unMarkOptions);
      } else {
        instance.unmark();
      }
    }, 1000);
    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [articleSearch]);

  useEffect(() => {
    if (articleSearchIndex !== undefined) {
      const className = `mark${articleSearchIndex}`;
      const yOffset = isMobile ? -120 : -200;
      const element = document.getElementsByClassName(className)[0];
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  }, [articleSearchIndex]);

  let sanitizedBody;
  // remove links from the body that don't point to another website.
  sanitizedBody = body.replace(
    /<a\s[^>]*href\s*=\s*"(?!https?:\/\/)[^"]*"[^>]*>.*?<\/a>/gi,
    (match) => match.replace(/<a\b[^>]*>/gi, '').replace(/<\/a>/gi, '')
  );
  // remove links for items that point to valid sites but don't have the site in the link text.
  sanitizedBody = body.replace(/<a[\s]+([^>]+)>(?!https?:\/\/|www\.).*?<\/a>/gi, (match) => match.replace(/<a\b[^>]*>/gi, '').replace(/<\/a>/gi, ''));

  return (
    <Box
      pr={isMobile ? 2 : 7}
      pl={isMobile ? 2 : 0}
      ref={articleRef}
      className="ArticleBody_content"
      id="article_innerHTML_content"
      dangerouslySetInnerHTML={{
        __html: sanitizedBody,
      }}
      sx={{
        '& mark': {
          padding: 0,
          background: 'yellow',
        },
        [`& .mark${articleSearchIndex}`]: {
          background: 'orange !important',
        },
      }}
    />
  );
};

ArticleBody.propTypes = {
  body: PropTypes.string.isRequired,
  highlighting: PropTypes.string,
  articleSearch: PropTypes.string.isRequired,
  setArticleSearchList: PropTypes.func.isRequired,
  setArticleSearchListLength: PropTypes.func.isRequired,
  articleSearchIndex: PropTypes.number,
  setArticleSearchIndex: PropTypes.func.isRequired,
  articleSearchList: PropTypes.shape({}).isRequired
};

ArticleBody.defaultProps = {
  articleSearchIndex: undefined,
  highlighting: undefined
};

export default ArticleBody;
