import {
  LOGIN_SUCCESS,
  LOGOUT_COMPLETE,
  LOGOUT_INVALID_TOKEN,
  ACCEPT_AGREEMENT_SUCCESS,
  UPDATE_USER_DETAILS,
  COMPLETED_SURVEY_SUCCESS,
  SEEN_EXPIRY_WARNING,
  STORE_UUID,
  RESET_REDUX,
  AUTHENTICATE_TOKEN_FAILURE,
  FAVOURITE_REMOVE_SUCCESS,
  FAVOURITE_ADD_SUCCESS,
  SAW_POPUP_SUCCESS,
  DISMISS_POPUP,
} from '../../actions/actions';
import * as actions from '../../actions/actions';

const INIT_STATE = {
  userID: null,
  firstName: '',
  lastName: '',
  email: '',
  accountID: null,
  companyID: null,
  role: null,
  agreementAgreed: false,
  bestPracticesIgnored: false,
  token: '',
  tokenExpiry: 0,
  universityId: '',
  tookSurvey: false,
  expiresIn: null,
  expiryWarning: true,
  azureUUID: '',
  azureUUIDExpiry: 0,
  favorites: [],
  updatePopupSeen: false,
  updatePopupSeenSession: false,
};

const user = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS: {
      window.localStorage.setItem('sofie_version', action.payload.user_details.app_version);
      return {
        ...state,
        userID: action.payload.user_details.user_id,
        firstName: action.payload.user_details.first_name,
        lastName: action.payload.user_details.last_name,
        email: action.payload.user_details.email,
        accountID: action.payload.user_details.account_id,
        companyID: action.payload.user_details.company_id,
        role: action.payload.user_details.role_id,
        agreementAgreed: action.payload.user_details.agreement_agreed,
        bestPracticesIgnored: action.payload.user_details.best_practices_ignore,
        token: action.payload.access_token,
        tokenExpiry: action.payload.access_token_expiry,
        universityId: action.payload.user_details.university_id,
        tookSurvey: action.payload.user_details.survey_gismo_accepted,
        expiresIn: action.payload.user_details.expires_in,
        createdDate: action.payload.user_details.created_date,
        favorites: action.payload.user_details.favorites,
        updatePopupSeen: action.payload.user_details.update_popup_seen,
        expiryWarning: false,
      };
    }

    case STORE_UUID:
      return {
        ...state,
        azureUUID: action.payload,
        azureUUIDExpiry: Math.floor(new Date().getTime() / 1000) + 86400,
      };

    case FAVOURITE_REMOVE_SUCCESS:
      return {
        ...state,
        favorites: action.payload.favorites,
      };

    case FAVOURITE_ADD_SUCCESS:
      return {
        ...state,
        favorites: action.payload.favorites,
      };

    case ACCEPT_AGREEMENT_SUCCESS:
      return {
        ...state,
        agreementAgreed: true,
      };

    case COMPLETED_SURVEY_SUCCESS: {
      return {
        ...state,
        tookSurvey: true,
      };
    }

    case SEEN_EXPIRY_WARNING: {
      return {
        ...state,
        expiryWarning: true,
      };
    }

    case UPDATE_USER_DETAILS:
      return {
        ...state,
        ...action.payload,
      };

    case actions.BEST_PRACTICES_SUCCESS:
      return {
        ...state,
        bestPracticesIgnored: true,
      };

    case AUTHENTICATE_TOKEN_FAILURE:
      return {
        ...state,
        token: '',
        tokenExpiry: 0,
      };
    case SAW_POPUP_SUCCESS:
      return {
        ...state,
        updatePopupSeen: true,
      };

    case DISMISS_POPUP:
      return {
        ...state,
        updatePopupSeenSession: true,
      };

    case LOGOUT_INVALID_TOKEN:
      return {
        ...state,
        userID: null,
        firstName: '',
        lastName: '',
        email: '',
        accountID: null,
        companyID: null,
        role: null,
        agreementAgreed: false,
        bestPracticesIgnored: false,
        token: '',
        tokenExpiry: 0,
        universityId: '',
        tookSurvey: false,
        expiresIn: null,
        expiryWarning: true,
        favorites: [],
        updatePopupSeenSession: false,
      };
    case LOGOUT_COMPLETE:
    case RESET_REDUX:
      return INIT_STATE;

    default:
      return state;
  }
};

export default user;
