import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import Proptypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useMsal } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';
import { loginRequest } from '../../AuthConfig';
import { storeUUID } from '../../actions/authentication/authentication';
import LoadingPage from '../LoadingPage/LoadingPage';

const AzureLogin = (props) => {
  const {
    user, saveUUID
  } = props;
  const { inProgress, instance } = useMsal();

  const azureAuthenticationInProgress = inProgress !== InteractionStatus.None;

  const now = Math.floor(new Date().getTime() / 1000);

  const isActiveAccount = !!instance.getActiveAccount();
  const isUuidValid = (user.azureUUID && (user.azureUUIDExpiry - now) > 0);

  useEffect(() => {
    if (!azureAuthenticationInProgress) {
      if (isActiveAccount && !isUuidValid) {
        saveUUID(instance.getActiveAccount().localAccountId);
      } else if (!isUuidValid) {
        instance.acquireTokenRedirect(loginRequest);
      }
    }
  }, [inProgress]);

  if (!isActiveAccount && !isUuidValid) {
    return (
      <LoadingPage title="Re-directing to login form." />
    );
  }

  if (isUuidValid) {
    return (
      <Redirect to="/login" />
    );
  }

  return (
    <LoadingPage title="Checking Azure Account." />
  );
};

/**
 * Maps items from the redux store to apps props.
 * @param {object} state - des
 * @returns {{user: *}} - maps user from redux to apps props
 */
const mapStateToProps = (state) => ({
  user: state.user,
});

/**
 * Maps actions to component props.
 * @param {Dispatch} dispatch - allows action creators to work with redux.
 * @returns {{logout: *, authToken: *, login: *}} - bound action creators
 */
const mapDispatchToProps = (dispatch) => bindActionCreators({
  saveUUID: storeUUID,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AzureLogin);

AzureLogin.propTypes = {
  user: Proptypes.shape({
    token: Proptypes.string,
    tokenExpiry: Proptypes.number,
    azureUUID: Proptypes.string,
    azureUUIDExpiry: Proptypes.number,
  }).isRequired,
  saveUUID: Proptypes.func.isRequired,
};

AzureLogin.defaultProps = {};
