import React, { useState, useEffect, createRef } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import AuthRequest from '../../api/AuthRequest';
import PublicArticleHeader from './PublicArticleHeader';
import ScrollWrapper from '../../components/ScrollWrapper/ScrollWrapper';
import './PublicArticle.scss';
import LoadingPage from '../../components/LoadingPage/LoadingPage';

const PublicArticle = () => {
  const [data, setData] = useState({
    loading: true,
    error: '',
    article: {}
  });
  const [headerStuck, setHeaderStuck] = useState(false);

  const params = useParams();
  const headerRef = createRef();

  useEffect(() => {
    AuthRequest({
      method: 'post',
      url: '/get-public-article',
      data: {
        code: params.code,
      },
    }).then((res) => {
      let error = '';
      let article = {};
      if (res.data.message === 'Article link is expired') {
        error = res.data.message;
      } else {
        article = res.data.message;
      }
      setData({
        loading: false,
        error,
        article,
      });
    });
  }, []);

  useEffect(() => {
    if (headerRef.current) {
      const cachedRef = headerRef.current;
      const observer = new IntersectionObserver(
        ([e]) => setHeaderStuck(e.intersectionRatio < 1),
        {
          threshold: [1],
        }
      );
      observer.observe(cachedRef);
      return () => observer.unobserve(cachedRef);
    }
    return () => false;
  }, [headerRef]);

  if (data.loading) {
    return (
      <>
        <PublicArticleHeader />
        <LoadingPage title="Loading Article..." />
      </>
    );
  }

  if (data.error) {
    return (
      <>
        <PublicArticleHeader />
        <Typography
          sx={{
            fontSize: '2rem',
            fontWeight: 'bold',
            textAlign: 'center',
            pt: 7
          }}
        >
          {data.error}
        </Typography>
      </>
    );
  }

  let sanitizedBody = '';
  if (data.article) {
    // remove links from the body that don't point to another website.
    sanitizedBody = data.article.answerbody.replace(
      /<a\s[^>]*href\s*=\s*"(?!https?:\/\/)[^"]*"[^>]*>.*?<\/a>/gi,
      (match) => match.replace(/<a\b[^>]*>/gi, '').replace(/<\/a>/gi, '')
    );
    // remove links for items that point to valid sites but don't have the site in the link text.
    sanitizedBody = sanitizedBody.replace(/<a[\s]+([^>]+)>(?!https?:\/\/|www\.).*?<\/a>/gi, (match) => match.replace(/<a\b[^>]*>/gi, '').replace(/<\/a>/gi, ''));
  }

  return (
    <>
      <Box id="back-to-top-anchor" />
      <Box
        sx={{
          position: 'sticky',
          backgroundColor: 'white.main',
          borderBottom: headerStuck ? '1px solid' : 'none',
          zIndex: 1000,
          top: -1,
          paddingTop: { xs: 0, lg: '33px' },
          '@media print': {
            position: 'relative',
          },
        }}
        pb={3}
        ref={headerRef}
      >
        <PublicArticleHeader
          title={data.article.title}
          book={data.article.bookInfo[0].book}
          year={data.article.bookInfo[0].year}
          publisherName={data.article.bookInfo[0].publisher_name}
          publisherWebsite={data.article.bookInfo[0].website}
        />
      </Box>
      <Grid
        container
        spacing={{ xs: 0, lg: 4 }}
      >
        <Grid
          item
          lg={2}
          sx={{
            display: { xs: 'none', lg: 'block' },
          }}
        />
        <Grid
          item
          xs={12}
          lg={8}
        >
          <Box
            pr={{ xs: 2, lg: 7 }}
            pl={{ xs: 2, lg: 0 }}
            className="PublicArticle_content"
            dangerouslySetInnerHTML={{
              __html: sanitizedBody,
            }}
          />
        </Grid>
      </Grid>
      <ScrollWrapper />
    </>
  );
};

PublicArticle.propTypes = {};

export default PublicArticle;
