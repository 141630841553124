import React from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import invalidToken from '../actions/authentication/invalidToken';
import { store } from '../reducers/store';
import Notification from '../components/Notification/Notification';

/**
 * Injects the base url and authentication token into axios requests
 * example get request to http://localhost:36080/ask API passing 'rabies' as a question:
 * AuthRequest({
        method: 'get',
        url: 'ask',
        params: {
          nlq: 'rabies'
        }
      })
 * @type AxiosInstance
 */
const AuthRequest = axios.create({
  baseURL: `${UM_API}v3/`,
});

/**
 * Injects error handling to all requests using this class
 */
const { dispatch, getState } = store;

AuthRequest.interceptors.request.use((config) => {
  const token = store.getState()?.user?.token;
  const newConfig = {
    ...config,
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    },
  };
  return newConfig;
});

AuthRequest.interceptors.response.use((response) => response, (error) => {
  // if user cancelled call just return the error.
  if (axios.isCancel(error)) {
    return Promise.reject(error);
  }

  const { status, data } = error.response;
  // if 401 and user is logged in log the user out.
  if (status === 401) {
    const token = getState().user.token;
    if (token) {
      AuthRequest.defaults.headers.common.Authorization = '';
      dispatch(invalidToken());
    }
    return Promise.reject(error);
  }
  // Other error return a notification.

  toast.error(
    <Notification
      body={data.message}
      title="Error!"
    />
  );
  return Promise.reject(error);
});

export default AuthRequest;
