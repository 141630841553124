import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';

const Title = (props) => {
  const { children, color, size } = props;
  return (
    <Typography
      sx={{
        color,
        fontSize: `${size}rem`,
        fontWeight: 'bold',
      }}
    >
      {children}
    </Typography>
  );
};

Title.propTypes = {
  children: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  size: PropTypes.number,
};

Title.defaultProps = {
  size: 1.0625,
};

export default Title;
