exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports

exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);"]);
exports.push([module.id, "@import url(https://use.fontawesome.com/releases/v5.7.2/css/all.css);"]);
// Module
exports.push([module.id, ".ThirdPartyRegistration{display:flex;flex-flow:column nowrap;flex:1 0 auto;margin:3rem 4rem}.ThirdPartyRegistration_title{display:flex;font-size:xx-large;align-self:center}.ThirdPartyRegistration_loader{margin-top:2rem;display:flex;align-self:center}.ThirdPartyRegistration_error{align-self:center;font-size:x-large;font-weight:bold;width:100%}.ThirdPartyRegistration_error_icon{width:25%;font-size:2.5rem}.ThirdPartyRegistration_error_message{width:100%}.ThirdPartyRegistration_video{align-self:center;margin-top:2rem;width:100%;max-width:1200px}.ThirdPartyRegistration_button{align-self:center;margin-right:4px;width:20rem;height:5rem;margin-top:4rem}\n", ""]);
// Exports
exports.locals = {
	"primaryColor": "#006281",
	"secondaryColor": "#f8992d"
};