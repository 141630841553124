import React from 'react';
import classNames from 'classnames';
import Spinner from 'react-bootstrap/Spinner';
import * as Proptypes from 'prop-types';
import './Button.scss';

/**
 * Either renders a pre-built button or a custom button using user content and classes.
 * Details regarding naming scheme for pre-built buttons can be found above the prop-type section.
 * @param {object} props - defined in proptypes
 * @returns {*} - Button
 * @class
 */
const Button = (props) => {
  const {
    onClick, variant, content, customVariant, customContent, disabled, width, height, squareButton,
    type, ariaLabel
  } = props;

  let borderRadius = '0.25rem';
  if (squareButton) {
    borderRadius = '';
  }

  /**
   * Determines and returns the contents of the button.
   * The default content is none.
   * @returns {string|{customContent: *}|*} - button contents
   */
  function contents() {
    if (disabled) {
      return customContent;
    }

    switch (content) {
      case 'arrow-right-hidden':
        return (
          <div className={`button-${content}-hover`}>
            {customContent}
            <i className={classNames('fas', 'fa-arrow-right', `button-${content}`)}>
              &nbsp;
            </i>
          </div>
        );
      case 'arrow-right':
        return (
          <div className={`button-${content}-hover`}>
            {customContent}
            <i className={classNames('fas', 'fa-arrow-right', `button-${content}`)}>
              &nbsp;
            </i>
          </div>
        );
      case 'spinner':
        return (
          <div>
            {customContent}
            <span className={`button-${content}`}>
              <Spinner as="span" animation="border" size="sm" />
            </span>
          </div>
        );
      // no content
      default:
        return customContent;
    }
  }

  /**
   * Determines if a custom disabled or pre-built button is being generated.
   * This eslint rule doesn't work with variables. Disabled it since it is being fulfilled.
   */
  /* eslint-disable react/button-has-type */
  if ('custom'.localeCompare(variant) === 0) {
    return (
      <button
        type={type}
        className={customVariant}
        style={{ width, height, borderRadius }}
        onClick={onClick}
        disabled={disabled}
        aria-label={ariaLabel}
      >
        {contents()}
      </button>
    );
  }

  if (disabled) {
    return (
      <button
        type={type}
        className={classNames('button_disabled', customVariant)}
        style={{ width, height, borderRadius }}
        disabled={disabled}
        aria-label={ariaLabel}
      >
        {contents()}
      </button>
    );
  }

  return (
    <button
      type={type}
      className={classNames(`button_${variant}`, customVariant)}
      style={{ width, height, borderRadius }}
      onClick={onClick}
      disabled={disabled}
      aria-label={ariaLabel}
    >
      {contents()}
    </button>
  );
};

export default Button;

/**
 * Type is reference the the types of pre-built buttons:
 * For TYPE:
 * The first word is the color scheme of the button. ex. primary Default primary
 * The second word is how the colours are applied.   ex. outline Default solid
 * The third word is the on hover affect.            ex. darken  Default none
 *
 * Content is referencing a pre-made class that goes in the button.
 *
 * Custom content is user defined content such as a string or another component.
 * Custom type is the class name for the custom class made by the user.
 * If variant is custom then only the user defined class be be applied.
 * Otherwise both the pre-defined class and the custom class(es) will be defined.
 */
Button.propTypes = {
  onClick: Proptypes.func.isRequired,
  variant: Proptypes.oneOf([
    'primary', 'primary-darken', 'primary-inverse',
    'primary-outline', 'primary-outline-darken', 'primary-outline-inverse',
    'primaryLight', 'primaryLight-darken', 'primaryLight-inverse',
    'secondary', 'secondary-darken', 'secondary-inverse',
    'secondary-outline', 'secondary-outline-darken', 'secondary-outline-inverse',
    'success', 'success-darken', 'success-inverse',
    'success-outline', 'success-outline-darken', 'success-outline-inverse',
    'warning', 'warning-darken', 'warning-inverse',
    'warning-outline', 'warning-outline-darken', 'warning-outline-inverse',
    'error', 'error-darken', 'error-inverse',
    'errorDark', 'errorDark-darken', 'errorDark-inverse',
    'error-outline', 'error-outline-darken', 'error-outline-inverse',
    'grey', 'grey-darken', 'grey-inverse',
    'custom'
  ]).isRequired,

  content: Proptypes.oneOf([
    'none',
    'arrow-right', 'arrow-right-hidden', 'spinner'
  ]),

  customContent: Proptypes.oneOfType([
    Proptypes.string,
    Proptypes.element
  ]),

  customVariant: Proptypes.string,
  type: Proptypes.oneOf([
    'button', 'submit'
  ]),

  height: Proptypes.string,
  width: Proptypes.string,
  disabled: Proptypes.bool,
  squareButton: Proptypes.bool,
  ariaLabel: Proptypes.string
};

Button.defaultProps = {
  type: 'button',
  content: 'none',
  customContent: null,
  customVariant: null,
  height: '',
  width: '',
  disabled: false,
  squareButton: false,
  ariaLabel: null
};
