import React from 'react';
import Image from 'react-bootstrap/Image';
import './MaintenancePage.scss';

/**
 * Generates maintenance page.
 * @returns {*} - maintenance page
 * @class
 */
const MaintenancePage = () => (
  <div className="maintenancePage">
    <div className="maintenancePage_body">
      <Image
        src={`${UM_URL}ui-images/maintenance.png`}
        className="logo img-responsive maintenancePage_body_image"
        width="100%"
      />
    </div>
  </div>
);

export default MaintenancePage;
