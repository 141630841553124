import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const Text = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'middle',
})(({ theme }) => ({
  fontSize: '0.75rem',
  [theme.breakpoints.up('lg')]: {
    fontSize: '1rem'
  },
}));

const Separator = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'middle',
})(({ theme }) => ({
  marginTop: '0.75rem',
  [theme.breakpoints.up('lg')]: {
    marginTop: '1rem',
  },
}));

const BookCard = (props) => {
  const { image, title, subtitle } = props;

  return (
    <>
      <img
        src={`${UM_URL}media/book-banner/${image}`}
        alt={title}
        style={{
          maxWidth: '60%',
          height: 'auto',
        }}
      />
      <Box mt={2}>
        <Text>{title}</Text>
        <Separator />
        <Text>{subtitle}</Text>
      </Box>
    </>
  );
};

BookCard.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
};

BookCard.defaultProps = {
  subtitle: ''
};

export default BookCard;
